// processTemplate.js
// Updated function to process the HTML template with inline SVGs

import { generateAllIconSvgs } from '../svgUtils';
import { APP_URL } from "../../config";

/**
 * Processes the HTML template and replaces all placeholders
 * @param {string} template - The HTML template
 * @param {Object} data - The data to use for replacements
 * @returns {string} - The processed HTML
 */
const processTemplate = (template, data) => {
    if (!template) return '';

    const {
        colors, logoSize, logo, companyName, buttonText, mainContent, buttonLink,
        headerTitle, tipsHeading, pageTitle, showLogo, showLogoBackground, backgroundImage,
        tip1Title, tip1Content, tip2Title, tip2Content,
        tip3Title, tip3Content, tip4Title, tip4Content,
        tip5Title, tip5Content, tip6Title, tip6Content,
        useDefaultsForEmptyStrings = true
    } = data;

    // Log the values being processed for debugging
    console.log("Processing template with values:", {
        companyName,
        headerTitle, mainContent, tipsHeading, backgroundImage,
        showLogo, showLogoBackground
    });

    let processedTemplate = template;

    // Get the primary color for SVGs
    const primaryColor = colors?.primary || '#145049';

    // Generate all icon SVGs with the primary color
    const iconSvgs = generateAllIconSvgs(primaryColor);

    // Helper function to determine if a value should use default
    const isEmpty = (value) => {
        return value === undefined || value === null || value === '';
    };

    const getValueOrDefault = (value, defaultValue) => {
        if (useDefaultsForEmptyStrings && isEmpty(value)) {
            return defaultValue;
        }
        return value;
    };

    // Default text and content values
    const defaultValues = {
        pageTitle: "Landing Page",
        headerTitle: "This wasn't a real cyber attack, but it could have been.",
        mainContent: "The email you received was part of a training and awareness exercise conducted by <strong>" + (companyName || "*Company name*") + "</strong>. Had this been a real cyber attack, cybercriminals could have potentially gained access to your login credentials, as well as other confidential personal or business information.",
        tipsHeading: "Here are some tips to stay secure:",
        tip1Title: "Public Domains",
        tip1Content: "Legitimate organisations will never contact you from a public email domain like '@gmail.com'.",
        tip2Title: "Domain Spoofing",
        tip2Content: "Be cautious of email addresses that look legitimate but contain subtle spelling errors, such as '@gmaill.com' instead of '@gmail.com'. Cybercriminals often use these tactics to deceive you.",
        tip3Title: "Urgency",
        tip3Content: "Be wary of emails that create a sense of urgency, especially from colleagues. Even if the sender's email address appears genuine, it could be a sign of an account takeover by cybercriminals.",
        tip4Title: "Hover Over Links",
        tip4Content: "Always hover your mouse over links in emails to check the actual URL before clicking. This helps you avoid being redirected to malicious websites.",
        tip5Title: "Attachments",
        tip5Content: "Only open email attachments if you know the sender and are expecting the file. If you're unsure about the attachment, verify with the sender directly via phone or a secure online chat before opening it.",
        tip6Title: "Sensitive Info",
        tip6Content: "Reputable organisations will almost never request sensitive information such as login credentials or bank details via email. Always verify such requests through official channels.",
        backgroundImage: "https://company-logo-email-store.s3.eu-west-2.amazonaws.com/backgroundpicture.jpg",
        companyName: "Your Company"
    };

    // Replace color placeholders
    if (colors) {
        processedTemplate = processedTemplate
            .replace(/#145049/g, colors.primary)
            .replace(/{color\.primary}/g, colors.primary)
            .replace(/{color\.secondary}/g, colors.secondary || colors.primary)
            .replace(/{color\.accent}/g, colors.accent || colors.primary);
    }

    // IMPORTANT: Handle logo visibility
    // Log the values for debugging
    console.log("Logo visibility:", showLogo);
    console.log("Logo background visibility:", showLogoBackground);

    if (showLogo === false) {
        // Remove the entire header div if showLogo is false
        processedTemplate = processedTemplate
            .replace(/<div class="header">[\s\S]*?<\/div>/g, '');
    } else {
        // Handle logo background visibility by modifying the CSS directly
        if (showLogoBackground === false) {
            // Find the .header img CSS rule and modify it
            const headerImgCssRegex = /\.header img\s*{[^}]*background-color:[^;]+;[^}]*}/g;

            // Create a new style definition without background-color
            processedTemplate = processedTemplate.replace(
                headerImgCssRegex,
                match => match.replace(/background-color:[^;]+;/g, 'background-color: transparent !important;')
            );

            // Modify padding
            processedTemplate = processedTemplate.replace(
                /\.header img\s*{[^}]*padding:[^;]+;[^}]*}/g,
                match => match.replace(/padding:[^;]+;/g, 'padding: 0 !important;')
            );

            // Also directly modify any potential inline style on the img tag
            processedTemplate = processedTemplate.replace(
                /<img src="{client\.logo}"([^>]*)>/g,
                '<img src="{client.logo}"$1 style="background-color: transparent !important; padding: 0 !important;">'
            );
        }

        // Replace logo placeholder
        if (logo) {
            console.log("Setting logo path:", `${APP_URL}/${logo}`);
            processedTemplate = processedTemplate
                .replace(/{client\.logo}/g, `${APP_URL}/${logo}`);
        } else {
            // If no logo is provided, use a default or hide the logo element
            console.log("No logo provided, using default");
            processedTemplate = processedTemplate
                .replace(/{client\.logo}/g, "Insert Logo");
        }
    }

    // Replace logo size
    processedTemplate = processedTemplate
        .replace(/{logo\.size}/g, logoSize || 120);

    // Replace icon placeholders with inline SVGs
    processedTemplate = processedTemplate
        .replace(/{icon\.emailDomain}/g, iconSvgs.emailDomain)
        .replace(/{icon\.spoofing}/g, iconSvgs.spoofing)
        .replace(/{icon\.urgency}/g, iconSvgs.urgency)
        .replace(/{icon\.links}/g, iconSvgs.links)
        .replace(/{icon\.attachments}/g, iconSvgs.attachments)
        .replace(/{icon\.sensitiveInfo}/g, iconSvgs.sensitiveInfo);

    // Get the actual company name to use
    const actualCompanyName = getValueOrDefault(companyName, defaultValues.companyName);

    // Replace any {company.name} or {companyName} placeholders in the template
    processedTemplate = processedTemplate
        .replace(/{company\.name}/g, actualCompanyName)
        .replace(/{companyName}/g, actualCompanyName);

    // Also handle the case where company name is used within the main content
    let processedMainContent = getValueOrDefault(mainContent, defaultValues.mainContent);

    // If the main content contains the placeholder "*Company name*", replace it
    if (processedMainContent.includes("*Company name*")) {
        processedMainContent = processedMainContent.replace(/\*Company name\*/g, actualCompanyName);
    }

    // Background image handling - FIXED to avoid duplicate replacement
    const finalBackgroundImage = getValueOrDefault(backgroundImage, defaultValues.backgroundImage);
    console.log("Using background image:", finalBackgroundImage);

    // Replace text content
    processedTemplate = processedTemplate
        .replace(/{page\.title}/g, getValueOrDefault(pageTitle, defaultValues.pageTitle))
        .replace(/{background\.image}/g, finalBackgroundImage)
        .replace(/{header\.title}/g, getValueOrDefault(headerTitle, defaultValues.headerTitle))
        .replace(/{main\.content}/g, processedMainContent)
        .replace(/{tips\.heading}/g, getValueOrDefault(tipsHeading, defaultValues.tipsHeading))
        .replace(/{tip1\.title}/g, getValueOrDefault(tip1Title, defaultValues.tip1Title))
        .replace(/{tip1\.content}/g, getValueOrDefault(tip1Content, defaultValues.tip1Content))
        .replace(/{tip2\.title}/g, getValueOrDefault(tip2Title, defaultValues.tip2Title))
        .replace(/{tip2\.content}/g, getValueOrDefault(tip2Content, defaultValues.tip2Content))
        .replace(/{tip3\.title}/g, getValueOrDefault(tip3Title, defaultValues.tip3Title))
        .replace(/{tip3\.content}/g, getValueOrDefault(tip3Content, defaultValues.tip3Content))
        .replace(/{tip4\.title}/g, getValueOrDefault(tip4Title, defaultValues.tip4Title))
        .replace(/{tip4\.content}/g, getValueOrDefault(tip4Content, defaultValues.tip4Content))
        .replace(/{tip5\.title}/g, getValueOrDefault(tip5Title, defaultValues.tip5Title))
        .replace(/{tip5\.content}/g, getValueOrDefault(tip5Content, defaultValues.tip5Content))
        .replace(/{tip6\.title}/g, getValueOrDefault(tip6Title, defaultValues.tip6Title))
        .replace(/{tip6\.content}/g, getValueOrDefault(tip6Content, defaultValues.tip6Content));

    // Optional CSS filter fix
    processedTemplate = processedTemplate.replace(
        /.tip img {[\s\S]*?filter:.*?;[\s\S]*?}/g,
        '.tip img { width: 60px; height: 60px; margin-bottom: 10px; border-radius: 5px; }'
    );

    return processedTemplate;
};

export default processTemplate;