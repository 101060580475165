import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Box,
    CardContent,
    FormControlLabel,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    TextField,
    InputAdornment
} from "@mui/material";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MainCard from "../../../components/cards/MainCard";
import LoadingSwitch from "../../../components/LoadingSwitch";
import { useTheme } from "@mui/material/styles";

DeliverySection.propTypes = {
    delivery: PropTypes.string.isRequired,
    handleDeliveryChange: PropTypes.func.isRequired,
    scheduledDate: PropTypes.instanceOf(Date),
    setScheduledDate: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date),
    setStartDate: PropTypes.func.isRequired,
    startTime: PropTypes.string,
    setStartTime: PropTypes.func.isRequired,
    endDate: PropTypes.instanceOf(Date),
    setEndDate: PropTypes.func.isRequired,
    endTime: PropTypes.string,
    setEndTime: PropTypes.func.isRequired,
    pauseOnWeekends: PropTypes.bool.isRequired,
    setPauseOnWeekends: PropTypes.func.isRequired
};

export default function DeliverySection({
                                            delivery,
                                            handleDeliveryChange,
                                            scheduledDate,
                                            setScheduledDate,
                                            startDate,
                                            setStartDate,
                                            startTime,
                                            setStartTime,
                                            endDate,
                                            setEndDate,
                                            endTime,
                                            setEndTime,
                                            pauseOnWeekends,
                                            setPauseOnWeekends
                                        }) {
    const scheduledDateRef = useRef(null);
    const startDateRef = useRef(null);
    const startTimeRef = useRef(null);
    const endDateRef = useRef(null);
    const endTimeRef = useRef(null);

    // Store flatpickr instances in refs to prevent recreation
    const scheduledDatePickerRef = useRef(null);
    const startTimePickerRef = useRef(null);
    const endTimePickerRef = useRef(null);
    const [isWeekendPauseLoading, setIsWeekendPauseLoading] = useState(false);

    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    // Combined DateTime picker options
    const dateTimeOptions = {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true,
        minuteIncrement: 1,
        allowInput: true,
        onClose: (selectedDates, dateStr, instance) => {
            if (selectedDates[0]) {
                setScheduledDate(selectedDates[0]);
            }
        }
    };

    // Separate Date picker options
    const dateConfig = {
        enableTime: false,
        dateFormat: "Y-m-d",
        allowInput: true
    };

    // Separate Time picker options with onClose instead of onChange
    const timeConfig = {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minuteIncrement: 1,
        onClose: (selectedDates, dateStr, instance) => {
            if (selectedDates[0]) {
                const timeString = instance.formatDate(selectedDates[0], "H:i");
                const setter = instance === startTimePickerRef.current
                    ? setStartTime
                    : setEndTime;
                // Only update state after picker is closed
                setter(timeString);
            }
        }
    };

    const formatDate = (date) => {
        return date instanceof Date ? flatpickr.formatDate(date, "Y-m-d") : "";
    };

    const formatDateTime = (date) => {
        return date instanceof Date ? flatpickr.formatDate(date, "d MMM Y H:i") : "";
    };

    const handleWeekendPauseChange = async () => {
        setIsWeekendPauseLoading(true);
        try {
            // Simulate an async operation - replace with actual API call if needed
            await new Promise(resolve => setTimeout(resolve, 1000));
            setPauseOnWeekends(!pauseOnWeekends);
        } finally {
            setIsWeekendPauseLoading(false);
        }
    };


    useEffect(() => {
        // Initialize combined datetime picker
        const initDateTimePicker = (ref, defaultDate) => {
            if (ref.current) {
                const instance = flatpickr(ref.current, {
                    ...dateTimeOptions,
                    defaultDate: defaultDate instanceof Date ? defaultDate : undefined
                });

                scheduledDatePickerRef.current = instance;

                if (defaultDate) {
                    instance.setDate(defaultDate);
                }

                return instance;
            }
        };

        // Initialize separate date pickers
        const initDatePicker = (ref, defaultDate, setDate) => {
            if (ref.current) {
                return flatpickr(ref.current, {
                    ...dateConfig,
                    defaultDate: defaultDate instanceof Date ? defaultDate : undefined,
                    onChange: ([date]) => date && setDate(date)
                });
            }
        };

        // Initialize separate time pickers
        const initTimePicker = (ref, currentTime, isStartTime) => {
            if (ref.current) {
                const instance = flatpickr(ref.current, {
                    ...timeConfig,
                    defaultDate: currentTime || "00:00"
                });

                // Store instance reference
                if (isStartTime) {
                    startTimePickerRef.current = instance;
                } else {
                    endTimePickerRef.current = instance;
                }

                if (currentTime) {
                    instance.setDate(currentTime);
                }

                return instance;
            }
        };

        // Initialize all pickers
        const scheduledPicker = initDateTimePicker(scheduledDateRef, scheduledDate);
        const startDatePicker = initDatePicker(startDateRef, startDate, setStartDate);
        const startTimePicker = initTimePicker(startTimeRef, startTime, true);
        const endDatePicker = initDatePicker(endDateRef, endDate, setEndDate);
        const endTimePicker = initTimePicker(endTimeRef, endTime, false);

        return () => {
            [scheduledPicker, startDatePicker, startTimePicker,
                endDatePicker, endTimePicker].forEach(instance => {
                if (instance && typeof instance.destroy === 'function') {
                    instance.destroy();
                }
            });
        };
    }, [scheduledDate, startDate, endDate, setScheduledDate, setStartDate, setEndDate]);

    // Update picker values when props change
    useEffect(() => {
        if (scheduledDatePickerRef.current && scheduledDate) {
            scheduledDatePickerRef.current.setDate(scheduledDate);
        }
    }, [scheduledDate]);

    useEffect(() => {
        if (startTimePickerRef.current && startTime) {
            startTimePickerRef.current.setDate(startTime);
        }
    }, [startTime]);

    useEffect(() => {
        if (endTimePickerRef.current && endTime) {
            endTimePickerRef.current.setDate(endTime);
        }
    }, [endTime]);

    const combineDateAndTime = (date, time) => {
        if (!date || !time) return null;
        const [hours, minutes] = time.split(':');
        const newDate = new Date(date);
        newDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        return newDate;
    };

    const isScheduledValid = scheduledDate && scheduledDate > new Date();
    const isBatchSendValid = (() => {
        const startDateTime = combineDateAndTime(startDate, startTime);
        const endDateTime = combineDateAndTime(endDate, endTime);

        if (!startDateTime || !endDateTime) return false;

        // Check if start date is in the past
        if (new Date(startDateTime) < new Date()) {
            return false;
        }

        // Check if start date is after end date
        if (new Date(startDateTime) > new Date(endDateTime)) {
            return false;
        }

        // Check if start hours are >= end hours (using UTC)
        if (startDateTime.getUTCHours() >= endDateTime.getUTCHours()) {
            return false;
        }

        return true;
    })();

    const renderDateTimeField = (label, ref, value, sx = {}, error = false) => (
        <TextField
            label={label}
            inputRef={ref}
            defaultValue={formatDateTime(value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={sx}
            error={error}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <CalendarTodayIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => ref.current?._flatpickr?.open()}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );

    const renderDateField = (label, ref, value, sx = {}, error = false) => (
        <TextField
            label={label}
            inputRef={ref}
            defaultValue={formatDate(value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={sx}
            error={error}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <CalendarTodayIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => ref.current?._flatpickr?.open()}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );

    const renderTimeField = (label, ref, value, sx = {}, error = false) => (
        <TextField
            label={label}
            inputRef={ref}
            value={value || ''}
            placeholder="HH:mm"
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={sx}
            error={error}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <AccessTimeIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                const fp = ref.current?._flatpickr;
                                if (fp) {
                                    if (value) {
                                        fp.setDate(value);
                                    }
                                    fp.open();
                                }
                            }}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                <ToggleButtonGroup
                    exclusive
                    value={delivery}
                    onChange={(event, newDelivery) => {
                        if (newDelivery !== null) {
                            handleDeliveryChange(event, newDelivery);
                        }
                    }}
                    aria-label="delivery"
                    sx={{
                        '& .MuiToggleButton-root': {
                            whiteSpace: 'nowrap'
                        }
                    }}
                >
                    <ToggleButton value="sendNow" aria-label="Send Now">
                        Send Immediately
                    </ToggleButton>
                    <ToggleButton value="schedule" aria-label="Schedule">
                        Send later
                    </ToggleButton>
                    <ToggleButton value="batchSend" aria-label="Batch Send">
                        Send in batches
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {delivery === "sendNow" && (
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center', mt: 12 }}>
                    <MainCard sx={{
                        width: "50%",
                        textAlign: "center",
                        mt: 5,
                        borderRadius: 2,
                        bgcolor: lightMode ? theme.palette.white : theme.palette.grey[700],
                    }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Courses/Simulations will be sent immediately.
                            </Typography>
                        </CardContent>
                    </MainCard>
                </Box>
            )}

            {delivery === "schedule" && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Box sx={{ width: "50%", mb: 2 }}>
                        {renderDateTimeField("Scheduled Date and Time", scheduledDateRef, scheduledDate, { mb: 2 }, !isScheduledValid)}
                    </Box>
                    {!isScheduledValid && (
                        <Typography variant="caption" sx={{ color: "error.main", mt: 1 }}>
                            Scheduled date and time must be in the future.
                        </Typography>
                    )}
                </Box>
            )}

            {delivery === "batchSend" && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Box sx={{ width: "50%", mb: 2 }}>
                        {renderDateField("Start Date", startDateRef, startDate, { mb: 2 }, !isBatchSendValid)}
                        {renderDateField("End Date", endDateRef, endDate, { mb: 2 }, !isBatchSendValid)}
                        <Typography variant="h5" sx={{mb:2}}>
                            Between the hours
                        </Typography>
                        {renderTimeField("Start Time", startTimeRef, startTime, { mb: 2 }, !isBatchSendValid)}
                        {renderTimeField("End Time", endTimeRef, endTime, { mb: 2 }, !isBatchSendValid)}
                    </Box>
                    {!isBatchSendValid && (
                        <Typography variant="caption" sx={{ color: "error.main", mt: 1, mb: 2 }}>
                            Start date/time must be in the future and at least 1 hours before end date/time.
                        </Typography>
                    )}
                    <FormControlLabel
                        control={
                            <LoadingSwitch
                                checked={pauseOnWeekends}
                                onChange={handleWeekendPauseChange}
                                loading={isWeekendPauseLoading}
                            />
                        }
                        label="Pause on Weekends"
                    />
                </Box>
            )}
        </Box>
    );
}