import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
    useDeleteAllUsersMutation,
    useDeleteJourneysAndSchedulesMutation,
    useDeleteTenancyMutation,
    useUpdateTenancyLogoMutation,
    useUpdateTenancyMutation
} from "../../store/services/tenancy.service";
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    CircularProgress,
    Divider,
    FormControlLabel,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { useGetUniversityCourseSettingsQuery, useSaveUniversityCourseSettingsMutation } from "../../store/services/university-service";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { APP_URL } from "../../config";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material/styles";
import { saveThemeColors, getThemeColors } from "../../themes/themeColourManager";
import { getCardColor, isValidHexCode } from "../../utils/colorUtils";
import { useThemeContext } from '../../themes/ThemeContext';
import ThemeSquare from '../../components/ThemeSquare';
import { PRESET_THEMES } from '../../themes/PresetThemes';
import ColorPicker from '../../components/ColorPicker';
import EmailPreview from "../../components/EmailPreview";
import EmailPreviewModal from "../../components/EmailPreviewModal";
import LoadingSwitch from "../../components/LoadingSwitch";

ThemeSettings.propTypes = {
    data: PropTypes.object
};

export default function ThemeSettings(props) {
    const { data } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const { customColors, updateThemeColors } = useThemeContext();
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const lightMode = theme.palette.mode === 'light';

    const [tenancyData, setTenancyData] = useState(data);
    const [useCustomTemplate, setUseCustomTemplate] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [pendingTemplateChange, setPendingTemplateChange] = useState(false);

    const [primaryColor, setPrimaryColor] = useState(theme.palette.primary.main);
    const [secondaryColor, setSecondaryColor] = useState(theme.palette.secondary.main);
    const [tertiaryColor, setTertiaryColor] = useState(theme.palette.warning.main);

    const [isSaving, setIsSaving] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const environment = process.env.REACT_APP_BRAND_TYPE === 'censornet';
    const environment_name = process.env.REACT_APP_ENVIRONMENT_NAME;
    const [previewContent, setPreviewContent] = useState('');
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

    const {
        data: universitySettings,
        error: universitySettingsError,
        isLoading: universitySettingsLoading
    } = useGetUniversityCourseSettingsQuery({ tenancyId: tenancyData.tenancyId });

    const [updateTenancy] = useUpdateTenancyMutation();
    const [updateUniversityCourseSettings] = useSaveUniversityCourseSettingsMutation();
    const [deleteAllUsers] = useDeleteAllUsersMutation();
    const [deleteTenancy] = useDeleteTenancyMutation();
    const [deleteJourneysSchedules] = useDeleteJourneysAndSchedulesMutation();
    const [updateTenancyLogo] = useUpdateTenancyLogoMutation();
    const {
        data: defaultTemplateSettings,
        isLoading: defaultTemplateLoading
    } = useGetUniversityCourseSettingsQuery({ tenancyId: 0 });

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [enableOnDemandBox, setEnableOnDemandBox] = useState(tenancyData.enableOndemand);
    const [unauthSendingBox, setUnauthSendingBox] = useState(universitySettings ? universitySettings?.allowUnauthorisedTakeCourse : false);
    const [anonymiseReportingBox, setAnonymiseReportingBox] = useState(tenancyData.anonymiseReports);

    useEffect(() => {
        if (universitySettings?.useDefaultTemplate && defaultTemplateSettings?.courseBodyText) {
            setUseCustomTemplate(false);
            let processedTemplate = defaultTemplateSettings.courseBodyText;

            if (tenancyData?.logo) {
                processedTemplate = processedTemplate.replace(
                    /{client\.logo}/g,
                    `${APP_URL}/${tenancyData.logo}`
                );
            }

            setPreviewContent(processedTemplate);
        } else if (universitySettings?.courseBodyText) {
            setUseCustomTemplate(true);
            let processedTemplate = universitySettings.courseBodyText;

            if (tenancyData?.logo) {
                processedTemplate = processedTemplate.replace(
                    /{client\.logo}/g,
                    `${APP_URL}/${tenancyData.logo}`
                );
            }

            setPreviewContent(processedTemplate);
        }
    }, [
        universitySettings?.courseBodyText,
        universitySettings?.useDefaultTemplate,
        defaultTemplateSettings?.courseBodyText,
        tenancyData?.logo
    ]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
    }, []);

    const handleTemplateChange = () => {
        const newValue = !useCustomTemplate;
        setPendingTemplateChange(newValue);
        setConfirmDialogOpen(true);
    };

    const handleConfirmTemplateChange = async () => {
        setConfirmDialogOpen(false);

        try {
            const completeSettingsData = {
                ...universitySettings,
                tenancyId: tenancyData.tenancyId,
                useDefaultTemplate: !pendingTemplateChange
            };

            await updateUniversityCourseSettings({
                tenancyId: tenancyData.tenancyId,
                settingsData: completeSettingsData
            }).unwrap();

            setUseCustomTemplate(pendingTemplateChange);
            setSnackbarMessage(
                pendingTemplateChange
                    ? "Custom template enabled successfully"
                    : "Default template enabled successfully"
            );
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error updating template settings:", error);
            setSnackbarMessage("Error updating template settings");
            setSnackbarSeverity("error");
        }
        setSnackbarOpen(true);
    };

    const handleCancelTemplateChange = () => {
        setConfirmDialogOpen(false);
        setPendingTemplateChange(useCustomTemplate);
    };

    const handleSave = useCallback(async (savedData) => {
        setIsSaving(true);
        try {
            if (!isValidHexCode(primaryColor) || !isValidHexCode(secondaryColor) || !isValidHexCode(tertiaryColor)) {
                throw new Error("Invalid hex code detected");
            }

            const dataToUpdate = savedData || tenancyData;

            const updatedTenancyData = {
                ...dataToUpdate,
                primaryHex: primaryColor,
                secondaryHex: secondaryColor,
                tertiaryHex: tertiaryColor
            };

            await updateTenancy({
                data: updatedTenancyData,
                tenancyId: tenancyData.tenancyId
            }).unwrap();

            updateThemeColors({
                primary: primaryColor,
                secondary: secondaryColor,
                warning: tertiaryColor
            });

            setTenancyData(updatedTenancyData);
            setSnackbarMessage("Settings saved successfully");
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error saving settings:", error);
            setSnackbarMessage(`Error saving settings: ${error.message}`);
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setIsSaving(false);
        }
    }, [tenancyData, primaryColor, secondaryColor, tertiaryColor, updateTenancy, updateThemeColors]);

    const handlePresetThemeSelect = (theme) => {
        setSelectedTheme(theme);
        const newColors = {
            primary: theme.primary,
            secondary: theme.secondary,
            warning: theme.tertiary
        };
        setPrimaryColor(theme.primary);
        setSecondaryColor(theme.secondary);
        setTertiaryColor(theme.tertiary);
        updateThemeColors(newColors);
    };

    const handleSaveUniversityCourseSettings = () => {
        const completeSettingsData = {
            ...universitySettings,
            tenancyId: tenancyData.tenancyId,
            allowUnauthorisedTakeCourse: unauthSendingBox
        };

        updateUniversityCourseSettings({
            tenancyId: tenancyData.tenancyId,
            settingsData: completeSettingsData
        })
            .then(() => {
                setSnackbarMessage("University course settings updated successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error("Error updating university course settings:", error);
                setSnackbarMessage("Error updating university course settings.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    const handleSaveLogo = async (file) => {
        setIsUploadingLogo(true);
        try {
            if (!file) {
                throw new Error("No file selected");
            }
            if (file.type !== 'image/png') {
                throw new Error("Please upload a PNG file");
            }
            const img = await new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = () => reject(new Error("Error loading image file"));
                img.src = URL.createObjectURL(file);
            });
            if (img.width > 300 || img.height > 300) {
                throw new Error("Image dimensions should not exceed 300x300 pixels");
            }
            const id = uuidv4();
            const fileName = `assets/logos/${id}.png`;
            const data = {
                file: file,
                tenancyId: tenancyData.tenancyId,
                fileName: fileName
            };

            await updateTenancyLogo({ data: data, tenancyId: tenancyData.tenancyId }).unwrap();
            setTenancyData({ ...tenancyData, logo: fileName });
            setSnackbarMessage("Company logo uploaded successfully");
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error uploading company logo:", error);
            setSnackbarMessage(error.message || "Error uploading company logo");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setIsUploadingLogo(false);
        }
    };

    if (tenancyData) {
        return (
            <>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 3
                }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="outlined"
                            color="black"
                            onClick={() => navigate(location.from ? location.from : -1)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={() => handleSave(tenancyData)}
                        >
                            {isSaving ? <CircularProgress size={24} color="inherit" /> : "Save"}
                        </Button>
                    </Box>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ mb: 4 }}>
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{
                                            height: '100%',
                                            bgcolor: getCardColor(lightMode),
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="Upload your company logo here. The logo will be displayed in the top left in the sidebar for all users.">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h4">
                                                    Upload Logo
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ width: "100%", mb: 2 }} />

                                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            {tenancyData.logo && (
                                                <Box sx={{ display: "flex", justifyContent: "center", mb: 6, mt: 6 }}>
                                                    <img src={`${APP_URL}/${tenancyData.logo}`} alt="Logo" width={120} height="auto"
                                                         className="logo-image" style={{ objectFit: "contain" }} />
                                                </Box>
                                            )}
                                        </Box>

                                        <Box sx={{ mt: 'auto', display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="label"
                                                fullWidth
                                            >
                                                {isUploadingLogo ? <CircularProgress color="black"/> : tenancyData.logo ? "Change Logo" : "Upload Logo"}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => handleSaveLogo(e.target.files[0])}
                                                />
                                            </Button>
                                            {tenancyData.logo && (
                                                <Button
                                                    variant="outlined"
                                                    color="black"
                                                    fullWidth
                                                    onClick={() => {
                                                        setTenancyData({ ...tenancyData, logo: "" });
                                                        handleSave({ ...tenancyData, logo: "" });
                                                    }}
                                                >
                                                    Remove Logo
                                                </Button>
                                            )}
                                            <Alert severity="warning" sx={{ mt: 2 }}>
                                                Logos must be a .png file with maximum dimensions of 300x300
                                            </Alert>
                                        </Box>
                                    </MainCard>
                                </Grid>

                                <Grid item xs={12} md={6} lg={6}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{
                                            height: '100%',
                                            bgcolor: getCardColor(lightMode),
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="Choose custom colors that will be used as the theme for all users.">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h4">
                                                    Custom Theme Selector
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ width: "100%", mb: 2 }} />
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 4 }}>
                                                <ColorPicker label="Primary" color={primaryColor} onChange={setPrimaryColor}/>
                                                <ColorPicker label="Secondary" color={secondaryColor} onChange={setSecondaryColor} />
                                                <ColorPicker label="Tertiary" color={tertiaryColor} onChange={setTertiaryColor} />
                                            </Box>

                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="Choose preset colors that will be used as the theme for all users.">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h5">Preset Themes</Typography>
                                            </Box>
                                            <Box sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(7, 1fr)',
                                                gridTemplateRows: 'repeat(2)',
                                                rowGap: 1,
                                                columnGap: 0.25,
                                                '& > button': {
                                                    aspectRatio: '1/1',
                                                    width: '80%'
                                                }
                                            }}>
                                                {PRESET_THEMES.map((theme) => (
                                                    <ThemeSquare
                                                        key={theme.name}
                                                        theme={theme}
                                                        onClick={() => handlePresetThemeSelect(theme)}
                                                        isSelected={selectedTheme?.name === theme.name}
                                                    />
                                                ))}
                                            </Box>
                                        </Box>
                                    </MainCard>
                                </Grid>   
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Confirmation Dialog */}
                <Dialog
                    open={confirmDialogOpen}
                    onClose={handleCancelTemplateChange}
                    aria-labelledby="template-confirmation-dialog"
                >
                    <DialogTitle id="template-confirmation-dialog">
                        {pendingTemplateChange ? "Switch to Custom Template?" : "Switch to Default Template?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {pendingTemplateChange
                                ? "Switching to custom templates will allow you to customize all email templates. Are you sure you want to continue?"
                                : "Switching to the default template will disable your custom email templates. All users will receive the default template emails. Are you sure you want to continue?"
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelTemplateChange} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmTemplateChange} color="primary" variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity={snackbarSeverity}
                        variant="filled"
                        sx={{ width: "100%" }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </>
        );
    }
    return null;
}