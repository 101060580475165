import React, { useState, useEffect } from "react";
import {
    Box,
    Button, FormControl,
    Grid, InputLabel, MenuItem,
    Select,
    TextField, Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useUpdateJourneyMutation } from "../../store/services/journey-service";
import { useGetSimulationsQuery } from "../../store/services/simulation-service";
import { useGetCoursesQuery } from "../../store/services/university-service";
import MainCard from "../../components/cards/MainCard";
import JourneyTimeline from "./JourneyTimeline";
import { getCardColor } from "../../utils/colorUtils";


const JourneyEditForm = ({ initialData, onSave, setSnackbarOpen, setSnackbarMessage }) => {
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const [UpdateJourney] = useUpdateJourneyMutation();
    const [nameError, setNameError] = useState("");

    const { data: simData, simLoading, simError } = useGetSimulationsQuery({
        perPage: 200,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });

    const { data: courseData, courseLoading, courseError } = useGetCoursesQuery({
        perPage: 200,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });

    const [journeyData, setJourneyData] = useState(initialData);

    const [newJourneyOption, setNewJourneyOption] = useState({
        type: 0,
        typeId: "",
        typeName: "", 
        month: "",
        day: "",
    });


    // because of backend magic for type 0 (sims) we need typeName to be populated but for typeId to be an empty string
    // because of similar magic reasons type 1 (courses) need to have both typeName and typeId populated with the course name
    const isOptionValid = () => {
        return (
            ((newJourneyOption.type === 0 && newJourneyOption.typeName !== "" && newJourneyOption.typeId === "") ||
                (newJourneyOption.type === 1 && newJourneyOption.typeName === newJourneyOption.typeId && newJourneyOption.typeId !== ""))
            && newJourneyOption.month >= 1
            && newJourneyOption.month <= 12
            && newJourneyOption.day >= 1
            && newJourneyOption.day <= 31
        );
    };


    useEffect(() => {
        setJourneyData(initialData);
    }, [initialData]);

    const handleSave = async () => {
        if (!journeyData.name.trim()) {
            setNameError("Journey Name is required");
            return;
        }
        const journeyUpdateCommand = { journeyToUpdate: journeyData };

        await UpdateJourney({ journeyData: journeyUpdateCommand }).unwrap()
        .then((response) => {
            setSnackbarMessage("Journey updated successfully");
            setSnackbarOpen(true);
        })
        .catch((error) => {
            console.log("error", error);
            setSnackbarMessage("Error occurred while updating journey");
            setSnackbarOpen(true);
        });
    };

    const handleAddNewJourneyOption = () => {
        newJourneyOption.dateCreated = new Date().toISOString();
        setJourneyData({ ...journeyData, journeyOptions: [...journeyData.journeyOptions, newJourneyOption] });
        setNewJourneyOption({
            day: "",
            month: "",
            type: 0,
            typeId: "",
            typeName: ""
        });
    };

    const handleRemoveJourneyOption = (index) => {
        const updatedOptions = journeyData.journeyOptions.filter((_, i) => i !== index);
        setJourneyData({ ...journeyData, journeyOptions: updatedOptions });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <TextField
                    label="Journey Name"
                    value={journeyData.name ?? journeyData.name}
                    size="medium"
                    sx={{ mb: 4, width: "50%" }}
                    onChange={(e) => {
                        setJourneyData({ ...journeyData, name: e.target.value });
                        if (e.target.value.trim()) setNameError("");
                    }}
                    required
                    error={!!nameError}
                    helperText={nameError}
                />

                <Grid item xs={12}>
                    <MainCard content={true} boxShadow={false} border={false} sx={{ height: '100%', bgcolor: lightMode ? "primary.main" : getCardColor(lightMode) }}>
                        <Typography variant="h5" gutterBottom>
                            Add Simulation or Course
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', mt: 4, gap: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel style={{ backgroundColor: 'transparent' }}>Type</InputLabel>
                                <Select
                                    value={newJourneyOption.type} 
                                    onChange={(e) => setNewJourneyOption({
                                        ...newJourneyOption,
                                        type: e.target.value,
                                        typeId: "",
                                        typeName: ""
                                    })}
                                    label="Type"
                                    variant="outlined"
                                    required={true}
                                >
                                    <MenuItem value={0}>Simulation</MenuItem>
                                    <MenuItem value={1}>Course</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel style={{ backgroundColor: "transparent" }}>
                                    Select {newJourneyOption.type === 1 ? "Course" : "Simulation"}
                                </InputLabel>
                                <Select
                                    value={newJourneyOption.type === 0 ? newJourneyOption.typeName : newJourneyOption.typeId}
                                    onChange={(e) => {
                                        if (newJourneyOption.type === 0) {
                                            setNewJourneyOption({
                                                ...newJourneyOption,
                                                typeName: e.target.value,
                                                typeId: ""  
                                            });
                                        } else {
                                            setNewJourneyOption({
                                                ...newJourneyOption,
                                                typeId: e.target.value,
                                                typeName: e.target.value  
                                            });
                                        }
                                    }}
                                    label={"Select " + (newJourneyOption.type === 1 ? "Course" : "Simulation")}
                                    variant="outlined"
                                    required={true}
                                >
                                    {newJourneyOption.type === 1 && courseData && courseData.map((course, index) => (
                                        <MenuItem key={index} value={course.courseName}>
                                            {course.courseName}
                                        </MenuItem>
                                    ))}
                                    {newJourneyOption.type === 0 && simData && simData.map((sim, index) => (
                                        <MenuItem key={index} value={sim.simulationName}>
                                            {sim.simulationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                type="number"
                                label="Month"
                                value={newJourneyOption.month}
                                onChange={(e) => setNewJourneyOption({ ...newJourneyOption, month: e.target.value > 12 ? 12 : e.target.value })}
                                inputProps={{ min: 1, max: 12 }}
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                type="number"
                                label="Day"
                                value={newJourneyOption.day}
                                onChange={(e) => setNewJourneyOption({ ...newJourneyOption, day: e.target.value > 31 ? 31 : e.target.value })}
                                inputProps={{ min: 1, max: 31 }}
                                fullWidth
                                variant="outlined"
                            />
                            <Button color="warning" variant="contained" size="medium" onClick={handleAddNewJourneyOption} disabled={!isOptionValid()} sx={{ height: 'fit-content', alignSelf: 'center' }}>
                                Add
                            </Button>
                        </Box>
                    </MainCard>
                </Grid>

                <Grid item xs={6}>
                    <Button color="warning" variant="contained" onClick={handleSave} sx={{ width: '50%', mt: 4 }}>
                        Save Journey
                    </Button>
                </Grid>

                <JourneyTimeline journeyData={journeyData} handleDeleteOption={handleRemoveJourneyOption} />
            </Grid>
        </Grid>
    );
};

export default JourneyEditForm;
