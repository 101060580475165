import { useRoutes } from 'react-router-dom';
import { useSelector } from "react-redux";
import MainRoutes from './MainRoutes';
import TenancyAdminRoutes from './TenancyAdminRoutes';
import LoginRoutes from './LoginRoutes';
import UserRoutes from './UserRoutes';
import SyncRoutes from "./SyncRoutes";
import ProvisioningRoutes from "./ProvisioningRoutes";

const ThemeRoutes = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { isHierarchyTenancy } = useSelector((state) => state.tenancy);

    // Display On-Demand, Reporting and Tenants tabs to users based on tenancy settings and user level permissions
    const getFilteredTenancyAdminRoutes = (user) => {
        return {
            ...TenancyAdminRoutes,
            children: TenancyAdminRoutes.children.filter(item => {
                if (item.path === 'reports') {
                    if (user?.role?.includes('TenancyAdministrators')) return user.enableReporting;
                    else return user.reportingLevel !== null && user.enableReporting;
                }
                if (item.path === 'on-demand') {
                    if (user?.role?.includes('TenancyAdministrators')) {
                        return user.enableOndemand;
                    }
                    else return user.onDemandLevel !== null && user.enableOndemand;
                }
                if (item.path === 'tenants') {
                    if (user?.role?.includes('PartnerAdministrators')) {
                        return true;
                    }
                    else if (user?.role?.includes('TenancyAdministrators')) {
                        return isHierarchyTenancy;
                    }
                    else return false
                }
                if (user?.role?.includes('TenancyAdministrators')) return true;
            })
        };
    };

    let routes = [];

    if (user?.role === 'Administrators') {
        routes = [MainRoutes, TenancyAdminRoutes, UserRoutes, SyncRoutes, LoginRoutes, ProvisioningRoutes];
    } else if (user?.role?.includes('TenancyAdministrators')) {
        const filteredRoutes = getFilteredTenancyAdminRoutes(user);
        routes = [filteredRoutes, UserRoutes, SyncRoutes, LoginRoutes];
        if (user?.role?.includes('PartnerAdministrators')) routes.push(ProvisioningRoutes);
    } else if (user?.role === '') {
        const filteredRoutes = getFilteredTenancyAdminRoutes(user);
        routes = [filteredRoutes, UserRoutes, LoginRoutes];
    } else {
        routes = [LoginRoutes];
    }

    return useRoutes(routes);
};

export default ThemeRoutes;
