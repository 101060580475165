import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from "../reducers/auth";
import { TENANCY_API_URL } from 'config';

const baseQuery = fetchBaseQuery({
    baseUrl: TENANCY_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem('user');

        if (user) {
            let userToken = JSON.parse(user).token;
            if (userToken) {
                headers.set('authorization', `Bearer ${userToken}`)
                return headers;
            }
        }
        localStorage.removeItem('user');
    },
    credentials: "omit"
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // I don't think this is the right endpoint to call
        // try to get a new token
        // const refreshResult = await baseQuery('auth/login', api, extraOptions)
        // if (refreshResult.data) {
        //     // store the new token
        //     api.dispatch(token(refreshResult.data))
        //     // retry the initial query
        //     result = await baseQuery(args, api, extraOptions)
        // } else {
            api.dispatch(logout())
        // }
    }
    return result
}

export const tenancyApi = createApi({
    reducerPath: "tenancyApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        generateNewAccessKey: builder.mutation({
            query: ({tenancyId}) => ({
                url: `tenancy/${tenancyId}/generatekey`,
                method: 'POST',
            }),
            transformResponse: (response) => response.Data,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, id) => [{type: "Tenancy", id: id}]
        }),
        getTenancyById: builder.query({
            query: ({tenancyId}) => `tenancy/${tenancyId}`,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            providesTags: (result, error, id) => [{ type: "Tenancy", id }]
        }),
        getTenancies: builder.query({
            query: ({perPage, page, includes, query, additionalQueryParams}) => `tenancy/?perPage=${perPage}&page=${page}&includes=${includes}&query=${query}${additionalQueryParams}`,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            providesTags: (result) => {
                return result ?
                    [...result.map(({ id }) => ({ type: "Tenancy", id })), { type: "Tenancy", id: "LIST" }]
                    :
                    [{ type: "Tenancy", id: "LIST" }];
            }
        }),
        getLocationsByTenancyId: builder.query({
            query: ({tenancyId}) => `tenancy/${tenancyId}/meta/location`,
            transformResponse: (response) => response.filter(name => name !== "" && name !== "-"),
            providesTags: (result, error, tenancyId ) => [{ type: "Locations", tenancyId  }]
        }),
        getStatesByTenancyId: builder.query({
            query: ({tenancyId}) => `tenancy/${tenancyId}/meta/state`,
            transformResponse: (response) => response.filter(name => name !== "" && name !== "-"),
            providesTags: (result, error, tenancyId ) => [{ type: "States", tenancyId  }]
        }),
        getCompanyNamesByTenancyId: builder.query({
            query: ({tenancyId}) => `tenancy/${tenancyId}/meta/companyname`,
            transformResponse: (response) => response.filter(name => name !== "" && name !== "-"),
            providesTags: (result, error, tenancyId ) => [{ type: "CompanyNames", tenancyId  }]
        }),
        getCitiesByTenancyId: builder.query({
            query: ({tenancyId}) => `tenancy/${tenancyId}/meta/city`,
            transformResponse: (response) => response.filter(name => name !== "" && name !== "-"),
            providesTags: (result, error, tenancyId ) => [{ type: "Cities", tenancyId  }]
        }),
        getDepartmentsByTenancyId: builder.query({
            query: ({tenancyId}) => `tenancy/${tenancyId}/meta/department`,
            transformResponse: (response) => response.filter(name => name !== "" && name !== "-"),
            providesTags: (result, error, tenancyId ) => [{ type: "Departments", tenancyId  }]
        }),
        getCountriesByTenancyId: builder.query({
            query: ({tenancyId}) => `tenancy/${tenancyId}/meta/country`,
            transformResponse: (response) => response.filter(name => name !== "" && name !== "-"),
            providesTags: (result, error, tenancyId ) => [{ type: "Countries", tenancyId  }]
        }),
        getAuditLogsForTenancy: builder.query({
            query: ({tenancyId}) => `tenancy/${tenancyId}/audit`,
            transformResponse(baseQueryReturnValue) {
                return baseQueryReturnValue;
            },
            transformErrorResponse: (response) => response.status,
        }),
        getImportStatus: builder.query({
            query: ({ tenancyId, all }) => all ?
                `tenancy/${tenancyId}/importstatus?all=true` :
                `tenancy/${tenancyId}/importstatus`,
            transformResponse(baseQueryReturnValue) {
                return baseQueryReturnValue;
            },
            transformErrorResponse: (response) => response.status,
        }),
        createGoogleImport: builder.query({
            query: ({ code, email, tenancyId }) => ({
                url: `tenancy/${tenancyId}/googleimport?code=${code}&requestEmail=${email}`,
                method: 'GET',
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        getTenancyBasics: builder.query({
            queryFn: async ({ tenancyId }, api, extraOptions, baseQuery) => {
                // Check if tenancyId is null, undefined, empty string, etc.
                if (!tenancyId) {
                    // Return null without making an API call
                    return { data: null };
                }
                const resp = await baseQuery(`tenancy/${tenancyId}/basic`, api, extraOptions);
                return resp;
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response, meta, arg) => {
                // Check if the error status is 401 (Unauthorized)
                if (response.status === 401) {
                    // We are logged out - redirect to login page
                    sessionStorage.clear();
                    localStorage.clear();
                    console.log("Clear Session");
                    debugger
                    window.location.href = '/';
                }

                return response.status;
            },
            providesTags: (result, error, id) => [{ type: "TenancyDataBasics", id }]
        }),
        getReminderOptions: builder.query({
            query: ({tenancyId}) => `tenancyreminder?tenancyId=${tenancyId}`,
        }),
        outlookPluginHandler: builder.query({
            query: ({ tenancyId, tenancy }) => ({
                url: `tenancy/${tenancyId}/outlookpluginhandler`,
                params: {
                    tenancy,
                }
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        getCachedOfficeData: builder.query({
            queryFn: async ({ tenancyId }, api, extraOptions, baseQuery) => {
                const cachedDataResponse = await baseQuery(`office365/cached?tenancyId=${tenancyId}`, api, extraOptions);

                if (cachedDataResponse.error) {
                    return { error: cachedDataResponse.error };
                }

                let data = cachedDataResponse.data;

                if (data && data.GroupLink) {
                    const groupLinkResponse = await fetch(data.GroupLink, {
                        method: "GET",
                    });

                    if (groupLinkResponse.ok) {
                        data = {Groups: await groupLinkResponse.json() };
                    }
                }

                return { data };
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        createOnDemand: builder.mutation({
            async queryFn(arg, api, extraOptions, baseQuery) {
                const { data, tenancyId } = arg;
                const maxRetries = 6;
                const baseDelay = 1000;

                const exponentialBackoffWithJitter = (attempt) => {
                    const exponentialDelay = Math.min(Math.pow(2, attempt) * baseDelay, 30000); // Max delay of 30 seconds
                    const jitter = Math.random();
                    return Math.floor(exponentialDelay * (1 + jitter));
                };

                for (let attempt = 0; attempt < maxRetries; attempt++) {
                    try {
                        const result = await baseQuery({
                            url: `tenancy/${tenancyId}/ondemand`,
                            method: 'POST',
                            body: data
                        }, api, extraOptions);

                        if (result.error) {
                            throw result.error;
                        }

                        return { data: result.data };
                    } catch (error) {
                        console.error(`Attempt ${attempt + 1} failed:`, error);

                        if (attempt === maxRetries - 1) {
                            // Log the final error with more details
                            console.error('All retry attempts failed:', {
                                tenancyId,
                                error: error.toString(),
                                status: error.status,
                                data: error.data
                            });
                            return { error };
                        }

                        const delay = exponentialBackoffWithJitter(attempt);
                        console.log(`Retrying in ${delay}ms...`);
                        await new Promise(resolve => setTimeout(resolve, delay));
                    }
                }
            },
            invalidatesTags: [{ type: "Tenancy", id: "LIST" }]
        }),
        createTenancy: builder.mutation({
            query: ({data}) => ({
                url: `tenancy`,
                method: 'POST',
                body: data
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: [{ type: "Tenancy", id: "LIST" }]
        }),
        createOfficeTenantImport: builder.mutation({
            query: ({ tenancyId, tenant, email }) => ({
                url: `tenancy/${tenancyId}/office365import?tenant=${tenant}&email=${email}`,
                method: 'POST',
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),

        updateTenancy: builder.mutation({
            query: ({data, tenancyId}) => ({
                url: `tenancy/${tenancyId}`,
                method: 'PUT',
                body: data
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, id) => [{ type: "Tenancy", id: "LIST" }, {type: "Tenancy", id: id}]
        }),
        updateTenancyLogo: builder.mutation({
            queryFn: async ({ data, tenancyId }, api, extraOptions, baseQuery) => {
                const preSignedUrlResponse = await baseQuery({
                    url: `tenancy/${tenancyId}/sign-upload`,
                    method: 'POST',
                    body: { fileName: data.fileName }
                }, api, extraOptions);

                if (preSignedUrlResponse.error) {
                    return { error: preSignedUrlResponse.error };
                }

                const { presignedUrl } = preSignedUrlResponse.data;

                const uploadResponse = await fetch(presignedUrl, {
                    method: "PUT",
                    body: data.file,
                });

                if (!uploadResponse.ok) {
                    return { error: { status: uploadResponse.status, statusText: uploadResponse.statusText } };
                }

                return { data: { success: true } };
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, { tenancyId }) => [{ type: "Tenancy", id: "LIST" }, { type: "Tenancy", id: tenancyId }]
        }),

        updateReminderOptions: builder.mutation({
            query: ({data}) => ({
                url: `tenancyreminder`,
                method: 'POST',
                body: data
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, id) => [{ type: "Tenancy", id: "LIST" }, {type: "Tenancy", id: id}]
        }),
        // TODO: Update the endpoint from user to tenancy in 2.0
        updateAutoSync: builder.mutation({
            query: ({tenancyId, data}) => ({
                url: `user/office365?tenancyId=${tenancyId}`,
                method: 'POST',
                body: data
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, id) => [{ type: "Tenancy", id: "LIST" }, {type: "Tenancy", id: id}]
        }),
        refreshSyncGroups: builder.mutation({
            query: ({tenancyId, data}) => ({
                url: `tenancy/${tenancyId}/refresh-groups`,
                method: 'POST',
                body: data
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        deleteAllUsers: builder.mutation({
            query: ({tenancyId}) => `tenancy/${tenancyId}/purgeusers`,
            invalidatesTags: (result, error, id) => [{ type: "Tenancy", id: "LIST" }, {type: "Tenancy", id: id}]
        }),
        deleteTenancy: builder.mutation({
            query: ({tenancyId}) => ({
                url: `tenancy/${tenancyId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: "Tenancy", id: "LIST" }, {type: "Tenancy", id: id}]
        }),
        deleteJourneysAndSchedules: builder.mutation({
            query: ({tenancyId}) => ({
                url: `tenancy/${tenancyId}/deleteAssociatedJourneysAndSchedules`,
                method: 'DELETE',
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, id) => [{ type: "Tenancy", id: "LIST" }, {type: "Tenancy", id: id}]
        }),
        getTenancyAllowRetake: builder.query({
            query: ({tenancyId}) => `tenancycourse/${tenancyId}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        refreshReports: builder.mutation({
            query: (tenancyId) => ({
                url: `reporting/refresh`,
                method: 'GET',
                params: { tenancyId },
                responseHandler: 'text',
            }),
            transformResponse: (response) => {
                return { message: response };
            },
            invalidatesTags: [
                { type: 'UserReportingData', id: 'LIST' },
                { type: "Tenancy", id: "LIST" }
            ]
        }),
    }),
});

export const {
    useGenerateNewAccessKeyMutation,
    useGetTenancyByIdQuery,
    useGetTenanciesQuery,
    useGetTenancyBasicsQuery,
    useLazyGetDepartmentsByTenancyIdQuery,
    useLazyGetLocationsByTenancyIdQuery,
    useLazyGetCitiesByTenancyIdQuery,
    useLazyGetCountriesByTenancyIdQuery,
    useLazyGetStatesByTenancyIdQuery,
    useLazyGetCompanyNamesByTenancyIdQuery,
    useGetDepartmentsByTenancyIdQuery,
    useGetLocationsByTenancyIdQuery,
    useGetCitiesByTenancyIdQuery,
    useGetCountriesByTenancyIdQuery,
    useGetStatesByTenancyIdQuery,
    useGetCompanyNamesByTenancyIdQuery,
    useGetAuditLogsForTenancyQuery,
    useGetImportStatusQuery,
    useGetReminderOptionsQuery,
    useGetCachedOfficeDataQuery,
    useCreateOnDemandMutation,
    useCreateTenancyMutation,
    useCreateOfficeTenantImportMutation,
    useUpdateTenancyMutation,
    useUpdateReminderOptionsMutation,
    useUpdateTenancyLogoMutation,
    useUpdateAutoSyncMutation,
    useRefreshSyncGroupsMutation,
    useDeleteAllUsersMutation,
    useDeleteTenancyMutation,
    useDeleteJourneysAndSchedulesMutation,
    useGetTenancyAllowRetakeQuery,
    useLazyCreateGoogleImportQuery,
    useRefreshReportsMutation,
    useOutlookPluginHandlerQuery,
} = tenancyApi;
