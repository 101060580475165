import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { useGetCourseByIdQuery, useUpdateCourseMutation, useUploadToS3Mutation } from '../../store/services/university-service';
import { useGetTenanciesQuery } from "../../store/services/tenancy.service";
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    CircularProgress,
    Snackbar,
    Alert,
    Card,
    Typography,
    Switch,
    Tooltip
} from "@mui/material";
import { APP_URL, COURSE_CATEGORIES, SIM_AND_COURSE_FORMATS } from "../../config";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from "@mui/material/styles";
import IncludeInRandomSwitch from "../../utils/randomSendSwitch";

CourseEdit.propTypes = {
    courseId: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default function CourseEdit({ courseId, onSave, onClose }) {
    const [editedCourseData, setEditedCourseData] = useState(null);
    const [isRandomSend, setIsRandomSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [uploadStates, setUploadStates] = useState({
        content: { isUploading: false, progress: 0 },
        image: { isUploading: false, progress: 0 },
        gif: { isUploading: false, progress: 0 }
    });
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });

    const fileInputRefs = {
        content: useRef(null),
        image: useRef(null),
        gif: useRef(null)
    };

    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const { data: courseData, isLoading: courseLoading, error: courseError } = useGetCourseByIdQuery({
        courseId: courseId,
        includes: '',
    });

    const { data: tenanciesData } = useGetTenanciesQuery({ perPage: 5000, page: 0, includes: "", query: "", additionalQueryParams: "" });

    const course_gif = courseData ? `${APP_URL}/${courseData.courseContentPath.replace('index.html', 'card.gif')}` : '';
    const course_img = courseData ? `${APP_URL}/${courseData.courseContentPath.replace('index.html', 'card.png')}` : '';

    const [updateCourse] = useUpdateCourseMutation();
    const [uploadToS3] = useUploadToS3Mutation();

    const courseLanguageCodeOptions = COURSE_CATEGORIES.split(',');
    const courseFormatOptions = SIM_AND_COURSE_FORMATS.split(',');

    const hasContentPath = editedCourseData?.courseContentPath && editedCourseData.courseContentPath.length > 0;

    useEffect(() => {
        if (courseData) {
            setEditedCourseData({
                ...courseData,
                randomSend: courseData.randomSend ?? false
            });
            setIsRandomSend(courseData.randomSend ?? false);
        }
    }, [courseData]);

    const handleSave = async () => {
        setLoading(true);
        setError(null);
        try {
            const updatedData = {
                ...editedCourseData,
                randomSend: Boolean(isRandomSend),
                universityCourseId: courseId
            };
            const response = await updateCourse({ courseData: updatedData }).unwrap();
            if (response && response.message === "Ok") {
                if (response.course && response.course.randomSend !== isRandomSend) {
                    setIsRandomSend(response.course.randomSend);
                }
                onSave(response.course || updatedData);
                showSnackbar("Course updated successfully", "success");
            } else {
                throw new Error("Unexpected response from server");
            }
        } catch (error) {
            console.error("Error in handleSave:", error);
            setError(error.message || "An error occurred while updating the course");
            showSnackbar(error.message || "An error occurred while updating the course", "error");
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event, fileType) => {
        const file = event.target.files[0];
        if (file) {
            handleUploadContent(file, fileType);
        }
    };

    const handleRandomSendChange = (event) => {
        setIsRandomSend(event.target.checked);
    };

    const fileTypes = {
        content: "application/zip,application/x-zip-compressed",
        image: "image/png",
        gif: "image/gif"
    };

    const handleUploadContent = async (file, fileType) => {
        setUploadStates(prev => ({
            ...prev,
            [fileType]: { isUploading: true, progress: 0 }
        }));

        const contentTypes = {
            content: ["application/zip", "application/x-zip-compressed", "application/octet-stream"],
            image: ["image/png"],
            gif: ["image/gif"]
        };

        if (fileType === 'content') {
            const isZipFile = file.name.toLowerCase().endsWith('.zip') &&
                (contentTypes[fileType].includes(file.type) || file.type === "");
            if (!isZipFile) {
                showSnackbar(`Invalid file type. Please upload a ZIP file.`, "error");
                resetUploadState(fileType);
                return;
            }
        } else if (!contentTypes[fileType].includes(file.type)) {
            showSnackbar(`Invalid file type. Please upload a ${contentTypes[fileType][0]} file.`, "error");
            resetUploadState(fileType);
            return;
        }

        try {
            const response = await uploadToS3({
                fileType: contentTypes[fileType][0],
                id: courseId,
                file,
                onProgress: (progress) => updateUploadProgress(fileType, progress)
            }).unwrap();

            if (response.success) {
                showSnackbar(`${fileType.charAt(0).toUpperCase() + fileType.slice(1)} Uploaded Successfully.`, "success");
                refreshMedia();
            } else {
                throw new Error(`Error uploading ${fileType}.`);
            }
        } catch (error) {
            console.error(`Error uploading ${fileType}:`, error);
            showSnackbar(`Error uploading ${fileType}: ${error.message}`, "error");
        } finally {
            resetUploadState(fileType);
        }
    };

    const updateUploadProgress = (fileType, progress) => {
        setUploadStates(prev => ({
            ...prev,
            [fileType]: { ...prev[fileType], progress }
        }));
    };

    const resetUploadState = (fileType) => {
        setUploadStates(prev => ({
            ...prev,
            [fileType]: { isUploading: false, progress: 0 }
        }));
    };

    const showSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const [mediaTimestamp, setMediaTimestamp] = useState(Date.now());

    const refreshMedia = () => {
        setTimeout(() => {
            setMediaTimestamp(Date.now());
        }, 4000); // 4-second delay
    };

    const getTimestampedUrl = (url) => {
        return `${url}?ts=${mediaTimestamp}`;
    };

    const renderUploadButton = (fileType, acceptedTypes) => {
        const isDisabled = (fileType === 'image' || fileType === 'gif') && !hasContentPath;
        const button = (
            <Button
                variant="contained"
                color="secondary"
                sx={{
                    width: '100%',
                    height: '40px',
                }}
                onClick={() => fileInputRefs[fileType].current.click()}
                disabled={uploadStates[fileType].isUploading || isDisabled}
            >
                {uploadStates[fileType].isUploading ? (
                    <CircularProgress size={24} variant="determinate" value={uploadStates[fileType].progress} />
                ) : (
                    `Upload ${fileType.charAt(0).toUpperCase() + fileType.slice(1)}`
                )}
            </Button>
        );

        if (isDisabled) {
            return (
                <Tooltip title="Please upload course content first" placement="top" arrow>
                    <span style={{ flex: 1, width: '100%' }}>{button}</span>
                </Tooltip>
            );
        }


        return (
            <React.Fragment>
                {button}
                <input
                    type="file"
                    ref={fileInputRefs[fileType]}
                    style={{ display: "none" }}
                    accept={acceptedTypes}
                    onChange={(e) => handleFileChange(e, fileType)}
                />
            </React.Fragment>
        );
    };

    if (courseLoading) {
        return <CircularProgress />;
    }

    if (courseError) {
        return <div>Error loading course: {courseError.message}</div>;
    }

    if (!editedCourseData) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Course Name"
                    value={editedCourseData.courseName}
                    fullWidth
                    onChange={(e) => setEditedCourseData({ ...editedCourseData, courseName: e.target.value })}
                    sx={{ mb: 2 }}
                    disabled={true}
                />
                <TextField
                    label="Scored Out Of"
                    value={editedCourseData.scoredOutOf}
                    fullWidth
                    onChange={(e) => setEditedCourseData({ ...editedCourseData, scoredOutOf: e.target.value })}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Course Content Path"
                    value={editedCourseData.courseContentPath}
                    fullWidth
                    onChange={(e) => setEditedCourseData({ ...editedCourseData, courseContentPath: e.target.value })}
                    sx={{ mb: 2 }}
                    disabled={true}
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Autocomplete
                        id="language-code"
                        options={courseLanguageCodeOptions}
                        value={editedCourseData.languageCode}
                        onChange={(_, newValue) => setEditedCourseData({ ...editedCourseData, languageCode: newValue })}
                        renderInput={(params) => <TextField {...params} label="Language" />}
                        sx={{ flex: 1 }}
                    />
                    <Autocomplete
                        id="format-type"
                        options={courseFormatOptions}
                        value={editedCourseData.format}
                        onChange={(_, newValue) => setEditedCourseData({ ...editedCourseData, format: newValue })}
                        renderInput={(params) => <TextField {...params} label="Format Type" />}
                        sx={{ flex: 1 }}
                    />
                </Box>
                <Box>
                    <IncludeInRandomSwitch
                        key={isRandomSend}
                        isChecked={isRandomSend}
                        onChange={handleRandomSendChange}
                    />
                </Box>
                <Box sx={{ bgcolor: (editedCourseData.published ? (lightMode ? 'primary.light' : 'primary.darker') : (lightMode ? 'grey.200' : 'grey.700')), p: 2, borderRadius: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Typography variant="h6">
                            Publish To All Tenancies
                        </Typography>
                        <Switch
                            checked={editedCourseData.published}
                            onChange={(e) => setEditedCourseData({ ...editedCourseData, published: e.target.checked })}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", mt: 2 }}>
                        {!editedCourseData.published && tenanciesData && (
                            <Autocomplete
                                id="tenancy-id"
                                size="small"
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                options={tenanciesData ? tenanciesData : []}
                                value={tenanciesData.find(row => row.tenancyId === editedCourseData.tenancyId) || null}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: "100%" }}
                                onChange={(_, value) => setEditedCourseData({ ...editedCourseData, tenancyId: value ? value.tenancyId : 0 })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Tenancy To Publish To"
                                        InputLabelProps={{
                                            style: { backgroundColor: "transparent" }
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Card sx={{ flex: 1, mr: 1, display: 'flex', flexDirection: 'column' }}>
                        <CardMedia
                            component="img"
                            image={getTimestampedUrl(course_img)}
                            alt="Course Image"
                            sx={{ objectFit: 'contain', maxHeight: 200 }}
                        />
                        <Box sx={{ p: 1 }}>
                            <Typography variant="subtitle2" align="center">Course Image</Typography>
                        </Box>
                    </Card>
                    <Card sx={{ flex: 1, ml: 1, display: 'flex', flexDirection: 'column' }}>
                        <CardMedia
                            component="img"
                            image={getTimestampedUrl(course_gif)}
                            alt="Course GIF"
                            sx={{ objectFit: 'contain', maxHeight: 200 }}
                        />
                        <Box sx={{ p: 1 }}>
                            <Typography variant="subtitle2" align="center">Course GIF</Typography>
                        </Box>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: "flex",
                    gap: 2,
                    mb: 2,
                    '& > *': {
                        flex: 1,
                        width: '33.33%'
                    }
                }}>
                    {Object.entries(fileTypes).map(([fileType, acceptedTypes]) => (
                        <React.Fragment key={fileType}>
                            {renderUploadButton(fileType, acceptedTypes)}
                        </React.Fragment>
                    ))}
                </Box>
                <Box>
                    <Button onClick={handleSave} variant="contained" color="warning" fullWidth disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Save Changes'}
                    </Button>
                </Box>
            </Grid>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}