import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SIMULATION_API_URL } from "config";
import {RESPONSE_API_URL} from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: SIMULATION_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem("user");

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set("authorization", `Bearer ${userToken}`);
        }
        return headers;
    },
    credentials: "omit"
});

const unauthenticatedBaseQuery = fetchBaseQuery({
    baseUrl: RESPONSE_API_URL,
    credentials: "omit"
});

export const simulationApi = createApi({
    reducerPath: "simulationApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getSimulationById: builder.query({
            query: ({ simId, includes }) => `emailsimulation/${simId}/?includes=${includes}`,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            providesTags: (result, error, id) => [{ type: "SimulationByIdData", id }]
        }),
        getSimulations: builder.query({
            query: ({ perPage, page, includes, query, additionalQueryParams }) =>
                `emailsimulation/?perPage=${perPage}&page=${page}&includes=${includes}&query=${query}${additionalQueryParams}`,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            providesTags: (result) => {
                return result ?
                    [...result.map(({ id }) => ({ type: "Simulations", id })), { type: "Simulations", id: "LIST" }]
                    :
                    [{ type: "Simulations", id: "LIST" }];
            }
        }),
        createSimulation: builder.mutation({
            query: (body) => ({
                url: `emailsimulation/`,
                method: "POST",
                body: body
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: [{ type: "Simulations", id: "LIST" }]
        }),
        updateSimulation: builder.mutation({
            query: ({ simData }) => ({
                url: `emailsimulation`,
                method: "PUT",
                body: simData
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, id) => [{ type: "Simulations", id: "LIST" }, { type: "SimulationByIdData", id: id }]
        }),
        uploadSimulationCsv: builder.mutation({
            queryFn: async ({ file }, api, extraOptions, baseQuery) => {
                try {
                    const fileType = file.type;

                    if (!['text/csv', 'application/vnd.ms-excel'].includes(fileType)) {
                        return { error: { status: 400, data: `Unknown File Type: ${fileType}`} };
                    }

                    const preSignedUrlResponse = await baseQuery(`emailsimulation/getpresignedurl?type=${fileType}`, api, extraOptions );

                    if (preSignedUrlResponse.error) {
                        return { error: preSignedUrlResponse.error };
                    }

                    const { presignedUrl } = preSignedUrlResponse.data;

                    const uploadResponse = await fetch(presignedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'x-amz-acl': 'bucket-owner-full-control'
                        }
                    });

                    if (!uploadResponse.ok) {
                        return { error: { status: uploadResponse.status, data: `Request failed, reason: ${uploadResponse.statusText}` } };
                    }

                    return { data: { success: true, message: 'Upload successful' } };
                } catch (error) {
                    return { error: { status: 500, data: `Request failed, reason: ${error.message}` } };
                }
            },
        transformResponse: (response) => response,
        transformErrorResponse: (response) => response.status,
        invalidatesTags: [{ type: "Simulations", id: "LIST" }]
    }),
        uploadToS3: builder.mutation({
            queryFn: async ({ fileType }, api, extraOptions, baseQuery) => {
                // First, get the pre-signed URL
                const preSignedUrlResponse = await baseQuery(`emailsimulation/getpresignedurl?type=${fileType}`, api, extraOptions);

                if (preSignedUrlResponse.error) {
                    return { error: preSignedUrlResponse.error };
                }

                const { presignedUrl } = preSignedUrlResponse.data;

                const uploadResponse = await fetch(presignedUrl, {
                    method: "PUT",
                    body: file,
                    headers: {
                        "x-amz-acl": "bucket-owner-full-control"
                    }
                });

                if (!uploadResponse.ok) {
                    return { error: { status: uploadResponse.status, statusText: uploadResponse.statusText } };
                }
                return { data: { success: true } };
            },
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: [{ type: "Simulations", id: "LIST" }]
        }),
        getTinyJwtToken: builder.query({
            query: () => `emailsimulation/gettinyjwt`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        getCampaignResponse: builder.query({
            query: (uniqueIdentifier) => ({
                url: `campaignresponse?uniqueIdentifier=${uniqueIdentifier}`,
                method: 'POST',
                baseQuery: unauthenticatedBaseQuery
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        postCampaignLandingResponse: builder.query({
            query: (uniqueIdentifier) => ({
                url: `campaignresponse/landingpage?uniqueIdentifier=${uniqueIdentifier}`,
                method: 'POST',
                baseQuery: unauthenticatedBaseQuery
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        getLandingPage: builder.query({
            query: (campaignId) => ({
            url:`emailsimulation/${campaignId}?includes=`,
            method: 'GET',
            baseQuery: unauthenticatedBaseQuery,
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
        }),
        uploadLandingPage: builder.mutation({
            query: ({ html, tenancyName }) => ({
                url: "emailsimulation/landing-page/upload",
                method: "POST",
                body: { html, tenancyName }
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status
        }),
        getEmailSimulationSettings: builder.query({
            query: ({ tenancyId }) => `emailsimulation/settings/${tenancyId}`,
            providesTags: (result, error, id) => [{ type: "UniversityCourseSettings", id }]
        }),
        saveEmailSimulationSettings: builder.mutation({
            query: ({ tenancyId, settingsData }) => ({
                url: `emailsimulation/settings/${tenancyId}`,
                method: "PUT",
                body: settingsData
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status.query(),
            invalidatesTags: (result, error, id) => [{ type: "UniversityCourseSettings", id }]
        }),

        uploadBackgroundImage: builder.mutation({
            query: (imageData) => ({
                url: 'emailsimulation/background-image/upload',
                method: 'POST',
                body: imageData,
            }),
        }),

    })
});

export const {
    useGetSimulationByIdQuery,
    useGetSimulationsQuery,
    useCreateSimulationMutation,
    useUpdateSimulationMutation,
    useUploadSimulationCsvMutation,
    useUploadToS3Mutation,
    useLazyGetTinyJwtTokenQuery,
    useGetCampaignResponseQuery,
    useGetLandingPageQuery,
    useLazyPostCampaignLandingResponseQuery,
    useUploadLandingPageMutation,
    useGetEmailSimulationSettingsQuery,
    useSaveEmailSimulationSettingsMutation,
    useUploadBackgroundImageMutation
} = simulationApi;
