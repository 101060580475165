import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Grid,
    Typography,
    IconButton,
    Tooltip,
    Alert,
    Divider,
    Snackbar,
    CircularProgress
} from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MainCard from '../../components/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import ColorPicker from '../../components/ColorPicker';
import ThemeSquare from '../../components/ThemeSquare';
import { PRESET_THEMES } from '../../themes/PresetThemes';
import { getCardColor } from '../../utils/colorUtils';
import { APP_URL } from '../../config';
import { useUpdateTenancyLogoMutation } from "../../store/services/tenancy.service";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

BrandingSelection.propTypes = {
    state: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function BrandingSelection({ state, onChange }) {
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [updateTenancyLogo] = useUpdateTenancyLogoMutation();
    const { provisionId } = useParams();

    // Initialize local state with current values
    const [primaryColor, setPrimaryColor] = useState(state.branding?.primaryHex || "#FFFFFF");
    const [secondaryColor, setSecondaryColor] = useState(state.branding?.secondaryHex || "#FFFFFF");
    const [tertiaryColor, setTertiaryColor] = useState(state.branding?.tertiaryHex || "#FFFFFF");

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Update local state when parent state changes
    useEffect(() => {
        setPrimaryColor(state.branding?.primaryHex || "#FFFFFF");
        setSecondaryColor(state.branding?.secondaryHex || "#FFFFFF");
        setTertiaryColor(state.branding?.tertiaryHex || "#FFFFFF");
    }, [state.branding]);

    // Effect to handle logo preview
    useEffect(() => {
        if (state.branding?.logo) {
            if (state.branding.logo.startsWith('data:')) {
                setLogoPreview(state.branding.logo);
            } else {
                setLogoPreview(`${APP_URL}/${state.branding.logo}`);
            }
        } else {
            setLogoPreview(null);
        }
    }, [state.branding?.logo]);

    const handleColorChange = (type, color) => {
        const newBranding = { ...state.branding };

        switch (type) {
            case 'primary':
                setPrimaryColor(color);
                newBranding.primaryHex = color;
                break;
            case 'secondary':
                setSecondaryColor(color);
                newBranding.secondaryHex = color;
                break;
            case 'tertiary':
                setTertiaryColor(color);
                newBranding.tertiaryHex = color;
                break;
            default:
                return;
        }

        onChange('branding', newBranding);
    };

    const handlePresetThemeSelect = (theme) => {
        setSelectedTheme(theme);
        setPrimaryColor(theme.primary);
        setSecondaryColor(theme.secondary);
        setTertiaryColor(theme.tertiary);

        const newBranding = {
            ...state.branding,
            primaryHex: theme.primary,
            secondaryHex: theme.secondary,
            tertiaryHex: theme.tertiary
        };

        onChange('branding', newBranding);
    };

    const handleLogoChange = async (file) => {
        setIsUploadingLogo(true);
        try {
            if (!file) {
                throw new Error("No file selected");
            }

            if (file.type !== 'image/png') {
                throw new Error("Please upload a PNG file");
            }

            // Validate image dimensions
            const img = await new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = () => reject(new Error("Error loading image file"));
                const objectUrl = URL.createObjectURL(file);
                img.src = objectUrl;
            });

            if (img.width > 300 || img.height > 300) {
                throw new Error("Image dimensions should not exceed 300x300 pixels");
            }

            // Convert file to base64 for preview and state storage
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                const newBranding = {
                    ...state.branding,
                    logo: base64String,
                    logoFile: file // Store the actual file for later upload
                };
                onChange('branding', newBranding);
            };
            reader.readAsDataURL(file);

        } catch (error) {
            console.error("Error handling logo:", error);
            // You might want to show this error to the user
        } finally {
            setIsUploadingLogo(false);
        }
    };

    const handleSaveLogo = async (file) => {
        setIsUploadingLogo(true);
        try {
            if (!file) {
                throw new Error("No file selected");
            }
            if (file.type !== 'image/png') {
                throw new Error("Please upload a PNG file");
            }
            const img = await new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = () => reject(new Error("Error loading image file"));
                img.src = URL.createObjectURL(file);
            });
            if (img.width > 300 || img.height > 300) {
                throw new Error("Image dimensions should not exceed 300x300 pixels");
            }
            const id = uuidv4();
            const fileName = `assets/logos/${id}.png`;
            const data = {
                file: file,
                tenancyId: provisionId,
                fileName: fileName
            };

            const newBranding = {
                ...state.branding,
                logo: fileName,
            };
            
            await updateTenancyLogo({ data: data, tenancyId: provisionId }).unwrap();
            onChange('branding', newBranding);
            setSnackbarMessage("Company logo uploaded successfully");
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error uploading company logo:", error);
            setSnackbarMessage(error.message || "Error uploading company logo");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setIsUploadingLogo(false);
        }
    };

    const handleRemoveLogo = () => {
        const newBranding = {
            ...state.branding,
            logo: "",
        };
        onChange('branding', newBranding);
        setLogoPreview(null);
    };

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
                <MainCard
                    content={true}
                    boxShadow={true}
                    border={false}
                    sx={{
                        height: '100%',
                        bgcolor: getCardColor(lightMode),
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Upload your company logo here. The logo will be displayed in the top left in the sidebar for all users.">
                                <IconButton>
                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h4">
                                Upload Logo
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ width: "100%", mb: 2 }} />

                    {/* <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '200px'
                    }}>
                        {logoPreview && (
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 6,
                                mt: 6,
                                width: '120px',
                                height: '120px'
                            }}>
                                <img
                                    src={logoPreview}
                                    alt="Logo"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: "contain"
                                    }}
                                />
                            </Box>
                        )}
                    </Box> */}

                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {state.branding?.logo && (
                            <Box sx={{ display: "flex", justifyContent: "center", mb: 6, mt: 6 }}>
                                <img src={`${APP_URL}/${state.branding?.logo}`} alt="Logo" width={120} height="auto"
                                    className="logo-image" style={{ objectFit: "contain" }} />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ mt: 'auto', display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="label"
                            fullWidth
                            disabled={isUploadingLogo}
                        >
                            {isUploadingLogo ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                logoPreview ? "Change Logo" : "Upload Logo"
                            )}
                            <input
                                type="file"
                                accept="image/png"
                                hidden
                                onChange={(e) => handleSaveLogo(e.target.files[0])}
                            />
                        </Button>
                        {logoPreview && (
                            <Button
                                variant="outlined"
                                color="black"
                                fullWidth
                                onClick={handleRemoveLogo}
                            >
                                Remove Logo
                            </Button>
                        )}
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            Logos must be a .png file with maximum dimensions of 300x300
                        </Alert>
                    </Box>
                </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
                <MainCard
                    content={true}
                    boxShadow={true}
                    border={false}
                    sx={{
                        height: '100%',
                        bgcolor: getCardColor(lightMode),
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Choose custom colors that will be used as the theme for all users.">
                                <IconButton>
                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h4">
                                Custom Theme Selector
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ width: "100%", mb: 2 }} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container justifyContent="flex-left" alignItems="flex-start">
                            <Grid item xs={12} sm={6}>
                                <ColorPicker
                                    label="Primary"
                                    color={primaryColor}
                                    onChange={(color) => handleColorChange('primary', color)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ColorPicker
                                    label="Secondary"
                                    color={secondaryColor}
                                    onChange={(color) => handleColorChange('secondary', color)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ColorPicker
                                    label="Tertiary"
                                    color={tertiaryColor}
                                    onChange={(color) => handleColorChange('tertiary', color)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
                <MainCard
                    content={true}
                    boxShadow={true}
                    border={false}
                    sx={{
                        height: '100%',
                        bgcolor: getCardColor(lightMode),
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Choose preset colors that will be used as the theme for all users.">
                                <IconButton>
                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h4">
                                Preset Theme Selector
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ width: "100%", mb: 2 }} />

                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Preset Themes</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
                            {PRESET_THEMES.map((theme) => (
                                <ThemeSquare
                                    key={theme.name}
                                    theme={theme}
                                    onClick={() => handlePresetThemeSelect(theme)}
                                    isSelected={selectedTheme?.name === theme.name}
                                />
                            ))}
                        </Box>
                    </Box>
                </MainCard>
            </Grid>
        </Grid>

        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
        >
            <Alert
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarSeverity}
                variant="filled"
                sx={{ width: "100%" }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </>
    );
}