import ProvisioningLayout from 'layout/ProvisioningLayout';
import Provisioning from "../pages/provisioning";

const ProvisioningRoutes = {
    element: <ProvisioningLayout />,
    children: [
        {
            path: "provision/:provisionId",
            element: <Provisioning />
        }
    ]
};

export default ProvisioningRoutes;
