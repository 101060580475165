import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Modal, Box, TextField, Typography, Grid, Button, Alert, CircularProgress, IconButton, Divider } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useCreateTenancyMutation } from "../../store/services/tenancy.service";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";


AddTenancyModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default function AddTenancyModal(props) {
    const { open, onClose } = props;
    const theme = useTheme();
    const { tenancyId, tenancyName: parentTenancyName } = useSelector((state) => state.tenancy);
    const user = useMemo(() => JSON.parse(localStorage.getItem('user')), []);
    const isSuperAdmin = user?.role === 'Administrators';

    // States for form fields
    const [tenancyName, setTenancyName] = useState("");
    const [userLicenses, setUserLicenses] = useState(0);

    // States for modal control
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [countdown, setCountdown] = useState(2);

    const [addTenancy] = useCreateTenancyMutation();

    const handleSave = () => {
        if (!tenancyName || userLicenses === 0) {
            setErrorMessage("Please fill in all required fields");
            setTimeout(() => {
                setErrorMessage("");
            }, 2000);
            return;
        }

        const tenancy = {
            name: tenancyName,
            userLicenses: userLicenses,
            parentTenancyId: tenancyId
        };

        setLoading(true);

        addTenancy({ data: tenancy }).unwrap()
            .then(() => {
                setLoading(false);
                setSuccess(true);
                setErrorMessage("");
                setCountdown(3);
                const timer = setInterval(() => {
                    setCountdown((prev) => {
                        if (prev === 1) {
                            clearInterval(timer);
                            onClose();
                            setSuccess(false);
                        }
                        return prev - 1;
                    });
                }, 1000);
            }).catch((error) => {
                setErrorMessage("Error adding tenancy: " + error.message);
                setLoading(false);
                setTimeout(() => {
                    setErrorMessage("");
                }, 2000);
            });
    };

    // Reset form on close
    useEffect(() => {
        if (!open) {
            setTenancyName("");
            setUserLicenses(0);
            setSuccess(false);
            setErrorMessage("");
            setCountdown(3);
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="add-tenancy-modal-title"
            aria-describedby="add-tenancy-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.default",
                    width: "80%",
                    maxWidth: 600,
                    boxShadow: theme.customShadows.z1,
                    p: 4,
                    borderRadius: theme.shape.borderRadius,
                }}
            >
                {success ? (
                    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} m={4}>
                        <CheckCircleIcon sx={{ color: "primary.main", fontSize: 80, mb: 2 }} />
                        <Typography variant="h5">Tenancy added successfully</Typography>
                        <Button
                            sx={{ mt: 4, width: "50%" }}
                            variant="contained"
                            color="primary"
                            onClick={onClose}
                        >
                            Close ({countdown})
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
                            <Typography id="add-tenancy-modal-title" variant="h4" gutterBottom>
                                Add Tenancy
                            </Typography>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Divider sx={{ mb: 3 }} />
                        {errorMessage && (
                            <Alert severity="error" sx={{ mb: 2 }} onClose={() => setErrorMessage("")}>
                                {errorMessage}
                            </Alert>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    label="Parent Tenancy ID"
                                    value={tenancyId}
                                    disabled={!isSuperAdmin}
                                    onChange={(e) => setParentTenancyId(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Tenancy Name"
                                    value={tenancyName}
                                    onChange={(e) => {
                                        setTenancyName(e.target.value);
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    type="number"
                                    label="User Licenses"
                                    value={userLicenses}
                                    onChange={(e) => {
                                        setUserLicenses(e.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={3} display="flex" justifyContent="flex-end">
                            <Button onClick={onClose} variant="outlined" color="inherit" sx={{ mr: 2 }}>Cancel</Button>
                            <Button onClick={handleSave} variant="contained" color="warning" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : "Create Tenancy"}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
}