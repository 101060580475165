import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SyncStatusCard from "../../components/cards/SyncStatusCard";
import { useGetAllUniversityCourseSettingsQuery } from "../../store/services/university-service";
import { useGetTenanciesQuery, useGetImportStatusQuery } from "../../store/services/tenancy.service";
import Table from "../../components/Table/Table";
import { Alert, Button, Modal, Snackbar, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AddTenancyModal from "../tenancies/AddTenancyModal";
import AddIcon from "@mui/icons-material/Add";

TenantTable.propTypes = {
    tenantIds: PropTypes.array,
    setTenantId: PropTypes.func,
    onRandomSelect: PropTypes.func
};

export default function TenantTable(props) {
    const dispatch = useDispatch();
    const { tenantIds = [], setTenantId, onRandomSelect } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPartnerAdmin, setIsPartnerAdmin] = useState(false);
    const [addTenancyModalOpen, setAddTenancyModalOpen] = useState(false);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 5000,
        page: 0
    });

    const { data: tenantsData, isLoading: tenantsLoading, error: tenantsError } = useGetTenanciesQuery({
        perPage: paginationModel.pageSize,
        page: paginationModel.page,
        includes: "",
        query: "",
        additionalQueryParams: ""  
    });

    const {
        data: importStatusData,
        isLoading: importStatusLoading,
        error: importStatusError,
    } = useGetImportStatusQuery({
        tenancyId: tenancyId,
        all: true
    });

    const {
        data: settingsData,
        isLoading: settingsLoading,
        error: settingsError
    } = useGetAllUniversityCourseSettingsQuery();

    const combinedData = useMemo(() => {
        if (!tenantsData || !settingsData || !importStatusData) return [];

        return tenantsData.map(tenant => {
            const tenancySetting = settingsData.find(
                setting => setting.tenancyId === tenant.tenancyId
            );
            const importStatus = importStatusData[tenant.tenancyId];

            const mostRecentSync = importStatus?.length ?
                importStatus.reduce((latest, current) => {
                    if (!latest) return current;
                    return new Date(current.dateTime) > new Date(latest.dateTime) ? current : latest;
                }, null)
                : null;

            return {
                ...tenant,
                oneClickCourses: tenancySetting?.allowUnauthorisedTakeCourse || false,
                syncType: mostRecentSync?.sk || 'None',
                latestAdminActivity: mostRecentSync?.dateTime || null
            };
        });
    }, [tenantsData, settingsData, importStatusData]);

    const isTableLoading = tenantsLoading || settingsLoading || importStatusLoading;
    const tableError = tenantsError || settingsError || importStatusError;

    const getSyncTypeColor = (syncType) => {
        switch (syncType.toLowerCase()) {
            case 'google':
                return 'primary';
            case 'office':
                return 'success';
            case 'none':
                return 'default';
            default:
                return 'default';
        }
    };

    const [selected, setSelected] = useState([]);
    const [filteredTenants, setFilteredTenants] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
        setIsPartnerAdmin(user && user.role.includes("PartnerAdministrators"));
    }, []);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleEnterTenant = useCallback((tenant) => {
        localStorage.setItem('selectedTenancyId', tenant.tenancyId);
        localStorage.setItem('selectedTenancyName', tenant.name);

        window.location.reload();
    }, []);

    function handleAddTenancy() {
        setAddTenancyModalOpen(true);
    }

    const handleConfigureTenant = (tenant) => {
        navigate(`/provision/${tenant.tenancyId}`);
    };

    const toolbarActions = (isAdmin || isPartnerAdmin) ? [
        {
            render: () => (
                <Box>
                    <Button
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => handleAddTenancy()}
                        endIcon={<AddIcon />}
                    >
                        Add Tenancy
                    </Button>
                </Box>
            )
        }
    ] : [];


    const headCells = [
        {
            id: "enter",
            label: "",
            render: (dataRow) => (
                <Button
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleEnterTenant(dataRow);
                    }}
                    aria-label="enter tenant"
                    variant="contained"
                    color="warning"
                >
                    Enter
                </Button>
            ),
            initialVisible: true
        },
        ...((isAdmin || isPartnerAdmin) ? [{
            id: "configure",
            label: "",
            render: (dataRow) => (
                <Button
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleConfigureTenant(dataRow);
                    }}
                    aria-label="configure tenant"
                    variant="contained"
                    color="secondary"
                >
                    Configure
                </Button>
            ),
            initialVisible: true
        }] : []),
        {
            id: "tenancyId",
            label: "ID",
            initialVisible: true
        },
        {
            id: "name",
            label: "Name",
            initialVisible: true
        },
        {
            id: "userLicenses",
            label: "Licence Count",
            initialVisible: true
        },
        {
            id: 'syncType',
            label: 'User Sync Type',
            initialVisible: true,
            render: (row) => {
                const getSyncStatus = () => {
                    if (!row.syncType || row.syncType === 'None') return 'None';
                    if (row.syncType === 'google') return 'Google';
                    if (row.syncType === 'office') return 'Microsoft';
                    return 'Other';
                };

                return (
                    <Box sx={{ width: 125 }}>  
                        <SyncStatusCard
                            status={getSyncStatus()}
                            size="small"
                        />
                    </Box>
                );
            }
        },
        /*
        {
            id: 'latestAdminActivity',
            label: 'Latest Admin Activity',
            initialVisible: false
        },
        */
        {
            id: 'oneClickCourses',
            label: 'One Click',
            initialVisible: true,
            render: (row) => (
                <Chip
                    label={row.oneClickCourses ? "On" : "Off"}
                    color={row.oneClickCourses ? "primary" : "default"}
                    sx={{
                        backgroundColor: row.oneClickCourses ? undefined : theme.palette.grey[300],
                        color: row.oneClickCourses ? undefined : theme.palette.grey[700]
                    }}
                    size="small"
                />
            )
        },
        {
            id: 'enableCourseRetake',
            label: 'Retakes',
            initialVisible: true,
            render: (row) => (
                <Chip
                    label={row.enableCourseRetake ? "On" : "Off"}
                    color={row.enableCourseRetake ? "primary" : "default"}
                    sx={{
                        backgroundColor: row.enableCourseRetake ? undefined : theme.palette.grey[300],
                        color: row.enableCourseRetake ? undefined : theme.palette.grey[700]
                    }}
                    size="small"
                />
            )
        },
        {
            id: 'automaticCourseAssignmentDays',
            label: 'Auto Assign',
            initialVisible: true,
            render: (row) => {
                const isEnabled = row.automaticCourseAssignmentDays !== "-1" && row.automaticCourseAssignmentDays !== null;
                return (
                    <Chip
                        label={isEnabled ? "On" : "Off"}
                        color={isEnabled ? "primary" : "default"}
                        sx={{
                            backgroundColor: isEnabled ? undefined : theme.palette.grey[300],
                            color: isEnabled ? undefined : theme.palette.grey[700]
                        }}
                        size="small"
                    />
                );
            }
        },
    ];

    return (
        <>
            <Table
                name={"Tenants"}
                data={filteredTenants.length > 0 ? filteredTenants : combinedData}
                error={tableError}
                loading={isTableLoading}
                headCells={headCells}
                search={true}
                editColumn={true}
                checkbox={false}
                showDelete={false}
                toolbarActions={toolbarActions}
                showToolbarActions={true}
                onDelete={() => console.log("Delete Tenants Not Allowed")}
                onCellClick={() => console.log("View Tenant attempts")}
                selected={selected}
                setSelected={setSelected}
                maxTableHeight={"calc(100vh - 250px)"}
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />

            <AddTenancyModal
                open={addTenancyModalOpen}
                onClose={() => setAddTenancyModalOpen(false)}
            />

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}