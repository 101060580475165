import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    useGetTenancyBasicsQuery,
    useGetTenancyByIdQuery,
} from "../../store/services/tenancy.service";
import {
    Alert,
    Box,
    Button,
    Grid,
    Snackbar,
    Typography,
    CircularProgress,
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { getCardColor } from "../../utils/colorUtils";
import { useThemeContext } from '../../themes/ThemeContext';
import { useSelector } from "react-redux";
import PdfExporter from "../../components/LandingPage/PdfExporter";

LandingPageSettings.propTypes = {
    data: PropTypes.object
};

export default function LandingPageSettings(props) {
    const { data: propsData } = props;
    const { tenancyId } = useSelector((state) => state.tenancy);
    const navigate = useNavigate();
    const theme = useTheme();
    const { customColors } = useThemeContext();
    const [isAdmin, setIsAdmin] = useState(false);
    const lightMode = theme.palette.mode === 'light';

    // Query for tenancy data
    const { data: tenancyData, isLoading: tenancyLoading } = useGetTenancyByIdQuery({ tenancyId });

    // State for notifications
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Check for admin role
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
    }, []);

    // For debugging - log what data we have
    useEffect(() => {
        if (tenancyData) {
            console.log("Tenancy Data:", tenancyData);
            console.log("Landing Page URL:", tenancyData.landingPageUrl);
        }
    }, [tenancyData]);

    // Combine data sources - prioritize data from query, then props
    const combinedData = tenancyData || propsData;

    // Handle PDF export success
    const handleExportSuccess = (fileName) => {
        setSnackbarMessage(`Successfully exported PDF as ${fileName}`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
    };

    // Handle PDF export error
    const handleExportError = (error) => {
        setSnackbarMessage(`Failed to export PDF: ${error.message || "Unknown error"}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
    };

    // Check for loading state or no data
    if (tenancyLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!combinedData) {
        return <Typography>No tenancy data available</Typography>;
    }

    // Get landing page URL, checking both possible property names
    const landingPageUrl = combinedData.landingPageUrl;

    // Generate a safe filename for the PDF
    const tenancyName = combinedData?.name || 'landing_page';
    const fileName = `${tenancyName.replace(/[^a-z0-9_-]/gi, '_')}_landingPage.pdf`;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MainCard
                                    content={true}
                                    boxShadow={true}
                                    border={false}
                                    sx={{
                                        height: '100%',
                                        bgcolor: getCardColor(lightMode),
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        mb: 1
                                    }}>
                                    </Box>

                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: 2,
                                        marginTop: 1
                                    }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            onClick={() => navigate("/settings/customlandingpage")}
                                            sx={{ px: 3, flex: 1 }}
                                        >
                                            Edit
                                        </Button>

                                        {/* Use our new PdfExporter component */}
                                        <PdfExporter
                                            url={landingPageUrl}
                                            fileName={fileName}
                                            buttonText="Export to PDF"
                                            buttonProps={{
                                                size: "medium",
                                                sx: { px: 3, flex: 1 }
                                            }}
                                            onSuccess={handleExportSuccess}
                                            onError={handleExportError}
                                        />
                                    </Box>
                                    <Box sx={{ marginTop: 2 }}>
                                        {landingPageUrl ? (
                                            <div className="iframe-container" style={{
                                                width: '100%',
                                                height: '485px',
                                                overflow: 'hidden',
                                                position: 'relative'
                                            }}>
                                                <iframe
                                                    src={landingPageUrl}
                                                    title="Landing Page Preview"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-100px',
                                                        left: '50%',
                                                        width: '250%',
                                                        height: '280%',
                                                        border: 'none',
                                                        transform: 'scale(0.5) translateX(-50%)', /* Scale and center horizontally */
                                                        transformOrigin: 'top left', /* Set origin to top left for precise positioning */
                                                        overflow: 'hidden'
                                                    }}
                                                    scrolling="no"
                                                />
                                            </div>
                                        ) : (
                                            <Typography variant="body1" color="text.secondary" sx={{ p: 3, textAlign: 'center' }}>
                                                No landing page URL available. Click 'Edit' to create one.
                                            </Typography>
                                        )}
                                    </Box>
                                </MainCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}