import React, { useState } from "react";
import { Grid, Stack, Tab, Tabs, Typography, Box } from "@mui/material";
import TenancySettings from "./TenancySettings";
import CompanyInformation from "./CompanyInformation";
import Journeys from "./Journeys";
import DefaultLandingPage from "./DefaultLandingPage";
import PhishReportButton from "./PhishReportButton";
import { useGetTenancyByIdQuery } from "../../store/services/tenancy.service";
import MainCard from "../../components/cards/MainCard";
import { useSelector } from "react-redux";
import TenancySettingSkeletonComponent from "../../components/Skeleton/TenancySettingSkeletonComponent";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getStatusMessage } from "../../utils/getStatusMessage";
import ApiIntergration from "./ApiIntergration";
import Deliverability from "./Deliverability";
import Branding from "./Branding";
import ScrollableTabs from "../../components/ScrollableTabs";
import { BRAND_TYPE } from "../../config";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tenancy-tabpanel-${index}`}
            aria-labelledby={`tenancy-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tenancy-tab-${index}`,
        'aria-controls': `tenancy-tabpanel-${index}`,
    };
}

export default function TenancyManagement() {
    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user.role === "Administrators";

    const { tenancyId } = useSelector((state) => state.tenancy);

    const [tabValue, setTabValue] = useState(0);

    const { data: tenancyData, isLoading: tenancyLoading, error: tenancyError } = useGetTenancyByIdQuery({ tenancyId });
    const [filteredTabs, setFilteredTabs] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        if (!tenancyData) {
            return;
        }
        const tabs = [
            { label: "General", component: <TenancySettings data={tenancyData} />, adminOnly: false },
            { label: "Deliverability", component: <Deliverability data={tenancyData} />, adminOnly: false, boxphishOnly: false  },
            { label: "Branding", component: <Branding data={tenancyData} />, adminOnly: false },
            { label: "Company Information", component: <CompanyInformation data={tenancyData} />, adminOnly: false },
            { label: "Journeys", component: <Journeys data={tenancyData} />, adminOnly: true },
            { label: "Default Landing Page", component: <DefaultLandingPage data={tenancyData} />, adminOnly: true },
            { label: "Phish Report Button", component: <PhishReportButton data={tenancyData} />, adminOnly: false, boxphishOnly: true },
            { label: "API Integration", component: <ApiIntergration data={tenancyData} />, adminOnly: false, restrictedTenancies: [3, 31, 136, 154, 283, 360, 581, 600, 605, 781, 860, 815, 552, 835, 825, 865, 565, 423, 276, 435, 895, 978, 222, 1062, 294, 748 ], boxphishOnly: true  },
        ];

        setFilteredTabs(tabs.filter(tab => {
            if (tab.adminOnly && !isAdmin) {
                return false;
            }
            if (tab.restrictedTenancies && !tab.restrictedTenancies.includes(parseInt(tenancyData.tenancyId))) {
                return false;
            }
            if (tab.boxphishOnly && BRAND_TYPE !== "boxphish") {
                return false;
            }
            return true;
        }));

    }, [tenancyData]);

    if (tenancyLoading) {
        return <TenancySettingSkeletonComponent />;
    }

    if (tenancyError) {
        return (
            <MainCard boxShadow={true} border={false}
                sx={{ minHeight: "calc(100vh - 200px)", display: "flex", justifyContent: "center", alignItems: "center", bgcolor: 'background.default' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Stack direction="column" spacing={4} alignItems="center">
                            <ErrorOutlineIcon color="error" style={{ fontSize: 80 }} />
                            <Typography variant="h3" align="center" color="textPrimary">
                                {getStatusMessage(tenancyError)}
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary">
                                Please refresh the page or contact the administrator.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }



    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12}>
                <Box sx={{ mx: 2 }}>
                    <ScrollableTabs
                        value={tabValue}
                        onChange={handleTabChange}
                        ariaLabel="tenancy management tabs"
                    >
                        {filteredTabs.map((tab, index) => (
                            <Tab key={index} label={tab.label} {...a11yProps(index)} />
                        ))}
                    </ScrollableTabs>
                </Box>
                <MainCard sx={{ bgcolor: 'background.default', minHeight: 'calc(100vh - 200px)' }} content={true} boxShadow={true} border={false}>
                    {filteredTabs.map((tab, index) => (
                        <TabPanel key={index} value={tabValue} index={index}>
                            {tab.component}
                        </TabPanel>
                    ))}
                </MainCard>
            </Grid>
        </Grid>
    );
}
