// components/LandingPage/extractValuesFromHTML.js

/**
 * Extracts form values from processed HTML content
 * This function is specifically designed for the preset landing page template
 * and extracts values based on its known structure
 *
 * @param {string} html - The processed HTML content from S3
 * @returns {Object} - Object containing extracted form values
 */
const extractValuesFromHTML = (html) => {
    if (!html) return {};

    try {
        // Create a DOM parser to work with the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Extract values using DOM queries based on the template structure

        // Header title - will be in an h1 element
        const headerTitle = doc.querySelector('h1')?.textContent?.trim() || '';

        // Main content - this is a paragraph after the h1
        let mainContent = '';
        const paragraphs = doc.querySelectorAll('p');
        if (paragraphs.length > 0) {
            // It's the first paragraph after the h1
            mainContent = paragraphs[0].innerHTML.trim();
        }

        // Tips heading - will be in the h2 element
        const tipsHeading = doc.querySelector('h2')?.textContent?.trim() || '';

        // Tips - extract from the "tip" divs
        const tipElements = doc.querySelectorAll('.tip');
        const tipTitles = [];
        const tipContents = [];

        if (tipElements && tipElements.length > 0) {
            Array.from(tipElements).forEach((tipElement, index) => {
                // Extract the title (h3) and content (p) from each tip
                const titleElement = tipElement.querySelector('h3');
                const contentElement = tipElement.querySelector('p');

                if (titleElement) {
                    tipTitles[index] = titleElement.textContent.trim();
                }

                if (contentElement) {
                    tipContents[index] = contentElement.textContent.trim();
                }
            });
        }

        // Background image - extract from the tips-grid background-image
        let backgroundImage = '';
        const tipsGrid = doc.querySelector('.tips-grid');
        if (tipsGrid) {
            const style = tipsGrid.getAttribute('style') || '';
            const match = style.match(/background-image:\s*url\(['"]?(.*?)['"]?\)/);
            if (match && match[1]) {
                backgroundImage = match[1].replace(/'/g, ''); // Remove any quotes
            }
        }

        // Extract primary color
        let primaryColor = '#145049'; // Default color

        // Try to extract from h1 background-color
        const h1Element = doc.querySelector('h1');
        if (h1Element) {
            const style = h1Element.getAttribute('style') ||
                window.getComputedStyle(h1Element).backgroundColor;

            if (style) {
                const colorMatch = style.match(/background-color:\s*(#[0-9a-fA-F]{6}|rgb\(\d+,\s*\d+,\s*\d+\))/);
                if (colorMatch && colorMatch[1]) {
                    primaryColor = colorMatch[1];
                }
            }
        }

        // If not found in h1, try the tip border-left color
        if (primaryColor === '#145049' && tipElements.length > 0) {
            const tipStyle = tipElements[0].getAttribute('style') ||
                window.getComputedStyle(tipElements[0]).borderLeftColor;

            if (tipStyle) {
                const colorMatch = tipStyle.match(/border-left-color:\s*(#[0-9a-fA-F]{6}|rgb\(\d+,\s*\d+,\s*\d+\))/);
                if (colorMatch && colorMatch[1]) {
                    primaryColor = colorMatch[1];
                }
            }
        }

        // Extract logo information
        let showLogo = true;
        const headerDiv = doc.querySelector('.header');
        if (!headerDiv || headerDiv.children.length === 0) {
            showLogo = false;
        }

        let logoSize = 120; // Default size
        const logoImg = doc.querySelector('.header img');
        if (logoImg) {
            const width = logoImg.getAttribute('width');
            if (width && !isNaN(parseInt(width))) {
                logoSize = parseInt(width);
            } else {
                // Try to extract from style
                const styleWidth = window.getComputedStyle(logoImg).width;
                if (styleWidth && styleWidth.endsWith('px')) {
                    const size = parseInt(styleWidth);
                    if (!isNaN(size)) {
                        logoSize = size;
                    }
                }
            }
        }

        // Construct the result object with all extracted values
        return {
            headerTitle,
            mainContent,
            tipsHeading,
            tip1Title: tipTitles[0] || '',
            tip1Content: tipContents[0] || '',
            tip2Title: tipTitles[1] || '',
            tip2Content: tipContents[1] || '',
            tip3Title: tipTitles[2] || '',
            tip3Content: tipContents[2] || '',
            tip4Title: tipTitles[3] || '',
            tip4Content: tipContents[3] || '',
            tip5Title: tipTitles[4] || '',
            tip5Content: tipContents[4] || '',
            tip6Title: tipTitles[5] || '',
            tip6Content: tipContents[5] || '',
            backgroundImage,
            brandColors: {
                primary: primaryColor
            },
            showLogo,
            logoSize
        };
    } catch (error) {
        console.error('Error extracting values from HTML:', error);
        return {}; // Return empty object on error
    }
};

export default extractValuesFromHTML;