export const PRESET_TEMPLATES = [
    {
        name: 'Sharp Corners Sharp Button',
        template: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <!--[if gte mso 15]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Email Template</title>
    
    <!--[if mso]>
    <style type="text/css">
        .outlook-button-fix {
            padding: 12px 36px !important;
            width: auto !important;
            height: auto !important;
        }
        table.logo-container {
            width: 100%;
        }
    </style>
    <![endif]-->
</head>
<body style="margin:0; padding:0; background-color:#F2F2F2;">
    <center>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: separate; border-spacing: 0; box-shadow: 0 4px 12px rgba(0,0,0,0.1);">
            <tr>
                <td bgcolor="#ffffff" style="padding: 40px 0;">
                    <!--[if mso]>
                    <table class="logo-container" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                    <td align="center">
                    <![endif]-->
                    <img src="{client.logo}" alt="Logo" width="{logo.size}" style="display: block; margin: 0 auto;" />
                    <!--[if mso]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
            <tr>
                <td bgcolor="#ffffff" style="padding: 40px 30px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                        <tr>
                            <td style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 20px 0 30px 0;">
                                {mainText}
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <!--[if gte mso 9]>
                                <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:180px;height:48px;">
                                    <v:fill type="tile" color="{color.primary}" />
                                    <v:textbox inset="0,0,0,0">
                                <![endif]-->
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;">
                                    <tbody>
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center">
                                            <table>
                                                <tr>
                                                    <td bgcolor="{color.primary}" style="font-family:'Roboto',Helvetica,Arial,sans-serif; text-align:center; width:180px; max-width:200px; font-weight:normal; font-size:18px; line-height:24px; color:#fffffe; background-color: {color.primary};">
                                                        <a href="{user.action}" name="mybutton" style="display:block;padding:12px 24px;text-decoration:none;color:#fffffe" target="_blank">{button.text}</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <!--[if gte mso 9]>
                                    </v:textbox>
                                </v:rect>
                                <![endif]-->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </center>
</body>
</html>`
    },
    {
        name: 'Rounded Corners Rounded Button',
        template: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <!--[if gte mso 15]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Email Template</title>
    
    <!--[if mso]>
    <style type="text/css">
        .outlook-button-fix {
            padding: 12px 36px !important;
            width: auto !important;
            height: auto !important;
        }
        table.logo-container {
            width: 100%;
        }
    </style>
    <![endif]-->
</head>
<body style="margin:0; padding:0; background-color:#F2F2F2;">
    <center>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: separate; border-spacing: 0; border-radius: 16px; box-shadow: 0 4px 12px rgba(0,0,0,0.1);">
            <tr>
                <td bgcolor="#ffffff" style="padding: 40px 0; border-radius: 16px 16px 0 0;">
                    <!--[if mso]>
                    <table class="logo-container" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                    <td align="center">
                    <![endif]-->
                    <img src="{client.logo}" alt="Logo" width="{logo.size}" style="display: block; margin: 0 auto;" />
                    <!--[if mso]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
            <tr>
                <td bgcolor="#ffffff" style="padding: 40px 30px; border-radius: 0 0 16px 16px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                        <tr>
                            <td style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 20px 0 30px 0;">
                                {mainText}
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <!--[if gte mso 9]>
                                <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:180px;height:48px; arcsize=10%">
                                    <v:fill type="tile" color="{color.primary}" />
                                    <v:textbox inset="0,0,0,0">
                                <![endif]-->
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;">
                                    <tbody>
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center">
                                            <table>
                                                <tr>
                                                    <td bgcolor="{color.primary}" style="font-family:'Roboto',Helvetica,Arial,sans-serif; text-align:center; width:180px; max-width:200px; font-weight:normal; font-size:18px; line-height:24px; color:#fffffe; background-color: {color.primary}; border-radius: 4px;">
                                                        <a href="{user.action}" name="mybutton" style="display:block;padding:12px 24px;text-decoration:none;color:#fffffe" target="_blank">{button.text}</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <!--[if gte mso 9]>
                                    </v:textbox>
                                </v:roundrect>
                                <![endif]-->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </center>
</body>
</html>`
    },
    {
        name: 'Big Sharp Header Sharp Button',
        template: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <!--[if gte mso 15]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Email Template</title>
    
    <!--[if mso]>
    <style type="text/css">
        .outlook-button-fix {
            padding: 12px 36px !important;
            width: auto !important;
            height: auto !important;
        }
        table.logo-container {
            width: 100%;
        }
    </style>
    <![endif]-->
</head>
<body style="margin:0; padding:0; background-color:#F2F2F2;">
    <center>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: separate; border-spacing: 0; box-shadow: 0 4px 12px rgba(0,0,0,0.1);">
            <tr>
                <td bgcolor="{color.primary}" style="padding: 60px 0; text-align: center;">
                    <!--[if mso]>
                    <table class="logo-container" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                    <td align="center">
                    <![endif]-->
                    <img src="{client.logo}" alt="Logo" width="{logo.size}" style="display: block; margin: 0 auto;" />
                    <!--[if mso]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
            <tr>
                <td bgcolor="#ffffff" style="padding: 10px 40px 30px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                        <tr>
                            <td style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 20px 0 30px 0;">
                                {mainText}
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <!--[if gte mso 9]>
                                <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:180px;height:48px;">
                                    <v:fill type="tile" color="{color.primary}" />
                                    <v:textbox inset="0,0,0,0">
                                <![endif]-->
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;">
                                    <tbody>
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center">
                                            <table>
                                                <tr>
                                                    <td bgcolor="{color.primary}" style="font-family:'Roboto',Helvetica,Arial,sans-serif; text-align:center; width:180px; max-width:200px; font-weight:normal; font-size:18px; line-height:24px; color:#fffffe; background-color: {color.primary};">
                                                        <a href="{user.action}" name="mybutton" style="display:block;padding:12px 24px;text-decoration:none;color:#fffffe" target="_blank">{button.text}</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <!--[if gte mso 9]>
                                    </v:textbox>
                                </v:rect>
                                <![endif]-->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </center>
</body>
</html>`
    },
    {
        name: 'Rounded Header Rounded Button',
        template: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <!--[if gte mso 15]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Email Template</title>
    
    <!--[if mso]>
    <style type="text/css">
        .outlook-button-fix {
            padding: 12px 36px !important;
            width: auto !important;
            height: auto !important;
        }
        table.logo-container {
            width: 100%;
        }
    </style>
    <![endif]-->
</head>
<body style="margin:0; padding:0; background-color:#F2F2F2;">
    <center>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: separate; border-spacing: 0; border-radius: 16px; box-shadow: 0 4px 12px rgba(0,0,0,0.1);">
            <tr>
                <td bgcolor="{color.primary}" style="padding: 60px 0; text-align: center; border-radius: 16px 16px 0 0;">
                    <!--[if mso]>
                    <table class="logo-container" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                    <td align="center">
                    <![endif]-->
                    <img src="{client.logo}" alt="Logo" width="{logo.size}" style="display: block; margin: 0 auto;" />
                    <!--[if mso]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
            <tr>
                <td bgcolor="#ffffff" style="padding: 10px 40px 30px; border-radius: 0 0 16px 16px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                        <tr>
                            <td style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 20px 0 30px 0;">
                                {mainText}
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <!--[if gte mso 9]>
                                <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:180px;height:48px; arcsize=10%">
                                    <v:fill type="tile" color="{color.primary}" />
                                    <v:textbox inset="0,0,0,0">
                                <![endif]-->
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;">
                                    <tbody>
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center">
                                            <table>
                                                <tr>
                                                    <td bgcolor="{color.primary}" style="font-family:'Roboto',Helvetica,Arial,sans-serif; text-align:center; width:180px; max-width:200px; font-weight:normal; font-size:18px; line-height:24px; color:#fffffe; background-color: {color.primary}; border-radius: 4px;">
                                                        <a href="{user.action}" name="mybutton" style="display:block;padding:12px 24px;text-decoration:none;color:#fffffe" target="_blank">{button.text}</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <!--[if gte mso 9]>
                                    </v:textbox>
                                </v:roundrect>
                                <![endif]-->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </center>
</body>
</html>`
    },
    {
        name: 'Half Height Header Sharp',
        template: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <!--[if gte mso 15]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Email Template</title>
    
    <!--[if mso]>
    <style type="text/css">
        .outlook-button-fix {
            padding: 12px 36px !important;
            width: auto !important;
            height: auto !important;
        }
        table.logo-container {
            width: 100%;
        }
    </style>
    <![endif]-->
</head>
<body style="margin:0; padding:0; background-color:#F2F2F2;">
    <center>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: separate; border-spacing: 0; box-shadow: 0 4px 12px rgba(0,0,0,0.1);">
            <tr>
                <td bgcolor="{color.primary}" style="padding: 30px 0; text-align: center;">
                    <!--[if mso]>
                    <table class="logo-container" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                    <td align="center">
                    <![endif]-->
                    <img src="{client.logo}" alt="Logo" width="{logo.size}" style="display: block; margin: 0 auto;" />
                    <!--[if mso]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
            <tr>
                <td bgcolor="#ffffff" style="padding: 10px 40px 30px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                        <tr>
                            <td style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 20px 0 30px 0;">
                                {mainText}
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <!--[if gte mso 9]>
                                <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:180px;height:48px;">
                                    <v:fill type="tile" color="{color.primary}" />
                                    <v:textbox inset="0,0,0,0">
                                <![endif]-->
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;">
                                    <tbody>
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center">
                                            <table>
                                                <tr>
                                                    <td bgcolor="{color.primary}" style="font-family:'Roboto',Helvetica,Arial,sans-serif; text-align:center; width:180px; max-width:200px; font-weight:normal; font-size:18px; line-height:24px; color:#fffffe; background-color: {color.primary};">
                                                        <a href="{user.action}" name="mybutton" style="display:block;padding:12px 24px;text-decoration:none;color:#fffffe" target="_blank">{button.text}</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <!--[if gte mso 9]>
                                    </v:textbox>
                                </v:rect>
                                <![endif]-->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </center>
</body>
</html>`
    },
    {
        name: 'Half Height Header Rounded',
        template: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <!--[if gte mso 15]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Email Template</title>
    
    <!--[if mso]>
    <style type="text/css">
        .outlook-button-fix {
            padding: 12px 36px !important;
            width: auto !important;
            height: auto !important;
        }
        table.logo-container {
            width: 100%;
        }
    </style>
    <![endif]-->
</head>
<body style="margin:0; padding:0; background-color:#F2F2F2;">
    <center>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: separate; border-spacing: 0; box-shadow: 0 4px 12px rgba(0,0,0,0.1);">
            <tr>
                <td bgcolor="{color.primary}" style="padding: 30px 0; text-align: center; border-radius: 16px 16px 0 0;">
                    <!--[if mso]>
                    <table class="logo-container" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                    <td align="center">
                    <![endif]-->
                    <img src="{client.logo}" alt="Logo" width="{logo.size}" style="display: block; margin: 0 auto;" />
                    <!--[if mso]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
            <tr>
                <td bgcolor="#ffffff" style="padding: 10px 40px 30px; border-radius: 0 0 16px 16px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                        <tr>
                            <td style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 20px 0 30px 0;">
                                {mainText}
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <!--[if gte mso 9]>
                                <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:180px;height:48px; arcsize=10%">
                                    <v:fill type="tile" color="{color.primary}" />
                                    <v:textbox inset="0,0,0,0">
                                <![endif]-->
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;">
                                    <tbody>
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center">
                                            <table>
                                                <tr>
                                                    <td bgcolor="{color.primary}" style="font-family:'Roboto',Helvetica,Arial,sans-serif; text-align:center; width:180px; max-width:200px; font-weight:normal; font-size:18px; line-height:24px; color:#fffffe; background-color: {color.primary}; border-radius: 4px;">
                                                        <a href="{user.action}" name="mybutton" style="display:block;padding:12px 24px;text-decoration:none;color:#fffffe" target="_blank">{button.text}</a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <!--[if gte mso 9]>
                                    </v:textbox>
                                </v:roundrect>
                                <![endif]-->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </center>
</body>
</html>`
    },
    {
        name: 'Default Template',
        template: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
\t<!-- NAME: 1 COLUMN -->
\t<!--[if gte mso 15]>
\t<xml>
\t\t<o:OfficeDocumentSettings>
\t\t\t<o:AllowPNG/>
\t\t\t<o:PixelsPerInch>96</o:PixelsPerInch>
\t\t</o:OfficeDocumentSettings>
\t</xml>
\t<![endif]-->
\t<meta charset="UTF-8">
\t<meta http-equiv="X-UA-Compatible" content="IE=edge">
\t<meta name="viewport" content="width=device-width, initial-scale=1">
\t<title>Cyber Awareness Courses from Boxphish</title>

\t<style type="text/css">
\t\tp{
\t\t\tmargin:10px 0;
\t\t\tpadding:0;
\t\t}
\t\ttable{
\t\t\tborder-collapse:collapse;
\t\t}
\t\th1,h2,h3,h4,h5,h6{
\t\t\tdisplay:block;
\t\t\tmargin:0;
\t\t\tpadding:0;
\t\t}
\t\timg,a img{
\t\t\tborder:0;
\t\t\theight:auto;
\t\t\toutline:none;
\t\t\ttext-decoration:none;
\t\t}
\t\tbody,#bodyTable,#bodyCell{
\t\t\theight:100%;
\t\t\tmargin:0;
\t\t\tpadding:0;
\t\t\twidth:100%;
\t\t}
\t\t.mcnPreviewText{
\t\t\tdisplay:none !important;
\t\t}
\t\t#outlook a{
\t\t\tpadding:0;
\t\t}
\t\timg{
\t\t\t-ms-interpolation-mode:bicubic;
\t\t}
\t\ttable{
\t\t\tmso-table-lspace:0pt;
\t\t\tmso-table-rspace:0pt;
\t\t}
\t\t.ReadMsgBody{
\t\t\twidth:100%;
\t\t}
\t\t.ExternalClass{
\t\t\twidth:100%;
\t\t}
\t\tp,a,li,td,blockquote{
\t\t\tmso-line-height-rule:exactly;
\t\t}
\t\ta[href^=tel],a[href^=sms]{
\t\t\tcolor:inherit;
\t\t\tcursor:default;
\t\t\ttext-decoration:none;
\t\t}
\t\tp,a,li,td,body,table,blockquote{
\t\t\t-ms-text-size-adjust:100%;
\t\t\t-webkit-text-size-adjust:100%;
\t\t}
\t\t.ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
\t\t\tline-height:100%;
\t\t}
\t\ta[x-apple-data-detectors]{
\t\t\tcolor:inherit !important;
\t\t\ttext-decoration:none !important;
\t\t\tfont-size:inherit !important;
\t\t\tfont-family:inherit !important;
\t\t\tfont-weight:inherit !important;
\t\t\tline-height:inherit !important;
\t\t}
\t\t#bodyCell{
\t\t\tpadding:10px;
\t\t}
\t\t.templateContainer{
\t\t\tmax-width:600px !important;
\t\t}
\t\ta.mcnButton{
\t\t\tdisplay:block;
\t\t}
\t\t.mcnImage,.mcnRetinaImage{
\t\t\tvertical-align:bottom;
\t\t}
\t\t.mcnTextContent{
\t\t\tword-break:break-word;
\t\t}
\t\t.mcnTextContent img{
\t\t\theight:auto !important;
\t\t}
\t\t.mcnDividerBlock{
\t\t\ttable-layout:fixed !important;
\t\t}
\t\t/*
        @tab Page
        @section Background Style
        @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
        */
\t\tbody,#bodyTable{
\t\t\t/*@editable*/background-color:#FAFAFA;
\t\t}
\t\t/*
        @tab Page
        @section Background Style
        @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
        */
\t\t#bodyCell{
\t\t\t/*@editable*/border-top:0;
\t\t}
\t\t/*
        @tab Page
        @section Email Border
        @tip Set the border for your email.
        */
\t\t.templateContainer{
\t\t\t/*@editable*/border:0;
\t\t}
\t\t/*
        @tab Page
        @section Heading 1
        @tip Set the styling for all first-level headings in your emails. These should be the largest of your headings.
        @style heading 1
        */
\t\th1{
\t\t\t/*@editable*/color:#202020;
\t\t\t/*@editable*/font-family:Helvetica;
\t\t\t/*@editable*/font-size:26px;
\t\t\t/*@editable*/font-style:normal;
\t\t\t/*@editable*/font-weight:bold;
\t\t\t/*@editable*/line-height:125%;
\t\t\t/*@editable*/letter-spacing:normal;
\t\t\t/*@editable*/text-align:left;
\t\t}
\t\t/*
        @tab Page
        @section Heading 2
        @tip Set the styling for all second-level headings in your emails.
        @style heading 2
        */
\t\th2{
\t\t\t/*@editable*/color:#202020;
\t\t\t/*@editable*/font-family:Helvetica;
\t\t\t/*@editable*/font-size:22px;
\t\t\t/*@editable*/font-style:normal;
\t\t\t/*@editable*/font-weight:bold;
\t\t\t/*@editable*/line-height:125%;
\t\t\t/*@editable*/letter-spacing:normal;
\t\t\t/*@editable*/text-align:left;
\t\t}
\t\t/*
        @tab Page
        @section Heading 3
        @tip Set the styling for all third-level headings in your emails.
        @style heading 3
        */
\t\th3{
\t\t\t/*@editable*/color:#202020;
\t\t\t/*@editable*/font-family:Helvetica;
\t\t\t/*@editable*/font-size:20px;
\t\t\t/*@editable*/font-style:normal;
\t\t\t/*@editable*/font-weight:bold;
\t\t\t/*@editable*/line-height:125%;
\t\t\t/*@editable*/letter-spacing:normal;
\t\t\t/*@editable*/text-align:left;
\t\t}
\t\t/*
        @tab Page
        @section Heading 4
        @tip Set the styling for all fourth-level headings in your emails. These should be the smallest of your headings.
        @style heading 4
        */
\t\th4{
\t\t\t/*@editable*/color:#202020;
\t\t\t/*@editable*/font-family:Helvetica;
\t\t\t/*@editable*/font-size:18px;
\t\t\t/*@editable*/font-style:normal;
\t\t\t/*@editable*/font-weight:bold;
\t\t\t/*@editable*/line-height:125%;
\t\t\t/*@editable*/letter-spacing:normal;
\t\t\t/*@editable*/text-align:left;
\t\t}
\t\t/*
        @tab Preheader
        @section Preheader Style
        @tip Set the background color and borders for your email's preheader area.
        */
\t\t#templatePreheader{
\t\t\t/*@editable*/background-color:#FAFAFA;
\t\t\t/*@editable*/background-image:none;
\t\t\t/*@editable*/background-repeat:no-repeat;
\t\t\t/*@editable*/background-position:center;
\t\t\t/*@editable*/background-size:cover;
\t\t\t/*@editable*/border-top:0;
\t\t\t/*@editable*/border-bottom:0;
\t\t\t/*@editable*/padding-top:9px;
\t\t\t/*@editable*/padding-bottom:9px;
\t\t}
\t\t/*
        @tab Preheader
        @section Preheader Text
        @tip Set the styling for your email's preheader text. Choose a size and color that is easy to read.
        */
\t\t#templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
\t\t\t/*@editable*/color:#656565;
\t\t\t/*@editable*/font-weight:normal;
\t\t\t/*@editable*/text-decoration:underline;
\t\t}
\t\t/*
        @tab Header
        @section Header Style
        @tip Set the background color and borders for your email's header area.
        */
\t\t#templateHeader{
\t\t\t/*@editable*/background-color:#FFFFFF;
\t\t\t/*@editable*/background-image:none;
\t\t\t/*@editable*/background-repeat:no-repeat;
\t\t\t/*@editable*/background-position:center;
\t\t\t/*@editable*/background-size:cover;
\t\t\t/*@editable*/border-top:0;
\t\t\t/*@editable*/border-bottom:0;
\t\t\t/*@editable*/padding-top:9px;
\t\t\t/*@editable*/padding-bottom:0;
\t\t}
\t\t/*
        @tab Header
        @section Header Text
        @tip Set the styling for your email's header text. Choose a size and color that is easy to read.
        */
\t\t#templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
\t\t\t/*@editable*/color:#202020;
\t\t\t/*@editable*/font-family:Helvetica;
\t\t\t/*@editable*/font-size:16px;
\t\t\t/*@editable*/line-height:150%;
\t\t\t/*@editable*/text-align:left;
\t\t}
\t\t/*
        @tab Header
        @section Header Link
        @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
        */
\t\t#templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
\t\t\t/*@editable*/color:#2BAADF;
\t\t\t/*@editable*/font-weight:normal;
\t\t\t/*@editable*/text-decoration:underline;
\t\t}
\t\t/*
        @tab Body
        @section Body Style
        @tip Set the background color and borders for your email's body area.
        */
\t\t#templateBody{
\t\t\t/*@editable*/background-color:#FFFFFF;
\t\t\t/*@editable*/background-image:none;
\t\t\t/*@editable*/background-repeat:no-repeat;
\t\t\t/*@editable*/background-position:center;
\t\t\t/*@editable*/background-size:cover;
\t\t\t/*@editable*/border-top:0;
\t\t\t/*@editable*/border-bottom:2px solid #EAEAEA;
\t\t\t/*@editable*/padding-top:0;
\t\t\t/*@editable*/padding-bottom:9px;
\t\t}
\t\t/*
        @tab Body
        @section Body Text
        @tip Set the styling for your email's body text. Choose a size and color that is easy to read.
        */
\t\t#templateBody .mcnTextContent,#templateBody .mcnTextContent p{
\t\t\t/*@editable*/color:#202020;
\t\t\t/*@editable*/font-family:Helvetica;
\t\t\t/*@editable*/font-size:16px;
\t\t\t/*@editable*/line-height:150%;
\t\t\t/*@editable*/text-align:left;
\t\t}
\t\t/*
        @tab Body
        @section Body Link
        @tip Set the styling for your email's body links. Choose a color that helps them stand out from your text.
        */
\t\t#templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
\t\t\t/*@editable*/color:#2BAADF;
\t\t\t/*@editable*/font-weight:normal;
\t\t\t/*@editable*/text-decoration:underline;
\t\t}
\t\t/*
        @tab Footer
        @section Footer Style
        @tip Set the background color and borders for your email's footer area.
        */
\t\t#templateFooter{
\t\t\t/*@editable*/background-color:#FAFAFA;
\t\t\t/*@editable*/background-image:none;
\t\t\t/*@editable*/background-repeat:no-repeat;
\t\t\t/*@editable*/background-position:center;
\t\t\t/*@editable*/background-size:cover;
\t\t\t/*@editable*/border-top:0;
\t\t\t/*@editable*/border-bottom:0;
\t\t\t/*@editable*/padding-top:9px;
\t\t\t/*@editable*/padding-bottom:9px;
\t\t}
\t\t/*
        @tab Footer
        @section Footer Text
        @tip Set the styling for your email's footer text. Choose a size and color that is easy to read.
        */
\t\t#templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
\t\t\t/*@editable*/color:#656565;
\t\t\t/*@editable*/font-family:Helvetica;
\t\t\t/*@editable*/font-size:12px;
\t\t\t/*@editable*/line-height:150%;
\t\t\t/*@editable*/text-align:center;
\t\t}
\t\t/*
        @tab Footer
        @section Footer Link
        @tip Set the styling for your email's footer links. Choose a color that helps them stand out from your text.
        */
\t\t#templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
\t\t\t/*@editable*/color:#656565;
\t\t\t/*@editable*/font-weight:normal;
\t\t\t/*@editable*/text-decoration:underline;
\t\t}
\t\t@media only screen and (min-width:768px){
\t\t\t.templateContainer{
\t\t\t\twidth:600px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\tbody,table,td,p,a,li,blockquote{
\t\t\t\t-webkit-text-size-adjust:none !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\tbody{
\t\t\t\twidth:100% !important;
\t\t\t\tmin-width:100% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t#bodyCell{
\t\t\t\tpadding-top:10px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnRetinaImage{
\t\t\t\tmax-width:100% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnImage{
\t\t\t\twidth:100% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
\t\t\t\tmax-width:100% !important;
\t\t\t\twidth:100% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnBoxedTextContentContainer{
\t\t\t\tmin-width:100% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnImageGroupContent{
\t\t\t\tpadding:9px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
\t\t\t\tpadding-top:9px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
\t\t\t\tpadding-top:18px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnImageCardBottomImageContent{
\t\t\t\tpadding-bottom:9px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnImageGroupBlockInner{
\t\t\t\tpadding-top:0 !important;
\t\t\t\tpadding-bottom:0 !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnImageGroupBlockOuter{
\t\t\t\tpadding-top:9px !important;
\t\t\t\tpadding-bottom:9px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnTextContent,.mcnBoxedTextContentColumn{
\t\t\t\tpadding-right:18px !important;
\t\t\t\tpadding-left:18px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
\t\t\t\tpadding-right:18px !important;
\t\t\t\tpadding-bottom:0 !important;
\t\t\t\tpadding-left:18px !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t.mcpreview-image-uploader{
\t\t\t\tdisplay:none !important;
\t\t\t\twidth:100% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Heading 1
            @tip Make the first-level headings larger in size for better readability on small screens.
            */
\t\t\th1{
\t\t\t\t/*@editable*/font-size:22px !important;
\t\t\t\t/*@editable*/line-height:125% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Heading 2
            @tip Make the second-level headings larger in size for better readability on small screens.
            */
\t\t\th2{
\t\t\t\t/*@editable*/font-size:20px !important;
\t\t\t\t/*@editable*/line-height:125% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Heading 3
            @tip Make the third-level headings larger in size for better readability on small screens.
            */
\t\t\th3{
\t\t\t\t/*@editable*/font-size:18px !important;
\t\t\t\t/*@editable*/line-height:125% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Heading 4
            @tip Make the fourth-level headings larger in size for better readability on small screens.
            */
\t\t\th4{
\t\t\t\t/*@editable*/font-size:16px !important;
\t\t\t\t/*@editable*/line-height:150% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Boxed Text
            @tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
            */
\t\t\t.mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
\t\t\t\t/*@editable*/font-size:14px !important;
\t\t\t\t/*@editable*/line-height:150% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Preheader Visibility
            @tip Set the visibility of the email's preheader on small screens. You can hide it to save space.
            */
\t\t\t#templatePreheader{
\t\t\t\t/*@editable*/display:block !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Preheader Text
            @tip Make the preheader text larger in size for better readability on small screens.
            */
\t\t\t#templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
\t\t\t\t/*@editable*/font-size:14px !important;
\t\t\t\t/*@editable*/line-height:150% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Header Text
            @tip Make the header text larger in size for better readability on small screens.
            */
\t\t\t#templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
\t\t\t\t/*@editable*/font-size:16px !important;
\t\t\t\t/*@editable*/line-height:150% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Body Text
            @tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
            */
\t\t\t#templateBody .mcnTextContent,#templateBody .mcnTextContent p{
\t\t\t\t/*@editable*/font-size:16px !important;
\t\t\t\t/*@editable*/line-height:150% !important;
\t\t\t}

\t\t}\t@media only screen and (max-width: 480px){
\t\t\t/*
            @tab Mobile Styles
            @section Footer Text
            @tip Make the footer content text larger in size for better readability on small screens.
            */
\t\t\t#templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
\t\t\t\t/*@editable*/font-size:14px !important;
\t\t\t\t/*@editable*/line-height:150% !important;
\t\t\t}

\t\t}</style>
</head>
<body>
<center>
\t<table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
\t\t<tr>
\t\t\t<td align="center" valign="top" id="bodyCell">
\t\t\t\t<table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer">
\t\t\t\t\t<tr>
\t\t\t\t\t\t<td valign="top" id="templatePreheader" style="background-color: #ffffff; padding-top:0">
\t\t\t\t\t\t\t<table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
\t\t\t\t\t\t\t\t<tbody class="mcnImageBlockOuter">
\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t<td valign="top" class="mcnImageBlockInner">
\t\t\t\t\t\t\t\t\t\t<table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
\t\t\t\t\t\t\t\t\t\t\t<tbody>
\t\t\t\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t\t\t\t<td style="height: 20px; width: 100%; border: 0; background-color: #0f293c;"></td>
\t\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t\t\t\t<td style="height: 40px; border: 0"></td>
\t\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t\t\t\t<td class="mcnImageContent" colspan="2" width="250" style="text-align: center; padding-bottom: 0; margin-top:5px; width: 300px; valign:top">
\t\t\t\t\t\t\t\t\t\t\t\t\t\t<img width="250" alt="" src='{client.logo}' style="max-width:200px; width: 200px;  padding-bottom: 0; display: block; margin-left: auto; margin-right: auto; vertical-align: center;" class="mcnImage">
\t\t\t\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t\t</tbody>
\t\t\t\t\t\t\t\t\t\t</table>
\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t</tbody>
\t\t\t\t\t\t\t</table>
\t\t\t\t\t\t</td>
\t\t\t\t\t</tr>
\t\t\t\t\t<tr>
\t\t\t\t\t\t<td valign="top" id="templateHeader">
\t\t\t\t\t\t\t<table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
\t\t\t\t\t\t\t\t<tbody class="mcnTextBlockOuter">
\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t<td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">\t\t\t\t\t\t\t\t\t<!--[if mso]>
\t\t\t\t\t\t\t\t\t\t<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
\t\t\t\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t\t<![endif]-->

\t\t\t\t\t\t\t\t\t\t<!--[if mso]>
\t\t\t\t\t\t\t\t\t\t<td valign="top" width="600" style="width:600px;">
\t\t\t\t\t\t\t\t\t\t<![endif]-->
\t\t\t\t\t\t\t\t\t\t<table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
\t\t\t\t\t\t\t\t\t\t\t<tbody>
\t\t\t\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t\t\t\t<td width="100px" style="max-width: 100px"></td>
\t\t\t\t\t\t\t\t\t\t\t\t<td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px; line-height: 200%">
\t\t\t\t\t\t\t\t\t\t\t\t\t<p style="text-align: center; font-size: 18px">{mainText}</p>
\t\t\t\t\t\t\t\t\t\t\t\t\t<p style="text-align: center; font-size: 18px">Click <span style="color: #419f79">{button.text}</span> below to get started</p>
\t\t\t\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t\t\t\t\t<td width="100px" style="max-width: 100px"></td>
\t\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t\t</tbody>
\t\t\t\t\t\t\t\t\t\t</table>

\t\t\t\t\t\t\t\t\t\t<!--[if mso]>
\t\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t\t\t<![endif]-->

\t\t\t\t\t\t\t\t\t\t<!--[if mso]>
\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t</table>
\t\t\t\t\t\t\t\t\t\t<![endif]-->
\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t</tbody>
\t\t\t\t\t\t\t</table></td>
\t\t\t\t\t</tr>
\t\t\t\t\t<tr>
\t\t\t\t\t\t<td valign="top" id="templateBody" style="border:0">
\t\t\t\t\t\t\t<table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;">
\t\t\t\t\t\t\t\t<tbody>
\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t<td valign="top" style="padding-top:9px;">
\t\t\t\t\t\t\t\t\t\t<!--[if mso]>
\t\t\t\t\t\t\t\t\t\t<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
\t\t\t\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t\t<![endif]-->

\t\t\t\t\t\t\t\t\t\t<!--[if mso]>
\t\t\t\t\t\t\t\t\t\t<td valign="top" width="600" style="width:600px;">
\t\t\t\t\t\t\t\t\t\t<![endif]-->
\t\t\t\t\t\t\t\t\t\t<table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;">
\t\t\t\t\t\t\t\t\t\t\t<tbody>
\t\t\t\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t\t\t\t<td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center">

\t\t\t\t\t\t\t\t\t\t\t\t\t<table>
\t\t\t\t\t\t\t\t\t\t\t\t\t\t<tr>
\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<td bgcolor="{color.primary}" style="font-family:'Roboto',Helvetica,Arial,sans-serif; text-align:center; width:180px; max-width:200px; border:1px solid #0f293c; border-radius:4px; font-weight:normal; font-size:18px; line-height:24px; color:#fffffe;}">
\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<a href="{user.action}" name="mybutton" style="display:block;padding:12px 24px;text-decoration:none;color:#fffffe" target="_blank">{button.text}</a>
\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t\t\t\t</table>
\t\t\t\t\t\t\t\t\t\t\t\t\t
\t\t\t\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t\t</tbody>
\t\t\t\t\t\t\t\t\t\t</table>
\t\t\t\t\t\t\t\t\t\t<table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
\t\t\t\t\t\t\t\t\t\t\t<tbody><tr>

\t\t\t\t\t\t\t\t\t\t\t\t<td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">



\t\t\t\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t\t</tbody>
\t\t\t\t\t\t\t\t\t\t</table>

\t\t\t\t\t\t\t\t\t\t<!--[if mso]>
\t\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t\t\t<![endif]-->

\t\t\t\t\t\t\t\t\t\t<!--[if mso]>
\t\t\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t\t\t</table>
\t\t\t\t\t\t\t\t\t\t<![endif]-->
\t\t\t\t\t\t\t\t\t</td>
\t\t\t\t\t\t\t\t</tr>
\t\t\t\t\t\t\t\t</tbody>
\t\t\t\t\t\t\t</table>



\t\t\t\t\t\t</td>
\t\t\t\t\t</tr>
\t\t\t\t</table>
\t\t\t\t<!--[if (gte mso 9)|(IE)]>
\t\t\t\t</td>
\t\t\t\t</tr>
\t\t\t\t</table>
\t\t\t\t<![endif]-->
\t\t\t\t<!-- // END TEMPLATE -->
\t\t\t</td>
\t\t</tr>
\t</table>
</center>
</body>
</html>
`
    }
]