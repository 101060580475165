import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Autocomplete, Button, IconButton, Modal, Snackbar, TextField, Box, useMediaQuery, Typography, CircularProgress } from "@mui/material";
import ContentPackPreview from "./ContentPackPreview";
import ContentPackDetails from "./ContentPackDetails";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import PropTypes from "prop-types";
import ConfirmationDialog from "../../utils/confirmationDialog";
import UsersTable from "../users/UsersTable";

import { useGetContentPacksQuery, useDeleteContentPackMutation } from '../../store/services/university-service';

import Table from "../../components/Table/Table";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import { useTheme } from "@mui/material/styles";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { getCardColor } from "../../utils/colorUtils";
import { ModalProvider } from '../../components/Modal/ModalContext';


const userModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    boxShadow: 24,
    borderRadius: 4,
    bgcolor: "background.paper"
};

ContentPackTable.propTypes = {
    type: PropTypes.oneOf(["custom", "preset"]).isRequired
}

export default function ContentPackTable(props) {
    const { type } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { tenancyId } = useSelector((state) => state.tenancy);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });
    
    const { data: contentPacksCustom, isLoading: contentPacksCustomLoading, error: contentPacksCustomError } = useGetContentPacksQuery({
        perPage: paginationModel.pageSize,
        page: paginationModel.page,
        searchTerm: '', 
        tenancyId: tenancyId 
    });

    const { data: contentPacksPreset, isLoading: contentPacksPresetLoading, error: contentPacksPresetError } = useGetContentPacksQuery({
        perPage: paginationModel.pageSize,
        page: paginationModel.page,
        searchTerm: '', 
        tenancyId: 0 
    });

    const [coursePreviewOpen, setCoursePreviewOpen] = useState(false);
    const contentPackData = [...(contentPacksCustom || []), ...(contentPacksPreset || [])];
    const [deleteContentPack] = useDeleteContentPackMutation();
    const [selectedContentPack, setSelectedContentPack] = useState(null);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [filteredContentPacks, setFilteredContentPacks] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isTenancyAdmin, setIsTenancyAdmin] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [deletingId, setDeletingId] = useState(null);

    // Confirmation Dialog
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContent, setDialogContent] = useState("");
    const [dialogAction, setDialogAction] = useState(null);


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
        setIsTenancyAdmin(user && user.role.includes("TenancyAdministrators"));
        setIsUser(user && user.role === "");
    }, []);

    const handlePreview = (row) => {
        setSelectedContentPack(row);
        setPreviewModalOpen(true);
    };

    const handleClosePreviewModal = () => {
        if (!coursePreviewOpen) {
            setPreviewModalOpen(false);
        }
    };

    const handleDeleteContentPack = async (id) => {
        setDialogTitle("Delete Content Pack");
        setDialogContent("Are you sure you want to delete the selected content pack? This action is irreversible.");
        setDialogAction(() => async () => {
            try {
                setDeletingId(id); 
                const response = await deleteContentPack(id).unwrap();
                setSnackbarSeverity("success");
                setSnackbarMessage("Content pack deleted successfully!");
                setSnackbarOpen(true);
            } catch (error) {
                console.error('Error deleting content pack:', error);
                setSnackbarSeverity("error");
                setSnackbarMessage("Failed to delete content pack");
                setSnackbarOpen(true);
            } finally {
                setDeletingId(null); 
            }
        });
        setDialogOpen(true);
    };

    const handleEditContentPack = (contentPack) => {
        navigate(`/packs/edit/${contentPack.internalId}`);
    };

    const handleAddContentPack = (isPreset = false) => {
        if (isPreset && !isAdmin) {
            setSnackbarSeverity("error");
            setSnackbarMessage("Only administrators can create preset content packs");
            setSnackbarOpen(true);
            return;
        }
        navigate(`/packs/add${isPreset ? '?preset=true' : ''}`);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    
    const handleSelectContentPack = (contentPackId) => {
        setSelected(contentPackId);
    };

    const handleOpenUserModal = (contentPackId) => {
        setSelectedUserId(contentPackId);
        setUserModalOpen(true);
    };

    const handleCloseUserModal = () => {
        setUserModalOpen(false);
    };

    const toolbarActions = [
        {
            render: () => (
                <Box width={"100%"} justifyContent={"flex-end"} display={"flex"} gap={2}>
                    <Button
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: "capitalize", whiteSpace: "nowrap", ml: 2 }}
                        onClick={() => handleAddContentPack(false)}
                        endIcon={<AddIcon />}
                    >
                        Create Custom Pack
                    </Button>
                    {isAdmin && (
                        <Button
                            size="medium"
                            variant="contained"
                            sx={{ textTransform: "capitalize", whiteSpace: "nowrap", }}
                            onClick={() => handleAddContentPack(true)}
                            endIcon={<AddIcon />}
                        >
                            Create Preset Pack
                        </Button>
                    )}
                </Box>
            )
        }
    ];

    let headCells = [
        {
            id: "preview",
            label: "",
            render: (dataRow) => (
                <>
                    <Button onClick={() => handlePreview(dataRow)} size="small" variant="contained" color="warning">
                        Preview
                    </Button>
                </>
            ),
            initialVisible: true
        },
        {
            id: "edit",
            label: "",
            render: (dataRow) => {
                if (dataRow.tenancyId === 0 && !isAdmin) return <></>;

                return (
                    <Button
                        onClick={() => handleEditContentPack(dataRow)}
                        size="small"
                        variant="contained"
                        color="secondary"
                    >
                        Edit
                    </Button>
                );
            },
            initialVisible: true
        },
        {
            id: "send-user",
            label: "",
            render: (dataRow) => (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenUserModal(dataRow.internalId)}
                    sx={{ whiteSpace: "nowrap" }}
                >
                    Send to User
                </Button>
            ),
            initialVisible: true
        },
        {
            id: "type",
            label: "Type",
            render: (dataRow) => dataRow.tenancyId === 0 ? "Preset" : "Custom",
            initialVisible: true
        },
        {
            id: "contentPackName",
            label: "Content Pack Name",
            initialVisible: true
        },
        {
            id: "dateCreated",
            label: "Date Created",
            initialVisible: true,
            render: (dataRow) => {
                const date = new Date(dataRow.dateCreated);
                return date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }
        },
        {
            id: "dateUpdated",
            label: "Date Updated",
            initialVisible: true,
            render: (dataRow) => {
                const date = new Date(dataRow.dateUpdated);
                return date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }
        },
        {
            id: "action",
            label: "",
            render: (dataRow) => {
                if (dataRow.tenancyId === 0 && !isAdmin) return <></>;

                return ( 
                    <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteContentPack(dataRow.internalId)}
                        disabled={deletingId === dataRow.internalId}
                        sx={{
                            minWidth: '70px', 
                            width: '70px',    
                            height: '32px',   
                            padding: '6px 8px', 
                            '& .MuiCircularProgress-root': {
                                marginRight: 0 
                            }
                        }}
                    >
                        {deletingId === dataRow.internalId ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            "Delete"
                        )}
                    </Button>
                );
            },
            initialVisible: true
        }

    ];
    return (
        <>
            <Table
                name="Content Packs"
                data={filteredContentPacks.length > 0 ? filteredContentPacks : contentPackData}
                error={contentPacksCustomError || contentPacksPresetError}
                loading={contentPacksCustomLoading || contentPacksPresetLoading}
                headCells={headCells}
                search={true}
                editColumn={false}
                checkbox={false}
                toolbarActions={toolbarActions}
                showToolbarActions={true}
                selected={selected}
                setSelected={setSelected}
                maxTableHeight="calc(100vh - 250px)"
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled"
                    sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <ModalProvider>
                <Modal
                    open={previewModalOpen}
                    onClose={handleClosePreviewModal}
                    aria-labelledby="simulation-preview-title"
                    aria-describedby="simulation-preview-description"
                    sx={{
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }
                    }}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '8px',
                        width: '600px',
                        height: '90vh',
                        p: 2,
                        bgcolor: theme.palette.background.default,
                        boxShadow: 24,
                        maxHeight: '90vh',
                        overflow: 'hidden',
                        visibility: coursePreviewOpen ? 'hidden' : 'visible',
                    }}>
                        {previewModalOpen && (
                            <ContentPackPreview
                                contentPackId={selectedContentPack?.internalId}
                                setCoursePreviewOpen={setCoursePreviewOpen}
                            />
                        )}
                    </Box>
                </Modal>
            </ModalProvider>
            <Modal
                open={userModalOpen}
                onClose={handleCloseUserModal}
                aria-labelledby="user-selection-modal"
                aria-describedby="user-selection-description"
            >
                <Box sx={userModalStyle}>
                    <UsersTable
                        type={"send"}
                        userId={selectedUserId}
                        setUserId={setSelectedUserId}
                        formatId={selectedUserId}
                        format={"contentPack"}
                        CSVButton
                    />
                </Box>
            </Modal>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContent}
                onConfirm={() => {
                    setDialogOpen(false);
                    dialogAction();
                }}
                onCancel={() => setDialogOpen(false)}
            />
        </>
    );
    
}
