import React, { useCallback, useEffect, useState } from "react";
import {
    Box, Button, CircularProgress, Grid, Typography
} from "@mui/material";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import MainCard from "../../components/cards/MainCard";
import { getCardColor } from "../../utils/colorUtils";
import {
    useGetTenancyBasicsQuery,
    useGetTenancyByIdQuery,
    useUpdateTenancyMutation
} from "../../store/services/tenancy.service";
import { useUploadLandingPageMutation } from "../../store/services/simulation-service";
import { PRESET_LANDING_PAGE } from "../../themes/PresetLandingPage";
import {
    useSaveEmailSimulationSettingsMutation,
    useGetEmailSimulationSettingsQuery,
} from "../../store/services/simulation-service";

// Import sub-components
import LandingPagePreview from "../../components/LandingPage/LandingPagePreview";
import StyleEditor from "../../components/LandingPage/StyleEditor";
import ContentEditor from "../../components/LandingPage/ContentEditor";
import processTemplate from '../../components/LandingPage/ProcessTemplate';
import extractValuesFromHTML from '../../components/LandingPage/extractValuesFromHTML';

/**
 * Helper function to extract HTML from a template string
 */
const extractHtmlFromTemplate = (template) => {
    if (!template) return '';

    const htmlStartIndex = template.indexOf('<!DOCTYPE html>');
    const htmlEndIndex = template.lastIndexOf('</html>') + 7;

    if (htmlStartIndex !== -1 && htmlEndIndex !== -1) {
        return template.substring(htmlStartIndex, htmlEndIndex);
    }

    return template;
};

/**
 * Main component for creating and editing custom landing pages
 */
function CustomLandingPage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const { tenancyId } = useSelector((state) => state.tenancy);

    // State
    const [isSaving, setIsSaving] = useState(false);
    const [showStyleEditor, setShowStyleEditor] = useState(false);
    const [templateHTML, setTemplateHTML] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [showLogoBackground, setShowLogoBackground] = useState(true);
    const [brandColors, setBrandColors] = useState({
        primary: theme.palette.primary.main,
    });
    const [logoSize, setLogoSize] = useState(120);
    const [showLogo, setShowLogo] = useState(true);
    const [formData, setFormData] = useState({
        displayName: "",
        buttonText: "",
        mainContent: "",
        templateHtml: "",
        backgroundImage: "",
        isBackgroundImageLocal: false,
        headerTitle: "",
        tipsHeading: "",
        tip1Title: "",
        tip1Content: "",
        tip2Title: "",
        tip2Content: "",
        tip3Title: "",
        tip3Content: "",
        tip4Title: "",
        tip4Content: "",
        tip5Title: "",
        tip5Content: "",
        tip6Title: "",
        tip6Content: ""
    });

    // Queries and Mutations
    const [updateTenancy] = useUpdateTenancyMutation();
    const [uploadLandingPage] = useUploadLandingPageMutation();
    const { data: tenancyBasics, isLoading: tenancyBasicsLoading } =
        useGetTenancyBasicsQuery({ tenancyId });
    const { data: tenancyData, isLoading: tenancyLoading } =
        useGetTenancyByIdQuery({ tenancyId });

    const {
        data: tenancySettings,
        isLoading: isLoadingSettings
    } = useGetEmailSimulationSettingsQuery({ tenancyId });
    const [saveEmailSimulationSettings] = useSaveEmailSimulationSettingsMutation();

    useEffect(() => {
        const initializeTemplate = async () => {
            try {
                // Always use the original preset template for editing
                setTemplateHTML(PRESET_LANDING_PAGE);

                if (!isLoadingSettings && tenancySettings) {
                    console.log("Found settings in API:", tenancySettings);


                    if (tenancySettings.landingPageLogoSize !== undefined) {
                        setLogoSize(tenancySettings.landingPageLogoSize);
                    }

                    if (tenancySettings.landingPageShowLogo !== undefined) {
                        setShowLogo(tenancySettings.landingPageShowLogo);
                    }


                    if (tenancySettings.landingPageShowLogoBackground !== undefined) {
                        setShowLogoBackground(tenancySettings.landingPageShowLogoBackground);
                    }

                    if (tenancySettings.primary) {
                        setBrandColors(prev => ({
                            ...prev,
                            primary: tenancySettings.primary
                        }));
                    }

                    // Then update form data
                    setFormData(prev => ({
                        ...prev,
                        displayName: tenancyData?.name || prev.displayName,
                        headerTitle: tenancySettings.landingPageHeaderTitle ?? '',
                        mainContent: tenancySettings.landingPageMainContent ?? '',
                        tipsHeading: tenancySettings.landingPageTipsHeading ?? '',
                        backgroundImage: tenancySettings.landingPageBackgroundImage ?? '',
                        buttonText: tenancySettings.landingPageButtonText ?? '',
                        buttonLink: tenancySettings.landingPageButtonLink ?? '',
                        pageTitle: tenancySettings.landingPageTitle ?? '',
                        tip1Title: tenancySettings.landingPageTip1Title ?? '',
                        tip1Content: tenancySettings.landingPageTip1Content ?? '',
                        tip2Title: tenancySettings.landingPageTip2Title ?? '',
                        tip2Content: tenancySettings.landingPageTip2Content ?? '',
                        tip3Title: tenancySettings.landingPageTip3Title ?? '',
                        tip3Content: tenancySettings.landingPageTip3Content ?? '',
                        tip4Title: tenancySettings.landingPageTip4Title ?? '',
                        tip4Content: tenancySettings.landingPageTip4Content ?? '',
                        tip5Title: tenancySettings.landingPageTip5Title ?? '',
                        tip5Content: tenancySettings.landingPageTip5Content ?? '',
                        tip6Title: tenancySettings.landingPageTip6Title ?? '',
                        tip6Content: tenancySettings.landingPageTip6Content ?? ''
                    }));

                    console.log("Successfully applied settings from API");
                }
                // Check if we have stored form data
                else if (tenancyData?.landingPageFormData) {
                    console.log("Found stored form data, parsing...");
                    try {
                        const parsedFormData = JSON.parse(tenancyData.landingPageFormData);

                        // Update form data with stored values
                        setFormData(prev => ({
                            ...prev,
                            displayName: parsedFormData.displayName || tenancyData.name || prev.displayName,
                            buttonText: parsedFormData.buttonText || prev.buttonText,
                            buttonLink: parsedFormData.buttonLink || prev.buttonLink,
                            headerTitle: parsedFormData.headerTitle || prev.headerTitle,
                            tipsHeading: parsedFormData.tipsHeading || prev.tipsHeading,
                            mainContent: parsedFormData.mainContent || prev.mainContent,
                            pageTitle: parsedFormData.pageTitle || prev.pageTitle,
                            backgroundImage: parsedFormData.backgroundImage || prev.backgroundImage,
                            tip1Title: parsedFormData.tip1Title || prev.tip1Title,
                            tip1Content: parsedFormData.tip1Content || prev.tip1Content,
                            tip2Title: parsedFormData.tip2Title || prev.tip2Title,
                            tip2Content: parsedFormData.tip2Content || prev.tip2Content,
                            tip3Title: parsedFormData.tip3Title || prev.tip3Title,
                            tip3Content: parsedFormData.tip3Content || prev.tip3Content,
                            tip4Title: parsedFormData.tip4Title || prev.tip4Title,
                            tip4Content: parsedFormData.tip4Content || prev.tip4Content,
                            tip5Title: parsedFormData.tip5Title || prev.tip5Title,
                            tip5Content: parsedFormData.tip5Content || prev.tip5Content,
                            tip6Title: parsedFormData.tip6Title || prev.tip6Title,
                            tip6Content: parsedFormData.tip6Content || prev.tip6Content
                        }));

                        // Set style settings
                        if (parsedFormData.brandColors) {
                            setBrandColors(parsedFormData.brandColors);
                        }

                        if (parsedFormData.logoSize) {
                            setLogoSize(parsedFormData.logoSize);
                        }

                        if (parsedFormData.showLogo !== undefined) {
                            setShowLogo(parsedFormData.showLogo);
                        }

                        if (parsedFormData.showLogoBackground !== undefined) {
                            setShowLogoBackground(parsedFormData.showLogoBackground);
                        }

                        console.log("Successfully applied stored form data");
                    } catch (error) {
                        console.error("Error parsing stored form data:", error);
                        // Fall back to extraction method
                    }
                }
                // Only if there's no API settings and no LandingPageFormData, try to extract from HTML
                else if (tenancyData?.landingPageUrl) {
                    const url = tenancyData.landingPageUrl;
                    console.log("No stored form data, fetching from URL:", url);

                    // Fetch the HTML content from the S3 URL
                    const response = await axios.get(url, {
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        }
                    });

                    const htmlContent = response.data;
                    console.log("Fetched HTML content, extracting values...");

                    // Extract values from the processed HTML
                    const extractedValues = extractValuesFromHTML(htmlContent);
                    console.log("Extracted values:", extractedValues);

                    // Update form data with extracted values
                    setFormData(prev => ({
                        ...prev,
                        headerTitle: extractedValues.headerTitle || prev.headerTitle,
                        mainContent: extractedValues.mainContent || prev.mainContent,
                        tipsHeading: extractedValues.tipsHeading || prev.tipsHeading,
                        tip1Title: extractedValues.tip1Title || prev.tip1Title,
                        tip1Content: extractedValues.tip1Content || prev.tip1Content,
                        tip2Title: extractedValues.tip2Title || prev.tip2Title,
                        tip2Content: extractedValues.tip2Content || prev.tip2Content,
                        tip3Title: extractedValues.tip3Title || prev.tip3Title,
                        tip3Content: extractedValues.tip3Content || prev.tip3Content,
                        tip4Title: extractedValues.tip4Title || prev.tip4Title,
                        tip4Content: extractedValues.tip4Content || prev.tip4Content,
                        tip5Title: extractedValues.tip5Title || prev.tip5Title,
                        tip5Content: extractedValues.tip5Content || prev.tip5Content,
                        tip6Title: extractedValues.tip6Title || prev.tip6Title,
                        tip6Content: extractedValues.tip6Content || prev.tip6Content,
                        backgroundImage: extractedValues.backgroundImage || prev.backgroundImage,
                        displayName: tenancyData.name || prev.displayName
                    }));

                    // Update style settings
                    if (extractedValues.brandColors?.primary) {
                        setBrandColors(prev => ({
                            ...prev,
                            primary: extractedValues.brandColors.primary
                        }));
                    }

                    if (extractedValues.logoSize) {
                        setLogoSize(extractedValues.logoSize);
                    }

                    if (extractedValues.showLogo !== undefined) {
                        setShowLogo(extractedValues.showLogo);
                    }
                } else {
                    // First time setup - just use the preset template with defaults
                    console.log("No existing landing page, using preset template");
                    setFormData(prev => ({
                        ...prev,
                        displayName: tenancyData?.name || prev.displayName
                    }));
                }
            } catch (error) {
                console.error("Error initializing template:", error);
                // Fall back to preset template
                setTemplateHTML(PRESET_LANDING_PAGE);
            }
        };

        if (!tenancyLoading && tenancyData) {
            initializeTemplate();
        }
    }, [tenancyData, tenancyLoading, PRESET_LANDING_PAGE, tenancySettings, isLoadingSettings]);

    // Load saved settings from localStorage
    useEffect(() => {
        const savedColors = localStorage.getItem("brandColors");
        if (savedColors) {
            setBrandColors(JSON.parse(savedColors));
        }

        const savedLogoSize = localStorage.getItem("logoSize");
        if (savedLogoSize) {
            setLogoSize(parseInt(savedLogoSize));
        }

        const savedShowLogo = localStorage.getItem("showLogo");
        if (savedShowLogo !== null) {
            setShowLogo(savedShowLogo === "true");
        }
    }, []);

    // Clean up local image URLs when component unmounts
    useEffect(() => {
        return () => {
            if (formData.isBackgroundImageLocal && formData.backgroundImage) {
                console.log("Cleaning up local image URL on unmount");
                URL.revokeObjectURL(formData.backgroundImage);
            }
        };
    }, [formData.isBackgroundImageLocal, formData.backgroundImage]);

    // Update when tenancy data loads
    useEffect(() => {
        if (tenancyData) {
            // If the tenancy has custom brand colors, use those
            if (tenancyData.brandColors) {
                setBrandColors(tenancyData.brandColors);
            }

            // Update form data with tenancy info if needed
            setFormData(prev => ({
                ...prev,
                displayName: tenancyData.name || prev.displayName
            }));
        }
    }, [tenancyData]);

    useEffect(() => {
        const savedShowLogoBackground = localStorage.getItem("showLogoBackground");
        if (savedShowLogoBackground !== null) {
            setShowLogoBackground(savedShowLogoBackground === "true");
        }
    }, []);

    // Handlers
    const handleInputChange = (field) => (event) => {
        const newValue = event.target.value;
        setFormData((prev) => ({
            ...prev,
            [field]: newValue,
        }));
    };

    const handleColorChange = useCallback((colors) => {
        setBrandColors(colors);
        localStorage.setItem("brandColors", JSON.stringify(colors));
    }, []);

    const handleLogoSizeChange = useCallback((size) => {
        setLogoSize(size);
        localStorage.setItem("logoSize", size.toString());
    }, []);

    const handleLogoVisibilityChange = useCallback((visible) => {
        setShowLogo(visible);
        localStorage.setItem("showLogo", visible.toString());
    }, []);

    const handleLogoBackgroundChange = useCallback((visible) => {
        setShowLogoBackground(visible);
        localStorage.setItem("showLogoBackground", visible.toString());
    }, []);

    const handleTemplateChange = useCallback((template) => {
        try {
            let htmlContent = template;
            if (template.includes('export const') || template.includes('<!DOCTYPE html>')) {
                htmlContent = extractHtmlFromTemplate(template);
            }

            const cleanedTemplate = htmlContent.trim();
            setTemplateHTML(cleanedTemplate);
            setFormData(prev => ({
                ...prev,
                templateHtml: cleanedTemplate
            }));
        } catch (error) {
            console.error("Error processing template:", error);
        }
    }, []);

    const handleSave = async () => {
        setIsSaving(true);
        console.log("Starting save operation");

        if (formData.isBackgroundImageLocal && formData.backgroundImage) {
            setSnackbarMessage("Please wait for background image to finish uploading");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            setIsSaving(false);
            return;
        }

        try {
            // 1. Save to EmailSimulationSettings API
            // Log the data we're about to save for debugging
            console.log("Saving the following data to API:", {
                tenancyId: tenancyId,
                landingPageHeaderTitle: formData.headerTitle,
                landingPageMainContent: formData.mainContent,
                landingPageTipsHeading: formData.tipsHeading,
                landingPageBackgroundImage: formData.backgroundImage,
                landingPageLogoSize: logoSize,
                landingPageShowLogo: showLogo,
                landingPageShowLogoBackground: showLogoBackground,
                landingPageTip1Title: formData.tip1Title,
                landingPageTip1Content: formData.tip1Content,
                landingPageTip2Title: formData.tip2Title,
                landingPageTip2Content: formData.tip2Content,
                landingPageTip3Title: formData.tip3Title,
                landingPageTip3Content: formData.tip3Content,
                landingPageTip4Title: formData.tip4Title,
                landingPageTip4Content: formData.tip4Content,
                landingPageTip5Title: formData.tip5Title,
                landingPageTip5Content: formData.tip5Content,
                landingPageTip6Title: formData.tip6Title,
                landingPageTip6Content: formData.tip6Content,
                landingPageButtonText: formData.buttonText,
                landingPageButtonLink: formData.buttonLink,
                landingPageTitle: formData.pageTitle,
                primary: brandColors.primary
            });

            // Save settings to API
            const settingsResult = await saveEmailSimulationSettings({
                tenancyId: tenancyId,
                settingsData: {
                    tenancyId: tenancyId,
                    landingPageHeaderTitle: formData.headerTitle,
                    landingPageMainContent: formData.mainContent,
                    landingPageTipsHeading: formData.tipsHeading,
                    landingPageBackgroundImage: formData.backgroundImage,
                    landingPageLogoSize: logoSize,
                    landingPageShowLogo: showLogo,
                    landingPageShowLogoBackground: showLogoBackground,
                    landingPageTip1Title: formData.tip1Title,
                    landingPageTip1Content: formData.tip1Content,
                    landingPageTip2Title: formData.tip2Title,
                    landingPageTip2Content: formData.tip2Content,
                    landingPageTip3Title: formData.tip3Title,
                    landingPageTip3Content: formData.tip3Content,
                    landingPageTip4Title: formData.tip4Title,
                    landingPageTip4Content: formData.tip4Content,
                    landingPageTip5Title: formData.tip5Title,
                    landingPageTip5Content: formData.tip5Content,
                    landingPageTip6Title: formData.tip6Title,
                    landingPageTip6Content: formData.tip6Content,
                    landingPageButtonText: formData.buttonText,
                    landingPageButtonLink: formData.buttonLink,
                    landingPageTitle: formData.pageTitle,
                    primary: brandColors.primary
                }
            }).unwrap();

            console.log("Settings API response:", settingsResult);

            // 2. Process the template with all current values
            let processedTemplate = processTemplate(templateHTML, {

                colors: brandColors,
                logoSize,
                logo: tenancyBasics?.logo,
                showLogo,
                showLogoBackground,
                companyName: tenancyData?.name || formData.displayName,
                displayName: tenancyData?.name || formData.displayName,
                buttonText: formData.buttonText,
                buttonLink: formData.buttonLink,
                headerTitle: formData.headerTitle,
                tipsHeading: formData.tipsHeading,
                mainContent: formData.mainContent,
                pageTitle: formData.pageTitle,
                backgroundImage: formData.backgroundImage,
                tip1Title: formData.tip1Title,
                tip1Content: formData.tip1Content,
                tip2Title: formData.tip2Title,
                tip2Content: formData.tip2Content,
                tip3Title: formData.tip3Title,
                tip3Content: formData.tip3Content,
                tip4Title: formData.tip4Title,
                tip4Content: formData.tip4Content,
                tip5Title: formData.tip5Title,
                tip5Content: formData.tip5Content,
                tip6Title: formData.tip6Title,
                tip6Content: formData.tip6Content,
                useDefaultsForEmptyStrings: true
            });

            // SANITIZE THE HTML - Fix problematic patterns
            // 1. Fix CSS selectors with # characters
            processedTemplate = processedTemplate.replace(
                /\.svg-filter-#([0-9a-fA-F]{6})/g,
                '.svg-filter-color-$1'
            );

            // 2. Remove HTML comments
            processedTemplate = processedTemplate.replace(/<!--[\s\S]*?-->/g, '');

            // 3. Remove control characters
            processedTemplate = processedTemplate.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');

            console.log("Processed and sanitized template length:", processedTemplate.length);

            console.log('Processed template before S3 upload:', processedTemplate);
            // Get the tenancy name for the S3 path
            const tenancyName = tenancyData?.name || formData.displayName;

            // Upload to S3 using RTK Query mutation
            const uploadResult = await uploadLandingPage({
                html: processedTemplate,
                tenancyName: tenancyName
            }).unwrap();

            if (!uploadResult.success) {
                throw new Error('Failed to upload landing page to S3');
            }

            // Get the URL of the uploaded landing page
            const landingPageUrl = uploadResult.url;

            // Update the tenancy in DynamoDB with the iframe HTML that uses the S3 URL
            const currentTenancyData = {...tenancyData};

            // Create iframe HTML that uses the landing page URL
            const iframeHtml = `<p><iframe style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; border: none; margin: 0; padding: 0; overflow: hidden; z-index: 999999; background-color: white;" src="${landingPageUrl}">
            Your browser doesn't support iframes</iframe></p>`;

            // Instead of storing just the URL, store the iframe HTML in the landingPage field
            currentTenancyData.landingPage = iframeHtml;

            // You can optionally still store the URL separately if needed
            currentTenancyData.landingPageUrl = landingPageUrl;

            // Add a timestamp
            currentTenancyData.updatedAt = new Date().toISOString();

            // Create the update payload for RTK Query
            const updateData = {
                data: currentTenancyData,
                tenancyId: tenancyId
            };

            console.log("Sending update with RTK Query");

            // Use your existing RTK Query mutation
            const result = await updateTenancy(updateData).unwrap();
            console.log("API response:", result);

            if (result && result.message && result.message.includes("error")) {
                throw new Error(`API error: ${result.message}`);
            }

            setSnackbarMessage("Landing page saved successfully!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Failed to update landing page:", error);
            setSnackbarMessage("Failed to save landing page: " + (error.message || "Unknown error"));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setIsSaving(false);
        }
    };

    // Loading state
    const isLoading = tenancyBasicsLoading || tenancyLoading;

    // Data for preview
    const previewData = {
        colors: brandColors,
        logoSize,
        logo: tenancyBasics?.logo,
        showLogo,
        showLogoBackground,
        companyName: formData.displayName,
        buttonText: formData.buttonText,
        buttonLink: formData.buttonLink,
        headerTitle: formData.headerTitle,
        tipsHeading: formData.tipsHeading,
        mainContent: formData.mainContent,
        pageTitle: formData.pageTitle,
        backgroundImage: formData.backgroundImage,
        tip1Title: formData.tip1Title,
        tip1Content: formData.tip1Content,
        tip2Title: formData.tip2Title,
        tip2Content: formData.tip2Content,
        tip3Title: formData.tip3Title,
        tip3Content: formData.tip3Content,
        tip4Title: formData.tip4Title,
        tip4Content: formData.tip4Content,
        tip5Title: formData.tip5Title,
        tip5Content: formData.tip5Content,
        tip6Title: formData.tip6Title,
        tip6Content: formData.tip6Content
    };

    console.log("Rendering with templateHTML:", templateHTML ? `${templateHTML.length} chars` : "empty");

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="h2" sx={{ ml: 2, mb: 2 }}>
                            {showStyleEditor ? "Edit Style" : "Edit Content"}
                        </Typography>

                        {showStyleEditor ? (
                            <StyleEditor
                                onBack={() => setShowStyleEditor(false)}
                                formData={formData}
                                setFormData={setFormData}
                                handleInputChange={handleInputChange}
                                tenancyBasics={tenancyBasics}
                                onColorChange={handleColorChange}
                                onLogoSizeChange={handleLogoSizeChange}
                                onLogoVisibilityChange={handleLogoVisibilityChange}
                                onLogoBackgroundChange={handleLogoBackgroundChange}
                                onTemplateChange={handleTemplateChange}
                                initialColors={brandColors}
                                initialLogoSize={logoSize}
                                initialShowLogo={showLogo}
                                initialShowLogoBackground={showLogoBackground}
                                isDisabled={false}
                            />
                        ) : (
                            <ContentEditor
                                formData={formData}
                                handleInputChange={handleInputChange}
                                onStyleEditorClick={() => setShowStyleEditor(true)}
                                lightMode={lightMode}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: 2,
                            }}
                        >
                            <Typography variant="h2">Preview</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<KeyboardArrowLeftTwoToneIcon />}
                                    onClick={() => navigate("/settings")}
                                    sx={{ whiteSpace: "nowrap" }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    sx={{
                                        bgcolor: theme.palette.secondary.main,
                                        "&:hover": {
                                            bgcolor: theme.palette.secondary.dark,
                                        },
                                        whiteSpace: "nowrap"
                                    }}
                                    disabled={isSaving}
                                >
                                    {isSaving ? <CircularProgress size={24} /> : "Save & Apply"}
                                </Button>
                            </Box>
                        </Box>
                        <MainCard
                            content={true}
                            boxShadow={true}
                            border={false}
                            sx={{
                                bgcolor: getCardColor(lightMode),
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                                mr: 2,
                                height: 'calc(90vh - 70px)'
                            }}
                        >
                            <Box>
                                {templateHTML ? (
                                    <Box sx={{ height: 'calc(90vh - 170px)' }}>
                                        <LandingPagePreview
                                            templateHTML={templateHTML}
                                            previewData={previewData}
                                        />
                                    </Box>
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 'calc(90vh - 180px)'
                                    }}>
                                        <CircularProgress />
                                    </Box>
                                )}
                            </Box>
                        </MainCard>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default CustomLandingPage;