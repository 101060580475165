import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    CircularProgress,
    FormControl,
    TextField,
    Paper,
    Typography,
    Skeleton
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetTenanciesQuery } from "../../../../store/services/tenancy.service";
import { setTenancyId, setTenancyName } from "../../../../store/reducers/tenancy";
import { useTheme } from "@mui/material/styles";

const Search = memo(() => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const { isHierarchyTenancy } = useSelector((state) => state.tenancy);

    const { tenancyId, tenancyName } = useSelector((state) => state.tenancy);
    const user = useMemo(() => JSON.parse(localStorage.getItem('user')), []);

    const { data: tenancyData = [], isLoading: tenanciesLoading, error: tenanciesError } = useGetTenanciesQuery({
        perPage: 5000, page: 0, includes: "", query: "", additionalQueryParams: ""
    });

    const [localTenancyData, setLocalTenancyData] = useState(tenancyData);
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);

    useEffect(() => {
        if (!user) {
            window.location.replace('/login');
        }
    }, [user]);

    useEffect(() => {
        setLocalTenancyData(tenancyData);
    }, [tenancyData]);

    useEffect(() => {
        if (shouldRefresh) {
            window.location.reload();
        }
    }, [shouldRefresh]);

    useEffect(() => {
        if (tenancyData.length > 0 && tenancyId) {
            // Convert string tenancyId from Redux to number for comparison
            const currentTenancy = tenancyData.find(t => t.tenancyId === parseInt(tenancyId, 10));
            if (currentTenancy) {
                if (currentTenancy.name !== tenancyName) {
                    dispatch(setTenancyName(currentTenancy.name));
                }
            } else {
                console.warn(`No tenancy found with ID ${tenancyId}`);
            }
        }
    }, [tenancyData, tenancyId, tenancyName, dispatch]);

    useEffect(() => {
        // Check for stored tenancy data on component mount
        const storedTenancyId = localStorage.getItem('selectedTenancyId');
        const storedTenancyName = localStorage.getItem('selectedTenancyName');
        if (storedTenancyId && storedTenancyName) {
            dispatch(setTenancyId(storedTenancyId));
            dispatch(setTenancyName(storedTenancyName));
            localStorage.removeItem('selectedTenancyId');
            localStorage.removeItem('selectedTenancyName');
        }
    }, [dispatch]);
    

    const handleChange = useCallback((event, newValue) => {
        if (newValue) {
            localStorage.setItem('selectedTenancyId', newValue.tenancyId);
            localStorage.setItem('selectedTenancyName', newValue.name);

            window.location.reload();
        }
    }, []);

    const handleInputChange = useCallback((event, newInputValue) => {
        setInputValue(newInputValue);
    }, []);

    const handleOpen = useCallback(() => {
        setOpen(true);
        setInputValue('');
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
        setInputValue(tenancyName || '');
    }, [tenancyName]);

    const filterOptions = useCallback((options, params) => {
        return options.filter((option) => {
            if (params.inputValue) {
                const inputValue = params.inputValue.toLowerCase();
                const tenancyIdString = option.tenancyId.toString();
                return option.name.toLowerCase().includes(inputValue) ||
                    tenancyIdString.includes(inputValue);
            }
            return true;
        });
    }, []);

    const optionLabel = useCallback((option) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }
        return `${option.tenancyId} - ${option.name}`;
    }, []);

    const isOptionEqual = useCallback((option, value) => option.tenancyId === value.tenancyId, []);

    const paperComponent = useMemo(() => {
        return ({ children }) => (
            <Paper sx={{ bgcolor: 'background.default' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1, bgcolor: lightMode ? 'grey.200' : 'grey.800' }}>
                    <Typography variant="caption">Tenancy</Typography>
                    <Typography variant="caption">User Licenses</Typography>
                </Box>
                {children}
            </Paper>
        );
    }, []);

    const renderOption = useCallback((props, option) => (
        <li {...props} style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'background.default' }}>
            <span>{option.tenancyId} - {option.name}</span>
            <span>{option.userLicenses}</span>
        </li>
    ), []);

    const renderInput = useCallback((params) => (
        <TextField
            {...params}
            label={tenanciesLoading ? 'Loading...' : 'Select Tenancy'}
            InputProps={{
                ...params.InputProps,
                variant: 'outlined',
                endAdornment: (
                    <React.Fragment>
                        {tenanciesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
        />
    ), [tenanciesLoading]);

    // permissions check
    if ((user?.role !== "Administrators") && !(user?.role?.includes("TenancyAdministrators") && isHierarchyTenancy)) {
        console.log("failed perms check");
        return null;
    }

    if (tenanciesError) {
        return (
            <Box sx={{ width: '30%' }}>
                <Typography color="error">
                    Error loading tenancies. Please try again later.
                </Typography>
            </Box>
        );
    }

    // Loading state
    if (tenanciesLoading) {
        return (
            <Box sx={{ width: '30%' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 1,
                    borderRadius: 1,
                    bgcolor: lightMode ? 'grey.100' : 'grey.900'
                }}>
                    <CircularProgress size={20} />
                    <Skeleton variant="rounded" width="100%" height={30} />
                </Box>
            </Box>
        );
    }


    return (
        <Box sx={{ width: '30%' }}>
            <FormControl sx={{ width: '100%' }}>
                <Autocomplete
                    disablePortal
                    disableClearable
                    id="size-small-outlined"
                    size="small"
                    freeSolo
                    options={localTenancyData}
                    filterOptions={filterOptions}
                    getOptionLabel={optionLabel}
                    isOptionEqualToValue={isOptionEqual}
                    value={localTenancyData.find(row => row.tenancyId === tenancyId) || null}
                    onChange={handleChange}
                    PaperComponent={paperComponent}
                    renderOption={renderOption}
                    renderInput={renderInput}
                    onInputChange={handleInputChange}
                    inputValue={open ? inputValue : (tenancyId + " - " + tenancyName || '')}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                />
            </FormControl>
        </Box>
    );
});

export default Search;
