// In tenancySlice.js or wherever you manage global state slices
import { createSlice } from '@reduxjs/toolkit';

export const tenancySlice = createSlice({
    name: 'tenancy',
    initialState: {
        tenancyId: '',
        tenancyName: '',
        isHierarchyTenancy: false,
    },
    reducers: {
        setTenancyId: (state, action) => {
            state.tenancyId = action.payload;
        },
        setTenancyName: (state, action) => {
            state.tenancyName = action.payload;
        },
        setIsHierarchyTenancy: (state, action) => {
            state.isHierarchyTenancy = action.payload;
        }
    },
});

export const { setTenancyId, setTenancyName, setIsHierarchyTenancy } = tenancySlice.actions;

export default tenancySlice.reducer;
