// src/components/ScrollableTabs.jsx
import React from "react";
import { Box, IconButton, Tabs } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

/**
 * A flexible scrollable tabs component with arrow navigation
 * 
 * @param {number} value - Current active tab index
 * @param {function} onChange - Function to call when tab changes
 * @param {React.ReactNode} children - Tab components to render
 * @param {string} ariaLabel - Accessibility label for the tabs
 * @param {object} tabsProps - Additional props to pass to the Tabs component
 * @param {object} containerProps - Additional props for the container Box
 * @param {number} spacing - Space to leave on sides for the arrows (default: 4)
 */

const ScrollableTabs = ({
    value,
    onChange,
    children,
    ariaLabel = "scrollable tabs",
    tabsProps = {},
    containerProps = {},
    spacing = 4
}) => {
    // Make sure children exists and is an array
    const tabsArray = React.Children.toArray(children);
    const tabCount = tabsArray.length;

    const handlePrevTab = () => {
        const newValue = value > 0 ? value - 1 : tabCount - 1;
        onChange(null, newValue);
    };

    const handleNextTab = () => {
        const newValue = value < tabCount - 1 ? value + 1 : 0;
        onChange(null, newValue);
    };

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                mb: 4,
                ...containerProps?.sx
            }}
            {...containerProps}
        >
            {/* Only show arrows if there are tabs */}
            {tabCount > 1 && (
                <IconButton
                    onClick={handlePrevTab}
                    sx={{
                        position: 'absolute',
                        left: -16,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 9,
                        bgcolor: 'background.paper',
                        boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
                        '&:hover': { bgcolor: 'action.hover' }
                    }}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
            )}

            {/* Actual Tabs */}
            <Box sx={{ mx: spacing }}>
                <Tabs
                    value={value}
                    onChange={onChange}
                    aria-label={ariaLabel}
                    variant="scrollable"
                    scrollButtons={false}
                    sx={{
                        '& .MuiTab-root': {
                            minWidth: 'auto',
                            px: 2
                        },
                        ...(tabsProps?.sx || {})
                    }}
                    {...tabsProps}
                >
                    {children}
                </Tabs>
            </Box>

            {/* Only show arrows if there are tabs */}
            {tabCount > 1 && (
                <IconButton
                    onClick={handleNextTab}
                    sx={{
                        position: 'absolute',
                        right: -16,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 9,
                        bgcolor: 'background.paper',
                        boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
                        '&:hover': { bgcolor: 'action.hover' }
                    }}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default ScrollableTabs;