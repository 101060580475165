export const PRESET_LANDING_PAGE = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>{page.title}</title>
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap" rel="stylesheet">
    <script>
    function redirectToPage() {
      console.log("Hello world")
    }
   
</script>
    <style>
        body {
            font-family: 'Inter', Arial, sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
            min-height: 100vh;
        }
        .wrapper {
            transform: scale(0.9);
            transform-origin: center;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .container {
            max-width: 1200px;
            width: 100%;
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .header {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
        .header img {
            width: {logo.size}px;
            height: auto;
            margin-right: 15px;
            background-color: {color.primary};
            padding: 10px 20px;
            border-radius: 8px;
            margin-bottom: 0px;
        }
        .header h1 {
            margin: 0;
            font-size: 24px;
            color: #333;
        }
        h1 {
            color: #FFFFFF;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0px;
            display: inline-block;
            padding: 10px 20px;
            background-color: {color.primary};
            border-radius: 8px;
        }
        h2 {
            color: #FFFFFF;
            font-size: 20px;
            margin-top: 0px;
            font-weight: 700;
            display: inline-block;
            padding: 8px 16px;
            background-color: {color.primary};
            border-radius: 8px;
        }
        .tips-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 20px;
            padding: 25px;
            background-image: url('{background.image}');
            background-size: cover;
            background-position: center;
            border-radius: 8px;
            position: relative;
            z-index: 1;
        }

        .tips-grid::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0);
            z-index: -1;
            border-radius: 8px;
        }

        .tip {
            background-color: rgba(249, 249, 249, 0.9);
            padding: 15px;
            border-left: 5px solid {color.primary};
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: left;
            text-align: left;
            font-size: 16px;
        }

        .tip img {
            width: 60px;
            height: 60px;
            margin-bottom: 10px;
            border-radius: 5px;
            /* Style for colored SVGs */
            filter: brightness(0) saturate(100%) invert(15%) sepia(34%) saturate(976%) hue-rotate(151deg) brightness(96%) contrast(94%);
        }

        /* This will be dynamically replaced based on the primary color */
        .svg-filter-{color.primary} .tip img {
            filter: brightness(0) saturate(100%) {primary.filter};
        }

        .tip h3 {
            color: #000000;
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 10px;
        }

        .tip p {
            color: #000000;
            margin: 0px 0 0;
        }
        .image-placeholder {
            width: 100%;
            height: 200px;
            background-color: #e0e0e0;
            border-radius: 8px;
            margin: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999;
            font-size: 18px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        /* Media Queries for Responsive Grid */
        @media (max-width: 1200px) {
            .tips-grid {
                grid-template-columns: repeat(2, 1fr); 
            }
        }

        @media (max-width: 768px) {
            .tips-grid {
                grid-template-columns: 1fr;
            }
        }
        .button {
            appearance: none;
            background-color: #FFFFFF;
            border-radius: 10px;
            box-sizing: border-box;
            color: {color.primary};
            cursor: pointer;
            display: inline-block;
            font-family: inherit;
            font-size: 24px;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            min-height: 40px;
            min-width: 0;
            outline: none;
            padding: 20px 20px;
            text-align: center;
            text-decoration: none;
            transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            will-change: transform;
            margin-top: 0px;
            margin-bottom: 0px;
            border: 5px solid; 
        }

        .button:hover {
            background-color: {color.primary};
            border: 5px solid transparent; 
            color: #FFFFFF;
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
            transform: translateY(-2px);
        }

        .button:active {
            box-shadow: none;
            transform: translateY(0);
            border: 5px solid {color.primary};
        }

        .button:disabled {
            border: 5px solid {color.primary};
            pointer-events: none;
        }
    </style>
</head>
<body class="svg-filter-{color.primary}">
    <div class="wrapper">
        <div class="container">
            <!-- Header with logo -->
            <div class="header">
                <img src="{client.logo}" alt="Logo">
            </div>

            <h1>{header.title}</h1>
            
            <p>{main.content}</p>
            
            <!-- <p><button class="button" onclick="redirectToPage()">{button.text}</button></p> -->
            
            <h2>{tips.heading}</h2>

            <div class="tips-grid">
                <div class="tip">
                    {icon.emailDomain}
                    <h3>{tip1.title}</h3>
                    <p>{tip1.content}</p>
                </div>

                <div class="tip">
                    {icon.spoofing}
                    <h3>{tip2.title}</h3>
                    <p>{tip2.content}</p>
                </div>
                
                <div class="tip">
                    {icon.urgency}
                    <h3>{tip3.title}</h3>
                    <p>{tip3.content}</p>
                </div>
                
                <div class="tip">
                    {icon.links}
                    <h3>{tip4.title}</h3>
                    <p>{tip4.content}</p>
                </div>
                
                <div class="tip">
                    {icon.attachments}
                    <h3>{tip5.title}</h3>
                    <p>{tip5.content}</p>
                </div>
                
                <div class="tip">
                    {icon.sensitiveInfo}
                    <h3>{tip6.title}</h3>
                    <p>{tip6.content}</p>
                </div>
            </div>
        </div>
    </div>
</body>

<script>
    function redirectToPage() {
      console.log("Hello world")
    }
</script>
</html>
`;