import React, { useEffect, useState } from "react";
import { Box, Grid, Tab, Tabs, Typography, Snackbar } from "@mui/material";
import UsersTable from "./UsersTable";
import UserSync from "./UserSync";
import AdminLogs from "./AdminLogs";
import RoleTable from "../on-demand/Tables/RoleTable";
import { useGetTenancyBasicsQuery, useGetTenancyByIdQuery } from "../../store/services/tenancy.service";
import { useSelector } from "react-redux";
import AdditionalDataTable from "../on-demand/Tables/AdditionalDataTable";
import { useLocation, useNavigate } from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`user-management-tabpanel-${index}`}
            aria-labelledby={`user-management-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `user-management-tab-${index}`,
        'aria-controls': `user-management-tabpanel-${index}`,
    };
}

function AdditionalDataTabPanel(props) {
    const { children, value, name, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== name}
            id={`additional-data-tabpanel-${name}`}
            aria-labelledby={`additional-data-tab-${name}`}
            {...other}
        >
            {value === name && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function additionalDataA11yProps(name) {
    return {
        id: `additional-data-tab-${name}`,
        'aria-controls': `additional-data-tabpanel-${name}`,
    };
}

const UserManagement = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { tenancyId } = useSelector((state) => state.tenancy);
    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user?.role === "Administrators";

    const searchParams = new URLSearchParams(location.search);
    const initialTab = parseInt(searchParams.get("tab")) || 0;

    const [licenseLimitReached, setLicenseLimitReached] = useState(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const [tabValue, setTabValue] = useState(initialTab);
    const [additionalDataValue, setAdditionalDataValue] = useState("department");
    const [openModal, setOpenModal] = useState(searchParams.get("importGroup") === "true");

    const { data: tenancyData, isLoading: tenancyLoading, error: tenancyError } = useGetTenancyByIdQuery({ tenancyId });

    const {
        data: tenancyBasicData,
        isLoading: tenancyBasicDataLoading,
        isError: tenancyBasicDataError
    } = useGetTenancyBasicsQuery(
        { tenancyId: tenancyData?.tenancyId },
        { skip: !tenancyData || !tenancyData.tenancyId }
    );

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        navigate(`?tab=${newValue}`, { replace: true });
    };

    const handleAdditionalDataChange = (event, value) => {
        if (value === null) return;
        setAdditionalDataValue(value);
    };

    useEffect(() => {
        if (tenancyData && !tenancyLoading && !tenancyError) {
            const isLimitReached = tenancyData.userCount >= tenancyData.userLicenses;
            setLicenseLimitReached(isLimitReached);
        }
    }, [tenancyData, tenancyLoading, tenancyError]);

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="user management tabs">
                        <Tab label="User Management" {...a11yProps(0)} />
                        <Tab label="User Sync" {...a11yProps(1)} />
                        <Tab label="Additional Data" {...a11yProps(2)} />
                        <Tab label="Logs" {...a11yProps(3)} />
                    </Tabs>
                    {tenancyData && !tenancyLoading && !tenancyError && (
                        <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
                            User Licenses: {tenancyBasicData?.userCount} / {tenancyData?.userLicenses}
                        </Typography>
                    )}
                </Box>

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={licenseLimitReached}
                    message="Maximum user licenses reached. Please contact your system admin."
                    severity="warning"
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            bgcolor: 'warning.main',
                            color: 'warning.contrastText'
                        }
                    }}
                />

                <TabPanel value={tabValue} index={0}>
                    <UsersTable type={"edit"} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <UserSync openModal={openModal} setOpenModal={setOpenModal} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Grid item xs={12}>
                        <Tabs value={additionalDataValue} onChange={handleAdditionalDataChange} aria-label="additional data tabs" sx={{ mb: 2 }}>
                            {isAdmin && <Tab label="Roles" value="role" {...additionalDataA11yProps("role")} />}
                            <Tab label="Departments" value="department" {...additionalDataA11yProps("department")} />
                            <Tab label="Location" value="location" {...additionalDataA11yProps("location")} />
                            <Tab label="Cities" value="city" {...additionalDataA11yProps("city")} />
                            <Tab label="Countries" value="country" {...additionalDataA11yProps("country")} />
                            <Tab label="Company Names" value="companyname" {...additionalDataA11yProps("companyname")} />
                            <Tab label="States" value="state" {...additionalDataA11yProps("state")} />
                        </Tabs>

                        {isAdmin && (
                            <AdditionalDataTabPanel value={additionalDataValue} name="role">
                                <RoleTable type={"edit"} />
                            </AdditionalDataTabPanel>
                        )}
                        <AdditionalDataTabPanel value={additionalDataValue} name="department">
                            <AdditionalDataTable name={"Department"} type={"edit"} />
                        </AdditionalDataTabPanel>
                        <AdditionalDataTabPanel value={additionalDataValue} name="location">
                            <AdditionalDataTable name={"Location"} type={"edit"} />
                        </AdditionalDataTabPanel>
                        <AdditionalDataTabPanel value={additionalDataValue} name="city">
                            <AdditionalDataTable name={"City"} type={"edit"} />
                        </AdditionalDataTabPanel>
                        <AdditionalDataTabPanel value={additionalDataValue} name="country">
                            <AdditionalDataTable name={"Country"} type={"edit"} />
                        </AdditionalDataTabPanel>
                        <AdditionalDataTabPanel value={additionalDataValue} name="companyname">
                            <AdditionalDataTable name={"CompanyName"} type={"edit"} />
                        </AdditionalDataTabPanel>
                        <AdditionalDataTabPanel value={additionalDataValue} name="state">
                            <AdditionalDataTable name={"State"} type={"edit"} />
                        </AdditionalDataTabPanel>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <AdminLogs />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default UserManagement;