import PropTypes from "prop-types";
import { useEffect } from "react";
import { Avatar, Box, CardContent, Grid, Stack, Typography } from "@mui/material";
import MainCard from "components/cards/MainCard";
import ProfileTab from "./ProfileTab";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const getRole = (user) => {
    if (user.role === "") return "User";
    if (user.role === "Administrators") return "Super Admin";
    // use includes here to work around case where role is "TenancyAdministrators, PartnerAdministrators" 
    // this is cased by enabling the partner tenancy field(this only happens for tenancy admins)
    if (user.role.includes("TenancyAdministrators")) return "Tenancy Admin";
    return "";
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = ({ open }) => {
    const user = JSON.parse(localStorage.getItem("user"));


    useEffect(() => {
        // check if the user is logged in
        if (user == null) {
            window.location.replace("/login");
        }
    }, [user]);

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75, bgcolor: 'background.default'}}>
            <MainCard elevation={0} border={false} content={false} sx={{ bgcolor: 'background.default'}}>
                {open &&
                    <CardContent sx={{ px: 2.5, pt: 3,  bgcolor: 'background.default'}}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Stack direction="row" spacing={1.25} alignItems="center">
                                    <Avatar alt="profile user" src={""} sx={{ width: 32, height: 32 }} />
                                    <Stack>
                                        <Typography variant="h6">{user?.firstName}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {getRole(user)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                }
                <ProfileTab open={open} />
            </MainCard>
        </Box>
    );
};

Profile.propTypes = {
    open: PropTypes.bool
};

export default Profile;
