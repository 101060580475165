import { useState, useCallback, useEffect } from "react";
import {
    Box,
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Stack,
    CircularProgress,
    Snackbar,
    Alert
} from "@mui/material";
import { useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
    useUpdateTenancyMutation,
    useGetTenancyByIdQuery
} from "../../store/services/tenancy.service";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import CustomStepIcon from "../../components/Icon/CustomStepIcon";
import ColorLibConnector from "../../components/ColorLibConnector";
import BrandingSelection from "./BrandingSelection";
import SettingsSelection from "./SettingsSelection";
import AdminsSelection from "./AdminsSelection";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import {
    useGetUniversityCourseSettingsQuery,
    useSaveUniversityCourseSettingsMutation
} from "../../store/services/university-service";
import { useUploadToS3Mutation } from "../../store/services/user-service";
import MainCard from "../../components/cards/MainCard";

const STEPS = ["Branding", "Settings", "Assign Admins"];

// Default values for top-level tenancies with no parent
const DEFAULT_VALUES = {
    branding: {
        logo: "",
        primaryHex: "#74E1BF",
        secondaryHex: "#4281EE",
        tertiaryHex: "#FDC95D",
    },
    settings: {
        courseRetaking: false,
        oneClickCourses: false,
        automaticCourseAssignment: false,
        reports: true,
        anonymiseReports: true,
        onDemand: true
    }
};

const getInitialState = (provisionTenancyData, parentTenancyData, universitySettings) => {

    // If parentTenancyId is -1 or there's no parent data, use default values
    const hasParent = parentTenancyData && provisionTenancyData?.parentTenancyId !== "-1";
    const parentSettings = hasParent ? universitySettings : null;

    // Always inherit from parent if available, regardless of child values
    return {
        branding: {
            logo: hasParent ? parentTenancyData?.logo : DEFAULT_VALUES.branding.logo,
            primaryHex: hasParent ? parentTenancyData?.primaryHex : DEFAULT_VALUES.branding.primaryHex,
            secondaryHex: hasParent ? parentTenancyData?.secondaryHex : DEFAULT_VALUES.branding.secondaryHex,
            tertiaryHex: hasParent ? parentTenancyData?.tertiaryHex : DEFAULT_VALUES.branding.tertiaryHex,
        },
        settings: {
            courseRetaking: hasParent ?
                parentTenancyData?.enableCourseRetake :
                DEFAULT_VALUES.settings.courseRetaking,

            oneClickCourses: hasParent ?
                parentSettings?.allowUnauthorisedTakeCourse :
                DEFAULT_VALUES.settings.oneClickCourses,

            // when auto course assignment is off it can be either null or -1 so we must handle both cases
            automaticCourseAssignment: hasParent ?
                (parentTenancyData?.automaticCourseAssignmentDays !== "-1" && parentTenancyData?.automaticCourseAssignmentDays !== null) :
                DEFAULT_VALUES.settings.automaticCourseAssignment,

            reports: hasParent ?
                parentTenancyData?.enableReporting :
                DEFAULT_VALUES.settings.reports,

            anonymiseReports: hasParent ?
                parentTenancyData?.anonymiseReports :
                DEFAULT_VALUES.settings.anonymiseReports,

            onDemand: hasParent ?
                parentTenancyData?.enableOndemand :
                DEFAULT_VALUES.settings.onDemand
        },
        assignAdmins: {
            admins: []
        }
    };
};

const Provisioning = () => {
    const { provisionId } = useParams(); // ID of tenancy being provisioned
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});
    const [parentTenancyId, setParentTenancyId] = useState(null);
    const [updateTenancy] = useUpdateTenancyMutation();
    const [updateUniversityCourseSettings] = useSaveUniversityCourseSettingsMutation();
    const [uploadToS3] = useUploadToS3Mutation();

    // Snackbar
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const {
        data: provisionTenancyData,
        isLoading: provisionTenancyLoading,
        error: provisionTenancyError
    } = useGetTenancyByIdQuery(
        { tenancyId: provisionId },
        { skip: !provisionId }
    );

    const {
        data: parentTenancyData,
        isLoading: parentTenancyLoading,
        error: parentTenancyError
    } = useGetTenancyByIdQuery(
        { tenancyId: parentTenancyId },
        { skip: !parentTenancyId || parentTenancyId === "-1" }
    );

    const {
        data: universitySettings,
        error: universitySettingsError,
        isLoading: universitySettingsLoading,
        refetch: refetchUniversitySettings
    } = useGetUniversityCourseSettingsQuery(
        { tenancyId: parentTenancyId },
        { skip: !parentTenancyId || parentTenancyId === "-1" }
    );

    useEffect(() => {
        if (provisionTenancyData && provisionTenancyData.parentTenancyId) {
            setParentTenancyId(provisionTenancyData.parentTenancyId);
        }
    }, [provisionTenancyData]);

    useEffect(() => {
        if (!provisionTenancyData) return;

        if (provisionTenancyData.parentTenancyId === "-1") {
            setState(getInitialState(provisionTenancyData, null, null));
            return;
        }

        if (!parentTenancyData || !universitySettings) {
            console.log("Waiting for parent data to load...");
            return;
        }

        setState(getInitialState(provisionTenancyData, parentTenancyData, universitySettings));
    }, [provisionTenancyData, parentTenancyData, universitySettings, provisionId, parentTenancyId]);

    const handleFinish = useCallback(() => {
        navigate("/tenants");
    }, [navigate]);

    // stepper handling 
    const handleChange = useCallback((section, value) => {
        setState(prevState => ({
            ...prevState,
            [section]: value
        }));
    }, []);

    const handlePrevious = useCallback(() => {
        setActiveStep((prevStep) => prevStep - 1);
    }, []);

    const handleApplyTenancySettings = useCallback(async (state) => {
        setLoading(true);

        const updatedTenancyData = {
            ...provisionTenancyData,
            logo: state.branding.logo,
            primaryHex: state.branding.primaryHex,
            secondaryHex: state.branding.secondaryHex,
            tertiaryHex: state.branding.tertiaryHex,
            enableCourseRetake: state.settings.courseRetaking,
            automaticCourseAssignmentDays: state.settings.automaticCourseAssignment ? "0" : "-1",
            enableReporting: state.settings.reports,
            anonymiseReports: state.settings.anonymiseReports,
            enableOndemand: state.settings.onDemand
        };

        const universitySettingsData = {
            tenancyId: provisionId,
            settingsData: {
                tenancyId: provisionId,
                allowUnauthorisedTakeCourse: state.settings.oneClickCourses
            }
        };

        try {
            await updateTenancy({
                data: updatedTenancyData,
                tenancyId: provisionId
            }).unwrap();

            await updateUniversityCourseSettings(universitySettingsData).unwrap();

            setSuccess(true);
            setSnackbarMessage("All settings updated successfully.");
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error updating settings:", error);
            setSnackbarMessage("Error updating settings. Please try again.");
            setSnackbarSeverity("error");
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    }, [provisionId, updateUniversityCourseSettings, provisionTenancyData, updateTenancy, state]);

    const handleCreateAdmins = useCallback(async (state) => {
        if (!state.assignAdmins.admins || state.assignAdmins.admins.length === 0) {
            return;
        }

        setLoading(true);

        try {
            // Generate CSV content for admins
            const headers = "Email,FirstName,LastName,SendEmailTo,Location,Department,City,Country,State,CompanyName,Role\n";

            const csvRows = state.assignAdmins.admins.map(admin => {
                return [
                    admin.email,
                    admin.firstName || '',
                    admin.lastName || '',
                    admin.email, // Use email for SendEmailTo
                    admin.location || '',
                    admin.department || '',
                    admin.city || '',
                    admin.country || '',
                    admin.state || '',
                    admin.companyName || '',
                    'TenancyAdministrators' 
                ].map(field => `"${field}"`).join(',');
            });

            const csvContent = headers + csvRows.join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv' });
            const file = new File([blob], 'admins.csv', { type: 'text/csv' });

            const response = await uploadToS3({
                tenancyId: provisionId,
                file: file
            }).unwrap();

            if (response.success) {
                setSnackbarMessage("Administrators have been added successfully.");
                setSnackbarSeverity("success");
            } else {
                throw new Error("Upload failed");
            }
        } catch (error) {
            console.error("Error adding administrators:", error);
            setSnackbarMessage("Error adding administrators. Please try again.");
            setSnackbarSeverity("error");
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    }, [provisionId, uploadToS3]);

    const handleNext = useCallback(async () => {
        if (activeStep === STEPS.length - 1) {
            setLoading(true);
            try {
                await handleApplyTenancySettings(state);

                if (state.assignAdmins.admins && state.assignAdmins.admins.length > 0) {
                    await handleCreateAdmins(state);
                }
            } catch (error) {
                console.error("Error during provisioning:", error);
                setError(true);
                setSnackbarMessage("Error during provisioning. Please try again.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
                setSuccess(true)
            }
        } else {
            setActiveStep((prevStep) => prevStep + 1);
        }
    }, [activeStep, state, handleApplyTenancySettings, handleCreateAdmins]);

    const handleReset = useCallback(() => {
        setActiveStep(0);
        setSuccess(false);
        setState(getInitialState(provisionTenancyData, parentTenancyData, universitySettings));
    }, [provisionTenancyData, parentTenancyData, universitySettings]);

    const isNextDisabled = useCallback(() => {
        if (activeStep === 2) return state.assignAdmins.admins.length === 0;
        else return false;

    }, [activeStep, state.assignAdmins]);

    const handleLabelClick = useCallback((index) => () => {
        if (index < activeStep) setActiveStep(index);
    }, [activeStep]);

    const stepComponents = [
        <BrandingSelection
            state={state}
            onChange={handleChange}
        />,
        <SettingsSelection
            state={state}
            onChange={handleChange}
        />,
        <AdminsSelection
            state={state}
            onChange={handleChange}
        />
    ];

    const isLoading = provisionTenancyLoading ||
        (!provisionTenancyData) ||
        (provisionTenancyData &&
            provisionTenancyData.parentTenancyId !== "-1" &&
            (parentTenancyLoading || !parentTenancyData || universitySettingsLoading || !universitySettings));

    const hasError = provisionTenancyError ||
        (provisionTenancyData &&
            provisionTenancyData.parentTenancyId !== "-1" &&
            (parentTenancyError || universitySettingsError));

    if (isLoading && !hasError) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                <CircularProgress size={40} />
                <Typography sx={{ mt: 2 }}>Loading default values...</Typography>
            </Box>
        );
    }

    if (success) {
        return (
            <MainCard content={true} sx={{ width: "100%", overflow: "hidden", display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(100vh - 250px)', bgcolor: 'background.default' }} border={false} boxShadow={false}>
                <Stack direction="column" spacing={4} alignItems="center" sx={{ textAlign: 'center' }}>
                    <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 60 }} />
                    <Typography variant="h4" sx={{ mt: 2 }}>
                        Tenancy provisioned successfully!
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 4, width: '100%' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFinish}
                            sx={{ ml: 2, width: '50%' }}
                        >
                            Finish
                        </Button>
                    </Stack>
                </Stack>
            </MainCard>
        );
    }

    return (
        <Box sx={{
            maxWidth: "1200px",
            mx: "auto",
            px: { xs: 1, sm: 2, md: 2 },
            width: "100%"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "stretch", md: "center" },
                gap: 2,
                mb: 4
            }}>
                <Box sx={{
                    display: "flex",
                    gap: 2,
                    order: { xs: 2, md: 1 },
                    justifyContent: { xs: "center", md: "flex-start" },
                    minWidth: { md: "250px" }
                }}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Button
                            variant="outlined"
                            color="black"
                            onClick={() => navigate(location.from ? location.from : -1)}
                        >
                            Cancel
                        </Button>
                        {activeStep > 0 && (
                            <Button
                                onClick={handlePrevious}
                                variant="contained"
                                color="secondary"
                                startIcon={<KeyboardArrowLeftTwoToneIcon />}
                                disabled={loading}
                            >
                                Previous
                            </Button>
                        )}
                    </Box>
                </Box>
                <Box sx={{
                    flex: 1,
                    order: { xs: 1, md: 2 },
                    mx: "auto",
                    width: "100%",
                    maxWidth: "800px"
                }}>
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        connector={<ColorLibConnector />}
                    >
                        {STEPS.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    StepIconComponent={(props) => (
                                        <CustomStepIcon
                                            {...props}
                                            onClick={handleLabelClick(index)}
                                        />
                                    )}
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    order: { xs: 3, md: 3 },
                    minWidth: { md: "200px" }
                }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleNext}
                        disabled={isNextDisabled() || loading}
                    >
                        {loading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            activeStep === STEPS.length - 1 ? "Confirm & Complete" : "Confirm"
                        )}
                    </Button>
                </Box>
            </Box>

            <StepContent
                activeStep={activeStep}
                stepComponents={stepComponents}
            />
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

function StepContent({ activeStep, stepComponents }) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {stepComponents[activeStep]}
        </Box>
    );
}

StepContent.propTypes = {
    activeStep: PropTypes.number.isRequired,
    stepComponents: PropTypes.array.isRequired,
};

export default Provisioning;