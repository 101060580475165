import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import processTemplate from './ProcessTemplate';


const adjustLogoBackgroundInPreview = (htmlContent, showLogoBackground) => {
    if (!htmlContent) return '';

    let modifiedHtml = htmlContent;

    if (showLogoBackground === false) {

        modifiedHtml = modifiedHtml.replace(
            /\.header img\s*{([^}]*)background-color:[^;]+;([^}]*)}/g,
            '.header img {$1background-color: transparent;$2}'
        );

        modifiedHtml = modifiedHtml.replace(
            /\.header img\s*{([^}]*)padding:[^;]+;([^}]*)}/g,
            '.header img {$1padding: 0;$2}'
        );
    }

    return modifiedHtml;
}

const processTemplateWithLogoBackground = (template, data) => {

    const processedTemplate = processTemplate(template, data);

    return adjustLogoBackgroundInPreview(processedTemplate, data.showLogoBackground);
}

const LandingPagePreview = ({ templateHTML, previewData }) => {
    const [processedHtml, setProcessedHtml] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (templateHTML && previewData) {
            try {
                const processed = processTemplateWithLogoBackground(templateHTML, previewData);
                setProcessedHtml(processed);
            } catch (error) {
                console.error('Error processing template for preview:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [templateHTML, previewData]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                height: '100%',
                overflow: 'hidden',
                borderRadius: 1,
                '& iframe': {
                    width: '100%',
                    height: '100%',
                    border: 'none',
                },
            }}
        >
            <iframe
                srcDoc={processedHtml}
                title="Landing Page Preview"
                sandbox="allow-scripts"
            />
        </Box>
    );
};

export default LandingPagePreview;