// components/LandingPage/ContentEditor.js
import React from "react";
import {
    Box, Button, TextField, Typography, Accordion,
    AccordionSummary, AccordionDetails
} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MainCard from "../../components/cards/MainCard";
import { getCardColor } from "../../utils/colorUtils";

/**
 * Component for editing the content of the landing page
 */
const ContentEditor = ({
                           formData,
                           handleInputChange,
                           onStyleEditorClick,
                           lightMode
                       }) => {
    return (
        <>
            <MainCard
                content={true}
                boxShadow={true}
                border={false}
                sx={{
                    bgcolor: getCardColor(lightMode),
                    display: "flex",
                    flexDirection: "column",
                    ml: 2,
                    mb: 1,
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                >
                    Main Content
                </Typography>

                <TextField
                    label="Header Title"
                    value={formData.headerTitle}
                    onChange={handleInputChange("headerTitle")}
                    fullWidth
                    sx={{ mb: 2 }}
                    placeholder="This wasn't a real cyber attack, but it could have been."
                />

                <TextField
                    label="Main Content Text"
                    name="mainContent"
                    value={formData.mainContent}
                    onChange={handleInputChange("mainContent")}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                    placeholder="The email you received was part of a training and awareness exercise..."
                />

                <TextField
                    label="Tips Section Heading"
                    value={formData.tipsHeading}
                    onChange={handleInputChange("tipsHeading")}
                    fullWidth
                    sx={{ mb: 2 }}
                    placeholder="Here are some tips to stay secure:"
                />
            </MainCard>

            <MainCard
                content={true}
                boxShadow={true}
                border={false}
                sx={{
                    bgcolor: getCardColor(lightMode),
                    display: "flex",
                    flexDirection: "column",
                    ml: 2,
                    mb: 1,
                }}
            >
                <Accordion sx={{ mb: 1, borderRadius: 2 }}>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography
                            variant="h6"
                        >
                            Security Tips
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                Tip 1
                            </Typography>
                            <TextField
                                label="Title"
                                value={formData.tip1Title}
                                onChange={handleInputChange("tip1Title")}
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder="Public Domains"
                            />
                            <TextField
                                label="Content"
                                value={formData.tip1Content}
                                onChange={handleInputChange("tip1Content")}
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Legitimate organisations will never contact you from a public email domain like '@gmail.com'."
                            />
                        </Box>

                        {/* Do the same for the remaining tips - remove the || default and add placeholder */}
                        <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                Tip 2
                            </Typography>
                            <TextField
                                label="Title"
                                value={formData.tip2Title}
                                onChange={handleInputChange("tip2Title")}
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder="Domain Spoofing"
                            />
                            <TextField
                                label="Content"
                                value={formData.tip2Content}
                                onChange={handleInputChange("tip2Content")}
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Be cautious of email addresses that look legitimate but contain subtle spelling errors..."
                            />
                        </Box>

                        <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                Tip 3
                            </Typography>
                            <TextField
                                label="Title"
                                value={formData.tip3Title}
                                onChange={handleInputChange("tip3Title")}
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder="Urgency"
                            />
                            <TextField
                                label="Content"
                                value={formData.tip3Content}
                                onChange={handleInputChange("tip3Content")}
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Be wary of emails that create a sense of urgency, especially from colleagues..."
                            />
                        </Box>

                        <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                Tip 4
                            </Typography>
                            <TextField
                                label="Title"
                                value={formData.tip4Title}
                                onChange={handleInputChange("tip4Title")}
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder="Hover Over Links"
                            />
                            <TextField
                                label="Content"
                                value={formData.tip4Content}
                                onChange={handleInputChange("tip4Content")}
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Always hover your mouse over links in emails to check the actual URL before clicking..."
                            />
                        </Box>

                        <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                Tip 5
                            </Typography>
                            <TextField
                                label="Title"
                                value={formData.tip5Title}
                                onChange={handleInputChange("tip5Title")}
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder="Attachments"
                            />
                            <TextField
                                label="Content"
                                value={formData.tip5Content}
                                onChange={handleInputChange("tip5Content")}
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Only open email attachments if you know the sender and are expecting the file..."
                            />
                        </Box>

                        <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                Tip 6
                            </Typography>
                            <TextField
                                label="Title"
                                value={formData.tip6Title}
                                onChange={handleInputChange("tip6Title")}
                                fullWidth
                                sx={{ mb: 2 }}
                                placeholder="Sensitive Info"
                            />
                            <TextField
                                label="Content"
                                value={formData.tip6Content}
                                onChange={handleInputChange("tip6Content")}
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Reputable organisations will almost never request sensitive information such as login credentials..."
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 2,
                        mb: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={onStyleEditorClick}
                    >
                        Edit Style
                    </Button>
                </Box>
            </MainCard>
        </>
    );
};

export default ContentEditor;