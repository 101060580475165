import React, { useState, useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';

const EmailPreview = ({ data, content, isLoading }) => {
    const [processedContent, setProcessedContent] = useState('');

    useEffect(() => {
        if (!content || isLoading) return;

        const replaceTemplateVariables = (htmlContent) => {
            // First handle the template variables we want to keep
            let processed = htmlContent.replace(
                /{mainText}/g,
                '<div class="skeleton skeleton-text"></div> <div class="skeleton skeleton-text"></div>'
            );

            processed = processed.replace(
                /{button\.text}/g,
                '&emsp;&emsp;&emsp;&emsp;'
            );

            // Remove all other text content
            const parser = new DOMParser();
            const doc = parser.parseFromString(processed, 'text/html');

            const removeTextNodes = (node) => {
                // Skip script and style tags
                if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE') return;

                // Remove regular text nodes (not our placeholder content)
                if (node.nodeType === 3 &&
                    node.textContent.trim() &&
                    !node.parentElement?.classList.contains('skeleton')) {
                    node.textContent = '';
                }

                // Recursively process child nodes
                const children = [...node.childNodes];
                children.forEach(removeTextNodes);
            };

            removeTextNodes(doc.body);
            return doc.body.innerHTML;
        };

        setProcessedContent(replaceTemplateVariables(content));
    }, [content, isLoading]);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '400px',
                    backgroundColor: 'white',
                    flexGrow: 1,
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <iframe
            srcDoc={`
        <html>
          <head>
            <style>
              body {
                margin: 0;
                transform-origin: 0 0;
                transform: scale(0.5);
                width: 200%;
                height: 200%;
                font-family: Arial, sans-serif;
                overflow: hidden;
              }
              
              .skeleton {
                background-color: #e0e0e0;
                border-radius: 4px;
                display: inline-block;
              }
              
              .skeleton-text {
                height: 60px;
                width: 100%;
                margin: 10px 0;
              }
            </style>
          </head>
          <body>
            ${processedContent || ''}
          </body>
        </html>
      `}
            style={{
                border: "none",
                width: "100%",
                height: "400px",
                backgroundColor: "white",
                flexGrow: 1,
                overflow: "hidden"
            }}
            title="Email Preview"
            sandbox="allow-same-origin allow-scripts"
            onLoad={(e) => {
                //prevent navigation in the iframe
                const frame = e.target;
                const frameDoc = frame.contentDocument || frame.contentWindow.document;

                // Disable all links by preventing their default action
                const links = frameDoc.querySelectorAll('a');
                links.forEach(link => {
                    link.onclick = (e) => {
                        e.preventDefault();
                        return false;
                    };
                });

                // Disable all buttons and input submits
                const buttons = frameDoc.querySelectorAll('button, input[type="submit"], input[type="button"]');
                buttons.forEach(button => {
                    button.onclick = (e) => {
                        e.preventDefault();
                        return false;
                    };
                });
            }}
        />
    );
};

export default EmailPreview;