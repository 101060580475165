import { useState } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Tooltip
} from "@mui/material";
import HelpIcon from "@mui/icons-material/HelpOutline";
import MainCard from "../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import LoadingSwitch from "../../components/LoadingSwitch";
import { getCardColor } from "../../utils/colorUtils";


SettingsSelection.propTypes = {
    state: PropTypes.object,
    onChange: PropTypes.func
};


export default function SettingsSelection({ state, onChange }) {
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const [loadingStates] = useState({});

    const handleSettingChange = (setting) => {
        onChange('settings', {
            ...state.settings,
            [setting]: !state.settings[setting]
        });
    };

    const SettingCard = ({ title, tooltipText, settingKey }) => (
        <Grid item xs={12} md={6} lg={4}>
            <MainCard
                content={true}
                boxShadow={true}
                border={false}
                sx={{
                    height: "100%",
                    bgcolor: getCardColor(lightMode, state.settings[settingKey])
                }}
            >
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h5">
                            {title}
                        </Typography>
                    </Box>
                    <LoadingSwitch
                        checked={state.settings[settingKey]}
                        onChange={() => handleSettingChange(settingKey)}
                        loading={loadingStates[settingKey]}
                        disabled={false}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                </Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Typography variant="body2">
                        {tooltipText}
                    </Typography>
                </Box>
            </MainCard>
        </Grid>
    );

    return (
        <Box sx={{ width: '100%', maxWidth: 1200, margin: '0 auto', p: 3 }}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <SettingCard
                    title="Course Retaking"
                    tooltipText="Allow users to retake a course if they scored below 80%. Enabling this will apply the settings to all existing courses, including those already assigned"
                    settingKey="courseRetaking"
                />
                <SettingCard
                    title="One-Click Courses"
                    tooltipText="When enabled, course invitation emails will include a link to take the course directly through email, circumventing the need for the user to login"
                    settingKey="oneClickCourses"
                />
                <SettingCard
                    title="Automatic Course Assignment"
                    tooltipText="Users are automatically assigned a course following the click of a link within a phishing simulation"
                    settingKey="automaticCourseAssignment"
                />
                <SettingCard
                    title="Reports"
                    tooltipText="Enable the use of Reports for Admins"
                    settingKey="reports"
                />
                <SettingCard
                    title="Anonymize Reports"
                    tooltipText="Anonymize all data within Reports. Effective immediately"
                    settingKey="anonymiseReports"
                />
                <SettingCard
                    title="On Demand"
                    tooltipText="Enable or disable on-demand features"
                    settingKey="onDemand"
                />
            </Grid>
        </Box>
    );
}