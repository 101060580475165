import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import authService from 'store/services/auth.service';
import { ThemeProvider, useThemeContext } from 'themes/ThemeContext';
import { useGetTenancyBasicsQuery } from 'store/services/tenancy.service';
import { isValidHexCode } from 'utils/colorUtils';
import { setIsHierarchyTenancy } from 'store/reducers/tenancy';


const AppContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { customColors, themeMode, updateThemeColors } = useThemeContext();
    const { tenancyId, isHierarchyTenancy } = useSelector((state) => state.tenancy);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasUpdatedColors, setHasUpdatedColors] = useState(false);
    const user = isAuthenticated ? JSON.parse(localStorage.getItem('user')) : null;


    // query for redux state tenancy
    const { data: tenancyBasics, isLoading } = useGetTenancyBasicsQuery(
        { tenancyId },
        { skip: !isAuthenticated || !tenancyId }
    );

    // User's original tenancy query - this is to validate their isHierachyTenancy value for the tenancy they belong to
    const { data: userTenancyBasics, isLoading: isLoadingUserTenancy } = useGetTenancyBasicsQuery(
        { tenancyId: user?.tenancyId },
        {
            skip: !isAuthenticated || !user?.tenancyId || user?.role === 'Administrators'
        }
    );

    const updateColors = useCallback(() => {
        if (tenancyBasics && !isLoading && !hasUpdatedColors) {
            const newColors = {
                primary: isValidHexCode(tenancyBasics.primaryHex) ? tenancyBasics.primaryHex : customColors.primary,
                secondary: isValidHexCode(tenancyBasics.secondaryHex) ? tenancyBasics.secondaryHex : customColors.secondary,
                warning: isValidHexCode(tenancyBasics.tertiaryHex) ? tenancyBasics.tertiaryHex : customColors.warning
            };
            updateThemeColors(newColors);
            setHasUpdatedColors(true);
        }
    }, [tenancyBasics, isLoading, hasUpdatedColors, updateThemeColors, customColors]);


    useEffect(() => {
        const checkAuth = () => {
            const loggedIn = authService.isLoggedIn();
            setIsAuthenticated(loggedIn);

            if (!loggedIn
                && (window.location.pathname !== '/login'
                    && window.location.pathname !== '/forgot-password'
                    && window.location.pathname !== '/login/reset-password'
                    && window.location.pathname.indexOf('/universitycourse/takecourse') === -1
                    && window.location.pathname.indexOf('/campaignclicked') === -1
                    && window.location.pathname.indexOf('/landingpage') === -1
                    && window.location.pathname.indexOf('/sso/') === -1
                    && window.location.pathname.indexOf('/uss-login') === -1
                    && window.location.pathname.indexOf('/cn-sso') === -1
                    && window.location.pathname.indexOf('/google-sso/') === -1)
                && window.location.pathname.indexOf('/aliaslogin')
            ) {
                navigate('/login');
            }

        };
        checkAuth();
    }, [navigate]);

    useEffect(() => {
        if (isAuthenticated && user && tenancyBasics && !isLoading) {
            if (user.role === 'Administrators' || !userTenancyBasics) {
                dispatch(setIsHierarchyTenancy(tenancyBasics.isHierarchyTenancy));
            } else if (userTenancyBasics) {
                dispatch(setIsHierarchyTenancy(userTenancyBasics.isHierarchyTenancy));
            }
        }
    }, [tenancyBasics, userTenancyBasics, isLoading, isAuthenticated, user]);


    useEffect(() => {
        updateColors();
    }, [updateColors]);

    return (
        <ThemeCustomization customColors={customColors} mode={themeMode}>
            <ScrollTop>
                <Routes />
            </ScrollTop>
        </ThemeCustomization>
    );
};

const App = () => {
    return (
        <ThemeProvider>
            <AppContent />
        </ThemeProvider>
    );
};

export default App;
