import { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, useMediaQuery, Typography } from "@mui/material";
import Search from "./Search";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { useTheme } from "@mui/material/styles";
import ThemeToggle from "../../../../components/ThemeToggle";
import { useSelector } from "react-redux";
import ProfileTab from "./Profile/ProfileTab";


const HeaderContent = ({ drawerOpen }) => {
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);
    const { isHierarchyTenancy } = useSelector((state) => state.tenancy);

    // Calculate the left position of the header watermark based on the drawer's state
    const watermarkLeftPosition = drawerOpen ? 25 : 100;

    return (
        <>
            {matchDownSM && (
                <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                    <ProfileTab open={true} />
                </Box>
            )}
            {!matchDownSM && (
                <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                    <Box sx={{ flexGrow: 1 }} />

                    {((user?.role === "Administrators" || (user?.role.includes("TenancyAdministrators") && isHierarchyTenancy)) && !window.location.pathname.startsWith("/provision/") )
                        
                        && (
                            <>
                                <Tooltip title="The tenancy selection dropbox shows the currently selected tenancy. Click the dropbox to show the list of all available tenancies">
                                    <IconButton>
                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                    </IconButton>
                                </Tooltip>
                                <Typography sx={{mr: 2}}>
                                    Currently viewing:
                                </Typography>

                                <Search />
                            </>
                        )}
                    <ThemeToggle />
                </Box>
            )}
        </>
    );
};

export default HeaderContent;
