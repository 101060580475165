import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Switch, { switchClasses } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledLoadingSwitch = styled(Switch)(({ theme }) => ({
    width: 80,
    height: 48,
    padding: 8,

    [`& .${switchClasses.switchBase}`]: {
        padding: 11,
        color: theme.palette.primary.main,
        '&.Mui-disabled': {
            opacity: theme.palette.action.disabledOpacity,
        },
    },
    [`& .${switchClasses.thumb}`]: {
        width: 26,
        height: 26,
        backgroundColor: theme.palette.background.paper,
    },
    [`& .${switchClasses.track}`]: {
        backgroundColor: theme.palette.grey[400],
        opacity: '1 !important',
        borderRadius: 20,
        position: 'relative',
        '&:before, &:after': {
            display: 'inline-block',
            position: 'absolute',
            top: '50%',
            width: '50%',
            transform: 'translateY(-50%)',
            textAlign: 'center',
            fontSize: '0.75rem',
            fontWeight: 500,
            color: theme.palette.text.primary,
        },
        '&:before': {
            content: '"ON"',
            left: 4,
            opacity: 0,
        },
        '&:after': {
            content: '"OFF"',
            right: 4,
        },
    },
    [`& .${switchClasses.checked}`]: {
        [`&.${switchClasses.switchBase}`]: {
            color: theme.palette.primary.dark,
            transform: 'translateX(32px)',
            '&:hover': {
                backgroundColor: `${theme.palette.primary.dark}14`,
            },
        },
        [`& .${switchClasses.thumb}`]: {
            backgroundColor: theme.palette.background.paper,
        },
        [`& + .${switchClasses.track}`]: {
            backgroundColor: theme.palette.primary.main,
            '&:before, &:after': {
                color: theme.palette.primary.contrastText,
            },
            '&:before': {
                opacity: 1,
            },
            '&:after': {
                opacity: 0,
            },
        },
    },
}));

const LoadingSwitch = ({ checked, onChange, disabled, loading, inputProps }) => {
    const handleChange = (event) => {
        event.preventDefault();
        if (!loading) {
            onChange();
        }
    };

    return (
        <Box
            sx={{
                width: 80,
                height: 48,
                position: 'relative',
            }}
        >
            <StyledLoadingSwitch
                checked={checked}
                onClick={handleChange}
                disabled={disabled || loading}
                inputProps={inputProps}
            />
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: checked ? '48.5px' : '28.5px',
                        height: 20,
                        width: 20,
                        transform: 'translate(0, -50%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pointerEvents: 'none',
                        transition: 'left 150ms cubic-bezier(0.4, 0, 0.2, 1)',
                    }}
                >
                    <CircularProgress size={14} color="inherit" />
                </Box>
            )}
        </Box>
    );
};

LoadingSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    inputProps: PropTypes.object,
};

export default LoadingSwitch;