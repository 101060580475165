// assets
import { ReactComponent as Home } from '../assets/navigation-icons/home.svg';
import { ReactComponent as Users } from '../assets/navigation-icons/users.svg';
import { ReactComponent as Tenancy } from '../assets/navigation-icons/tenancy.svg';
import { ReactComponent as Journey } from '../assets/navigation-icons/journey.svg';
import { ReactComponent as Simulation } from '../assets/navigation-icons/simulation.svg';
import { ReactComponent as Course } from '../assets/navigation-icons/course.svg';
import { ReactComponent as OnDemand } from '../assets/navigation-icons/on-demand.svg';
import { ReactComponent as Report } from '../assets/navigation-icons/report.svg';
import { ReactComponent as Help } from '../assets/navigation-icons/help.svg';

// icons
const icons = {
    Home,
    Users,
    Tenancy,
    Journey,
    Simulation,
    Course,
    OnDemand,
    Report,
    Help
};

// ==============================|| MENU ITEMS - ADMIN PAGES ||============================== //

const adminItems = {
    id: 'users',
    type: 'group',
    title: 'Admin',
    divider: false,
    children: [
        {
            id: 'journey1',
            title: 'Journeys',
            type: 'item',
            url: '/journeys',
            icon: icons.Journey,
        },
        {
            id: 'simulation1',
            title: 'Simulations',
            type: 'item',
            url: '/simulations',
            icon: icons.Simulation,
        },
        {
            id: 'courses1',
            title: 'Courses',
            type: 'item',
            url: '/courses',
            icon: icons.Course,
        },

        /*{
            id: 'adminLogs1',
            title: 'Admin Logs',
            type: 'item',
            url: '/admin-logs',
            icon: icons.Report,
        },*/
    ]
};

export default adminItems;
