import React, { useState, useCallback } from "react";
import {
    Box, Button, TextField, Typography, Slider, FormControlLabel,
    Switch
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { getCardColor } from "../../utils/colorUtils";
import { useTheme } from "@mui/material/styles";
import ColorPicker from "../../components/ColorPicker";
import { APP_URL } from "../../config";
import { PRESET_LANDING_PAGE } from "../../themes/PresetLandingPage";
import BackgroundImageUploader from './BackgroundImageUploader';

/**
 * Component for editing the style of the landing page
 */
const StyleEditor = ({
                         onBack,
                         formData,
                         setFormData,
                         tenancyBasics,
                         onColorChange,
                         onLogoSizeChange,
                         onLogoVisibilityChange,
                         onLogoBackgroundChange,
                         onTemplateChange,
                         initialColors,
                         initialLogoSize,
                         initialShowLogo,
                         initialShowLogoBackground,
                         isDisabled,
                     }) => {
    const theme = useTheme();
    const [logoSize, setLogoSize] = useState(initialLogoSize);
    const [colors, setColors] = useState(initialColors);
    const [showLogo, setShowLogo] = useState(initialShowLogo);
    const [showLogoBackground, setShowLogoBackground] = useState(initialShowLogoBackground);
    const [uploading, setUploading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleColorChange = (type, color) => {
        const newColors = { ...colors, [type]: color };
        setColors(newColors);
        onColorChange(newColors);
    };

    const handleBackgroundImageChange = (url) => {
        setFormData(prev => ({
            ...prev,
            backgroundImage: url,
            isBackgroundImageLocal: false
        }));
    };

    const handleLogoSizeChange = useCallback(
        (event) => {
            const newSize = parseInt(event.target.value);
            setLogoSize(newSize);
            onLogoSizeChange(newSize);
        },
        [onLogoSizeChange]
    );

    const handleLogoVisibilityChange = useCallback(
        (event) => {
            const visible = event.target.checked;
            setShowLogo(visible);
            onLogoVisibilityChange(visible);
        },
        [onLogoVisibilityChange]
    );

    const handleLogoBackgroundChange = useCallback(
        (event) => {
            const visible = event.target.checked;
            setShowLogoBackground(visible);
            onLogoBackgroundChange(visible);
        },
        [onLogoBackgroundChange]
    );


    return (
        <MainCard
            content={true}
            boxShadow={true}
            border={false}
            sx={{
                bgcolor: getCardColor(theme.palette.mode === "light"),
                display: "flex",
                flexDirection: "column",
                mb: 2,
                ml: 2,
            }}
        >
            <Box sx={{ mt: 2 }}>
                <Typography
                    variant="h6"
                    sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                >
                    Background Image
                </Typography>
                <BackgroundImageUploader
                    backgroundImage={formData.backgroundImage}
                    onBackgroundImageChange={handleBackgroundImageChange}
                    disabled={isDisabled || uploading}
                />
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography
                    variant="h6"
                    sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                >
                    Logo Settings
                </Typography>

                {/* Logo Visibility Toggle */}
                <Box sx={{ mb: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showLogo}
                                onChange={handleLogoVisibilityChange}
                                disabled={isDisabled}
                            />
                        }
                        label="Show Logo"
                    />
                </Box>

                {/* Uncommented Logo Background Toggle */}
                <FormControlLabel
                    control={
                        <Switch
                            checked={showLogoBackground}
                            onChange={handleLogoBackgroundChange}
                            name="showLogoBackground"
                            disabled={isDisabled || !showLogo}
                        />
                    }
                    label="Show Logo Background"
                    sx={{ mb: 2 }}
                />

                {showLogo && (
                    <Box sx={{ mb: 2 }}>
                        <Typography id="logo-size-slider" gutterBottom>
                            Logo Size: {logoSize}px
                        </Typography>
                        <Slider
                            value={logoSize}
                            onChange={(e, newValue) => {
                                setLogoSize(newValue);
                                onLogoSizeChange(newValue);
                            }}
                            aria-labelledby="logo-size-slider"
                            valueLabelDisplay="auto"
                            min={40}
                            max={200}
                            sx={{
                                color: theme.palette.primary.main,
                            }}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {showLogo && tenancyBasics?.logo ? (
                        <>
                            <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                                <img
                                    src={`${APP_URL}/${tenancyBasics.logo}`}
                                    alt="Logo"
                                    width={logoSize}
                                    style={{
                                        objectFit: "contain",
                                        backgroundColor: showLogoBackground ? colors.primary : 'transparent',
                                        padding: showLogoBackground ? '10px 20px' : '0',
                                        borderRadius: '8px'
                                    }}
                                />
                            </Box>
                            <TextField
                                label="Logo Width (px)"
                                type="number"
                                value={logoSize}
                                onChange={handleLogoSizeChange}
                                fullWidth
                                InputProps={{
                                    inputProps: { min: 0 },
                                }}
                                disabled={isDisabled || !showLogo}
                            />
                        </>
                    ) : showLogo ? (
                        <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                            <Typography color="text.secondary">No Logo Selected</Typography>
                        </Box>
                    ) : null}
                </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography
                    variant="h6"
                    sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                >
                    Color Settings
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                        flexWrap: "wrap",
                        mb: 2,
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Typography variant="body2" sx={{ mb: 1 }}>Primary</Typography>
                        <ColorPicker
                            color={colors.primary}
                            onChange={(color) => !isDisabled && handleColorChange("primary", color)}
                            disabled={isDisabled}
                        />
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 2,
                    mb: 2,
                }}
            >
                <Button
                    variant="contained"
                    fullWidth
                    onClick={onBack}
                >
                    Back to Main Editor
                </Button>
            </Box>
        </MainCard>
    );
};

export default StyleEditor;