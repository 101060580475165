import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { APP_URL, CN_LOGIN } from "../../config";
import { logout } from "../../store/reducers/auth";
import { dispatch } from "../../store";
import { Box, Grid } from "@mui/material";
import LogoSection from "../../components/Logo";

const UssLogin = () => {
    const [searchParams] = useSearchParams();


    useEffect(() => {

        const handleSsoCallback = async () => {
            // Original UssLogin logic
            const handleLogin = () => {
                const domain = searchParams.get("domain");
                const errorReason = searchParams.get("errorReason");
                var cnLoginPage = domain + "/#oidc/authorise";
                if (domain === null || !domain.includes("clouduss.com")) {
                    cnLoginPage = CN_LOGIN;
                }
                const cryptoString1 = nanoid();
                const cryptoString2 = nanoid();

                sessionStorage.setItem("State", cryptoString1);
                sessionStorage.setItem("Nonce", cryptoString2);
                dispatch(logout())
                    .unwrap()
                    .then(() => {
                        window.location.href = `${cnLoginPage}?response_type=id_token&scope=openid profile&client_id=184e5135-cc9f-45d5-8976-2bf0efc00772&redirect_uri=${encodeURIComponent(`${APP_URL}/cn-sso?`)}&state=${cryptoString1}&nonce=${cryptoString2}`;
                    }).catch((err) => {
                    console.log(err);
                    window.location.href = `${cnLoginPage}?response_type=id_token&scope=openid profile&client_id=184e5135-cc9f-45d5-8976-2bf0efc00772&redirect_uri=${encodeURIComponent(`${APP_URL}/cn-sso?`)}&state=${cryptoString1}&nonce=${cryptoString2}`;
                });
            };

            handleLogin();
        };

        handleSsoCallback();
    }, [searchParams]);

    return (
        <Box sx={{ minHeight: "100vh", bgcolor: "#FFFFFF" }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    minHeight: "100vh"
                }}
            >
                <div className="flex items-center justify-center min-h-screen">
                    <Box sx={{ minHeight: "100vh", bgcolor: "#FFFFFF" }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                minHeight: "100vh"
                            }}
                        >
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                                <LogoSection />
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                                <div className="flex items-center justify-center min-h-screen">
                                    <div className="text-center">
                                        <h1 className="text-xl mb-4">Authenticating...</h1>
                                        <div
                                            className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full mx-auto"></div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Grid>
        </Box>
    );
};

export default UssLogin;
