import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tab,
    Tabs,
    Typography,
    CircularProgress
} from '@mui/material';
import { EMAIL_TAB_CONFIG } from "../utils/EmailTabsConfig"
import CloseIcon from '@mui/icons-material/Close';
import ScrollableTabs from "../components/ScrollableTabs";

const EmailPreviewModal = ({
    open,
    onClose,
    previewContent,
    tenancyData,
    universitySettings,
}) => {
    const [activeTabId, setActiveTabId] = useState(null);
    const [currentPreview, setCurrentPreview] = useState('');
    const [visibleTabs, setVisibleTabs] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);

    // Initialize tabs and active tab id when data is available
    useEffect(() => {
        if (tenancyData && universitySettings && open) {
            const newVisibleTabs = [];

            if (universitySettings.allowUnauthorisedTakeCourse) {
                newVisibleTabs.push('oneClick');
            } else {
                if (tenancyData.automaticCourseAssignmentDays !== "-1" && tenancyData.automaticCourseAssignmentDays !== null) {
                    newVisibleTabs.push('autoAssign');
                }
                if (tenancyData.contentPackId) {
                    newVisibleTabs.push('contentPacks');
                } 
                newVisibleTabs.push('reminders');
                newVisibleTabs.push('invites');
            }

            setVisibleTabs(newVisibleTabs);

            // Set the first visible tab as active if we don't have an active one yet
            // or if the current active tab is not in visible tabs
            if (newVisibleTabs.length > 0 && (!activeTabId || !newVisibleTabs.includes(activeTabId))) {
                setActiveTabId(newVisibleTabs[0]);
                console.log("newVisibleTAbs", newVisibleTabs);
            }

            setIsInitialized(true);
        }
    }, [tenancyData, universitySettings, open, activeTabId]);

    const filteredTabs = EMAIL_TAB_CONFIG.filter(tab => visibleTabs.includes(tab.id));

    const visibleTabIndex = filteredTabs.findIndex(tab => tab.id === activeTabId);

    const getCurrentMessage = useCallback(() => {
        if (!universitySettings || !activeTabId) return '';

        switch (activeTabId) {
            case 'invites':
                return universitySettings.inviteMessage || '';
            case 'autoAssign':
                return universitySettings.autoAssignMessage || '';
            case 'reminders':
                return universitySettings.reminderMessage || '';
            case 'oneClick':
                return universitySettings.oneClickMessage || '';
            case 'contentPacks':
                return universitySettings.contentPackMessage || '';
            default:
                return '';
        }
    }, [activeTabId, universitySettings]);

    const getActiveSubject = useCallback(() => {
        if (!universitySettings || !activeTabId) return '';

        switch (activeTabId) {
            case 'invites':
                return universitySettings.inviteEmailSubject || '';
            case 'autoAssign':
                return universitySettings.autoAssignEmailSubject || '';
            case 'reminders':
                return universitySettings.reminderEmailSubject || '';
            case 'oneClick':
                return universitySettings.oneClickEmailSubject || '';
            case 'contentPacks':
                return universitySettings.contentPackEmailSubject || '';
            default:
                return '';
        }
    }, [activeTabId, universitySettings]);

    useEffect(() => {
        if (previewContent && open && isInitialized && activeTabId) {
            let processedTemplate = previewContent;
            const message = getCurrentMessage();

            processedTemplate = processedTemplate
                .replace('{mainText}', message || '')
                .replace(/\{button\.text\}/g, universitySettings?.courseButtonText || '');

            if (tenancyData?.logo) {
                processedTemplate = processedTemplate.replace(
                    /{client\.logo}/g,
                    `${window.location.origin}/${tenancyData.logo}`
                );
            }

            processedTemplate = `
                <html>
                <head>
                    <style>
                        body, html {
                            margin: 0;
                            padding: 0;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .email-container {
                            max-width: 100%;
                        }
                    </style>
                </head>
                <body>
                    <div class="email-container">
                        ${processedTemplate}
                    </div>
                </body>
                </html>
            `;

            setCurrentPreview(processedTemplate);
        }
    }, [previewContent, activeTabId, open, getCurrentMessage, tenancyData, universitySettings, isInitialized]);

    const handleTabChange = (_, newIndex) => {
        if (newIndex >= 0 && newIndex < filteredTabs.length) {
            setActiveTabId(filteredTabs[newIndex].id);
        }
    };

    if (!isInitialized || !tenancyData || !universitySettings) {
        return (
            <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", p: 4 }}>
                    <CircularProgress size={40} />
                    <Typography sx={{ mt: 2 }}>Loading preview...</Typography>
                </Box>
            </Dialog>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: {
                    height: '90vh',
                    maxHeight: '90vh'
                }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid',
                borderColor: 'divider'
            }}>
                <Typography variant="h4">Email Preview</Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 3 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                    <ScrollableTabs
                        value={visibleTabIndex >= 0 ? visibleTabIndex : 0}
                        onChange={handleTabChange}
                        ariaLabel="email template tabs"
                    >
                        {filteredTabs.map((tab) => (
                            <Tab key={tab.id} label={tab.label} />
                        ))}
                    </ScrollableTabs>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Display Name: {universitySettings?.displayName}
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Subject: {getActiveSubject()}
                    </Typography>
                </Box>

                <iframe
                    srcDoc={currentPreview}
                    style={{
                        border: 'none',
                        width: '100%',
                        height: 'calc(90vh - 250px)',
                        backgroundColor: 'white'
                    }}
                    title="Email Preview"
                    sandbox="allow-same-origin allow-scripts"
                    onLoad={(e) => {
                        //prevent navigation in the iframe
                        const frame = e.target;
                        const frameDoc = frame.contentDocument || frame.contentWindow.document;

                        // Disable all links
                        const links = frameDoc.querySelectorAll('a');
                        links.forEach(link => {
                            link.onclick = (e) => {
                                e.preventDefault();
                                return false;
                            };
                        });

                        // Disable all buttons and input submits
                        const buttons = frameDoc.querySelectorAll('button, input[type="submit"], input[type="button"]');
                        buttons.forEach(button => {
                            button.onclick = (e) => {
                                e.preventDefault();
                                return false;
                            };
                        });
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default EmailPreviewModal;