import React, { useState } from 'react';
import { Tabs, Tab, Box, Grid, Typography, Button } from '@mui/material';
import MainCard from "../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import { getCardColor } from "../../utils/colorUtils";
import { BRAND_TYPE } from "../../config";
import ThemeSettings from "./ThemeSettings";
import EmailSettings from "./EmailSettings";
import LandingPageSettings from "./LandingPageSettings";

 


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`reports-tabpanel-${index}`}
            aria-labelledby={`reports-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `reports-tab-${index}`,
        'aria-controls': `reports-tabpanel-${index}`,
    };
}



export default function Branding({ data }) {
    const [subTabValue, setSubTabValue] = useState(0);
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const handleSubTabChange = (event, newValue) => {
        setSubTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={subTabValue} onChange={handleSubTabChange} aria-label="reports tabs">
                    <Tab label="Theme" {...a11yProps(0)} />
                    <Tab label="Emails" {...a11yProps(1)} />
                    <Tab label="Landing Page" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={subTabValue} index={0}>
                <ThemeSettings data={data} />
            </TabPanel>
            <TabPanel value={subTabValue} index={1}>
                <EmailSettings data={data} />
            </TabPanel>
            <TabPanel value={subTabValue} index={2}>
                <LandingPageSettings data={data} />
            </TabPanel>
        </Box>
    );
}