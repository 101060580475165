import React, { useState, useRef } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Typography,
    Paper,
    IconButton,
    Grid,
    Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { useSelector } from 'react-redux';
import { useUploadBackgroundImageMutation } from '../../store/services/simulation-service';
import {useGetTenancyByIdQuery} from "../../store/services/tenancy.service";


const BackgroundImageUploader = ({
                                     backgroundImage,
                                     onBackgroundImageChange,
                                     disabled = false
                                 }) => {
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);
    const { tenancyId } = useSelector((state) => state.tenancy);
    const { tenancyName } = useSelector((state) => state.tenancy);
    const [uploadBackgroundImage] = useUploadBackgroundImageMutation();
    const [preview, setPreview] = useState(backgroundImage || '');
    const { data: tenancyData, isLoading: tenancyLoading } =
        useGetTenancyByIdQuery({ tenancyId });

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Reset states
        setError('');
        setUploading(true);

        // Validate file type
        const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];
        if (!validTypes.includes(file.type)) {
            setError('Invalid file type. Please upload a JPG, PNG, GIF, WebP, or SVG image.');
            setUploading(false);
            return;
        }

        // Validate file size (5MB max)
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        if (file.size > maxSize) {
            setError('File is too large. Maximum size is 5MB.');
            setUploading(false);
            return;
        }

        try {
            // Read the file as base64
            const reader = new FileReader();
            reader.onload = async (e) => {
                const base64Image = e.target.result;

                // Create temporary preview
                setPreview(base64Image);

                try {
                    console.log('Uploading image to server...');

                    // Upload to server using RTK Query mutation
                    const result = await uploadBackgroundImage({
                        base64Image: base64Image,
                        tenancyName: tenancyData.name
                    }).unwrap();

                    console.log('Upload response:', result, tenancyName);

                    if (!result.success) {
                        throw new Error('Failed to upload image: ' + JSON.stringify(result));
                    }

                    // Return the URL to the parent component
                    onBackgroundImageChange(result.url);
                    setUploading(false);
                } catch (uploadError) {
                    console.error('Error uploading image:', uploadError);
                    setError('Failed to upload image. Please try again.');
                    setUploading(false);
                }
            };

            reader.onerror = () => {
                setError('Error reading file. Please try again.');
                setUploading(false);
            };

            reader.readAsDataURL(file);
        } catch (e) {
            console.error('Error processing file:', e);
            setError('Error processing file. Please try again.');
            setUploading(false);
        }
    };

    const handleClearImage = () => {
        setPreview('');
        onBackgroundImageChange('');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        disabled={disabled || uploading}
                    />
                    <Button
                        variant="contained"
                        onClick={handleButtonClick}
                        disabled={disabled || uploading}
                        startIcon={uploading ? <CircularProgress size={20} /> : <UploadIcon />}
                    >
                        {uploading ? 'Uploading...' : preview ? 'Change Background Image' : 'Upload Background Image'}
                    </Button>
                </Grid>

                {preview && (
                    <Grid item>
                        <Tooltip title="Remove Image">
                            <IconButton
                                onClick={handleClearImage}
                                disabled={disabled || uploading}
                                color="error"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>

            {error && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    {error}
                </Typography>
            )}

            {preview && (
                <Paper
                    elevation={2}
                    sx={{
                        mt: 2,
                        p: 1,
                        height: '150px',
                        backgroundImage: `url(${preview})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: 1
                    }}
                />
            )}
        </Box>
    );
};

export default BackgroundImageUploader;