import { useSelector } from "react-redux";
import tenancyAdminItems from './tenancyAdminItems';
import adminItems from './adminItems';
import userItems from "./userItems";

const useMenuItems = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const { role } = user;
    const { isHierarchyTenancy } = useSelector((state) => state.tenancy);
    const items = [userItems];

    const filterMenuItems = (menuItems, userRole) => {
        return {
            ...menuItems,
            children: menuItems.children.filter(item => {
                if (item.id === 'reports1') {
                    if (userRole.includes("TenancyAdministrators")) return user.enableReporting;
                    else return user.reportingLevel !== null && user.enableReporting;
                }
                if (item.id === 'ondemand1') {
                    if (userRole.includes("TenancyAdministrators")) return user.enableOndemand;
                    else return user.onDemandLevel !== null && user.enableOndemand;
                }
                if (item.id === 'tenants1') {
                    if (userRole.includes("PartnerAdministrators")) return true;
                    else if (userRole.includes("TenancyAdministrators")) return isHierarchyTenancy;
                }
                if (userRole.includes("TenancyAdministrators")) return true;
            })
        };
    };

    if (role === 'Administrators') {
        items.push(tenancyAdminItems, adminItems);
    } else if (role.includes('TenancyAdministrators')) {
        items.push(filterMenuItems(tenancyAdminItems, role));
    } else if (role === '') {
        items.push(filterMenuItems(tenancyAdminItems, role));
    }

    return { items };
};

export default useMenuItems;