import React, { useState, useEffect, useCallback, useRef } from "react";
import { debounce } from "lodash";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
    Tabs,
    Tab,
    Autocomplete,
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { getCardColor } from "../../utils/colorUtils";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ColorPicker from "../../components/ColorPicker";
import {
    useSaveUniversityCourseSettingsMutation,
    useGetUniversityCourseSettingsQuery,
} from "../../store/services/university-service";
import { useSelector } from "react-redux";
import { APP_URL } from "../../config";
import { useGetTenancyBasicsQuery, useGetTenancyByIdQuery } from "../../store/services/tenancy.service";
import { PRESET_TEMPLATES } from "../../themes/PresetTemplates";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import ScrollableTabs from "../../components/ScrollableTabs";
import { EMAIL_TAB_CONFIG } from "../../utils/EmailTabsConfig"

const ALLOWED_HTML_TAGS = ["p", "br", "strong"];

const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const clean = Array.from(doc.body.children).filter((el) =>
        ALLOWED_HTML_TAGS.includes(el.tagName.toLowerCase())
    );
    return clean.map((el) => el.outerHTML).join("");
};

const EmailPreview = React.memo(
    ({
         templateContent,
         messageToUse,
         buttonText,
         logoSize,
         colors,
         tenancyBasics,
         tenancyData,
     }) => {
        const [previewContent, setPreviewContent] = useState("");

        const tenancyDataRef = useRef(tenancyData);

        useEffect(() => {
            tenancyDataRef.current = tenancyData;
        }, [tenancyData]);

        const updatePreview = useCallback(
            debounce((template, data) => {
                const currentTenancyData = tenancyDataRef.current;

                if (template && data) {
                    const messageText = data.messageToUse || "";
                    const btnText = data.buttonText || "";
                    const logoSizeVal = data.logoSize || 120;
                    const colorValues = {
                        primary: data.colors?.primary || "#000000",
                        secondary: data.colors?.secondary || "#555555",
                        tertiary: data.colors?.tertiary || "#888888"
                    };

                    let preview = template
                        .replace("{mainText}", messageText)
                        .replace(/\{button\.text\}/g, btnText)
                        .replace(/\{logo\.size\}/g, `${logoSizeVal}`)
                        .replace(/\{color\.primary\}/g, colorValues.primary)
                        .replace(/\{color\.secondary\}/g, colorValues.secondary)
                        .replace(/\{color\.tertiary\}/g, colorValues.tertiary);

                    if (currentTenancyData?.logo) {
                        preview = preview.replace(
                            /{client\.logo}/g,
                            `${APP_URL}/${currentTenancyData.logo}`
                        );
                    } else {
                        preview = preview.replace(
                            /{client\.logo}/g,
                            `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${logoSizeVal}' height='${logoSizeVal}' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Ctext x='50' y='50' font-family='Arial' font-size='14' text-anchor='middle' dominant-baseline='middle' fill='%23999'%3ENo Logo%3C/text%3E%3C/svg%3E`
                        );
                    }

                    // flexbox centering 
                    preview = `
                <html>
                <head>
                    <style>
                        body, html {
                            margin: 0;
                            padding: 0;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .email-container {
                            max-width: 100%;
                        }
                    </style>
                </head>
                <body>
                    <div class="email-container">
                        ${preview}
                    </div>
                </body>
                </html>
            `;

                    setPreviewContent(preview);
                } else {
                    setPreviewContent(`
                        <html>
                        <head>
                            <style>
                                body, html {
                                    margin: 0;
                                    padding: 0;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-family: Arial, sans-serif;
                                }
                            </style>
                        </head>
                        <body>
                            <div style="text-align: center; padding: 20px;">
                                <p>Email template preview will appear here once data is loaded.</p>
                            </div>
                        </body>
                        </html>
                    `);
                }
            }, 100),
            []
        );

        useEffect(() => {
            updatePreview(templateContent, {
                messageToUse,
                buttonText,
                logoSize,
                colors,
                tenancyBasics,
            });

            return () => {
                updatePreview.cancel();
            };
        }, [
            templateContent,
            messageToUse,
            buttonText,
            logoSize,
            colors,
            tenancyBasics,
            updatePreview,
        ]);

        return (
            <iframe
                srcDoc={previewContent}
                style={{
                    border: 'none',
                    width: '100%',
                    height: 'calc(90vh - 250px)',
                    backgroundColor: 'white'
                }}
                title="Email Preview"
                sandbox="allow-same-origin allow-scripts"
                onLoad={(e) => {
                    //prevent navigation in the iframe
                    const frame = e.target;
                    const frameDoc = frame.contentDocument || frame.contentWindow.document;

                    // Disable all links by preventing their default action
                    const links = frameDoc.querySelectorAll('a');
                    links.forEach(link => {
                        link.onclick = (e) => {
                            e.preventDefault();
                            return false;
                        };
                    });

                    // Disable all buttons and input submits
                    const buttons = frameDoc.querySelectorAll('button, input[type="submit"], input[type="button"]');
                    buttons.forEach(button => {
                        button.onclick = (e) => {
                            e.preventDefault();
                            return false;
                        };
                    });
                }}
            />
        );
    }
);

function StyleEditor({
                         onBack,
                         formData,
                         handleInputChange,
                         tenancyBasics,
                         onTemplateChange,
                         onColorChange,
                         onLogoSizeChange,
                         initialColors,
                         initialLogoSize,
                         isDisabled,
                     }) {
    const theme = useTheme();
    const [logoSize, setLogoSize] = useState(initialLogoSize);
    const [emailColors, setEmailColors] = useState(initialColors);
    const [currentTemplate, setCurrentTemplate] = useState(formData.courseBodyText);

    const handleEmailColorChange = (type, color) => {
        const newColors = { ...emailColors, [type]: color };
        setEmailColors(newColors);
        onColorChange(newColors);
    };

    const handleLogoSizeChange = useCallback(
        (event) => {
            const newSize = parseInt(event.target.value);
            setLogoSize(newSize);
            onLogoSizeChange(newSize);
        },
        [onLogoSizeChange]
    );

    const loadTemplate = useCallback(
        (template) => {
            if (template) {
                setCurrentTemplate(template);
                onTemplateChange(template);
            }
        },
        [onTemplateChange]
    );

    useEffect(() => {
        if (formData.courseBodyText !== currentTemplate) {
            setCurrentTemplate(formData.courseBodyText);
        }
    }, [formData.courseBodyText, currentTemplate]);

    return (
        <MainCard
            content={true}
            boxShadow={true}
            border={false}
            sx={{
                bgcolor: getCardColor(theme.palette.mode === "light"),
                display: "flex",
                flexDirection: "column",
                mb: 2,
                ml: 2,
            }}
        >

            <Box sx={{ mt: 2 }}>
                <Typography
                    variant="h6"
                    sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                >
                    Templates
                </Typography>
                <Autocomplete
                    options={PRESET_TEMPLATES}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Template" fullWidth />
                    )}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            loadTemplate(newValue.template);
                        }
                    }}
                    sx={{ mb: 2 }}
                />
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography
                    variant="h6"
                    sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                >
                    Logo Settings
                </Typography>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {tenancyBasics?.logo ? (
                        <>
                            <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                                <img
                                    src={`${APP_URL}/${tenancyBasics.logo}`}
                                    alt="Logo"
                                    width={logoSize}
                                    style={{ objectFit: "contain" }}
                                />
                            </Box>
                            <TextField
                                label="Logo Width (px)"
                                type="number"
                                value={logoSize}
                                onChange={handleLogoSizeChange}
                                fullWidth
                                InputProps={{
                                    inputProps: { min: 0 },
                                }}
                            />
                        </>
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                            <Typography color="text.secondary">No Logo Selected</Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography
                    variant="h6"
                    sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                >
                    Email Color
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                        flexWrap: "wrap",
                    }}
                >
                    <ColorPicker
                        color={emailColors.primary}
                        onChange={(color) => handleEmailColorChange("primary", color)}
                        disabled={false}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 2,
                    mb: 2,
                }}
            >
                <Button
                    variant="contained"
                    fullWidth
                    onClick={onBack}
                >
                    Back to Text Editor
                </Button>
            </Box>

        </MainCard>
    );
}

function CustomEmailTemplate() {
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [activeTab, setActiveTab] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [showStyleEditor, setShowStyleEditor] = useState(false);
    const [visibleTabs, setVisibleTabs] = useState(EMAIL_TAB_CONFIG.map(tab => tab.id));
    const [isUsingDefaultTemplate, setIsUsingDefaultTemplate] = useState(false);
    const [showingDefaultAsOutline, setShowingDefaultAsOutline] = useState(false);

    const { data: tenancyData, isLoading: tenancyLoading, error: tenancyError } = useGetTenancyByIdQuery({ tenancyId });

    const [globalEmailColors, setGlobalEmailColors] = useState(
        JSON.parse(localStorage.getItem("emailColors")) || {
            primary: theme.palette.primary.main,
            secondary: theme.palette.secondary.main,
            tertiary: theme.palette.warning.main,
        }
    );
    const [globalLogoSize, setGlobalLogoSize] = useState(
        parseInt(localStorage.getItem("logoSize")) || 120
    );

    const { data: tenancyBasics, isLoading: tenancyBasicsLoading } =
        useGetTenancyBasicsQuery({ tenancyId });

    const { data: settingsData, isLoading: settingsLoading } = useGetUniversityCourseSettingsQuery(
        { tenancyId },
        { skip: !tenancyId }
    );

    // Add query for default template settings with tenancyId 0
    const { data: defaultTemplateSettings, isLoading: defaultTemplateLoading } =
        useGetUniversityCourseSettingsQuery(
            { tenancyId: 0 },
            { skip: !tenancyId }
        );

    const mapVisibleIndexToActualIndex = (visibleIndex) => {
        return filteredTabs[visibleIndex] ?
            EMAIL_TAB_CONFIG.findIndex(tab => tab.id === filteredTabs[visibleIndex].id) :
            0;
    };

    const [saveSettings] = useSaveUniversityCourseSettingsMutation();

    const [formData, setFormData] = useState({
        tenancyId: tenancyId,
        displayName: " ",
        courseButtonText: " ",
        courseButtonStyle: " ",
        courseBodyText: " ",
        inviteMessage: " ",
        inviteEmailSubject: " ",
        autoAssignMessage: " ",
        autoAssignEmailSubject: " ",
        reminderMessage: " ",
        reminderEmailSubject: " ",
        oneClickMessage: " ",
        oneClickEmailSubject: " ",
        contentPackMessage: " ",
        contentPackEmailSubject: " ",
        useDefaultTemplate: false,
    });

    // Function to check if this is the first time creating a custom template
    const isCreatingNewCustomTemplate = () => {
        if (!settingsData) return false;

        // Check if the current settings data has meaningful content or just placeholders
        const hasEmptyCustomTemplate =
            (!settingsData.courseBodyText || settingsData.courseBodyText.trim() === " ") &&
            (!settingsData.inviteMessage || settingsData.inviteMessage.trim() === " ") &&
            (!settingsData.reminderMessage || settingsData.reminderMessage.trim() === " ");

        // User is creating a new custom template if:
        // 1. They are not using the default template (useDefaultTemplate is false)
        // 2. The custom template fields are empty/default
        return !settingsData.useDefaultTemplate && hasEmptyCustomTemplate;
    };

    const getCurrentMessage = () => {
        const actualIndex = mapVisibleIndexToActualIndex(activeTab);
        switch (actualIndex) {
            case 0:
                return formData.inviteMessage;
            case 1:
                return formData.autoAssignMessage;
            case 2:
                return formData.reminderMessage;
            case 3:
                return formData.oneClickMessage;
            case 4:
                return formData.contentPackMessage;
            default:
                return "";
        }
    };

    const handleTemplateChange = useCallback((templateContent) => {
        setFormData((prev) => ({
            ...prev,
            courseBodyText: templateContent,
            content: templateContent,
        }));
    }, []);

    const handleGlobalColorChange = useCallback((colors) => {
        setGlobalEmailColors(colors);
        localStorage.setItem("emailColors", JSON.stringify(colors));
    }, []);

    const handleGlobalLogoSizeChange = useCallback((size) => {
        setGlobalLogoSize(size);
        localStorage.setItem("logoSize", size.toString());
    }, []);

    useEffect(() => {
        if (tenancyData && settingsData && defaultTemplateSettings) {
            // Check if using default template per settings
            const usingDefault = settingsData.useDefaultTemplate;
            setIsUsingDefaultTemplate(usingDefault);

            const creating = isCreatingNewCustomTemplate();
            setShowingDefaultAsOutline(creating);


            const initialEmailColors = settingsData.emailColors || {
                primary: theme.palette.primary.main,
                secondary: theme.palette.secondary.main,
                tertiary: theme.palette.warning.main,
            };

            setGlobalEmailColors(initialEmailColors);
            localStorage.setItem("emailColors", JSON.stringify(initialEmailColors));

            const savedLogoSize = settingsData.logoSize
                ? parseInt(settingsData.logoSize)
                : 120;
            setGlobalLogoSize(savedLogoSize);
            localStorage.setItem("logoSize", savedLogoSize.toString());

            const templateToUse = usingDefault ?
                defaultTemplateSettings :
                (creating ? defaultTemplateSettings : settingsData);

            setFormData({
                ...settingsData,
                tenancyId: settingsData.tenancyId,
                useDefaultTemplate: usingDefault,
                courseBodyText: templateToUse.courseBodyText || " ",
                logoSize: savedLogoSize,
                displayName: templateToUse.displayName || " ",
                inviteMessage: templateToUse.inviteMessage || " ",
                autoAssignMessage: templateToUse.autoAssignMessage || " ",
                reminderMessage: templateToUse.reminderMessage || " ",
                contentPackMessage: templateToUse.contentPackMessage || " ",
                oneClickMessage: templateToUse.oneClickMessage || " ",
                inviteEmailSubject: templateToUse.inviteEmailSubject || " ",
                autoAssignEmailSubject: templateToUse.autoAssignEmailSubject || " ",
                reminderEmailSubject: templateToUse.reminderEmailSubject || " ",
                oneClickEmailSubject: templateToUse.oneClickEmailSubject || " ",
                contentPackEmailSubject: templateToUse.contentPackEmailSubject || " ",
                courseButtonText: templateToUse.courseButtonText || " ",
            });


            const newVisibleTabs = [];
            if (settingsData.allowUnauthorisedTakeCourse) {
                newVisibleTabs.push('oneClick');
            } else {
                if (tenancyData.automaticCourseAssignmentDays !== "-1" && tenancyData.automaticCourseAssignmentDays !== null) {
                    newVisibleTabs.push('autoAssign');
                }
                if (tenancyData.contentPackId) {
                    newVisibleTabs.push('contentPacks');
                }
                newVisibleTabs.push('reminders');
                newVisibleTabs.push('invites');
            }
            setVisibleTabs(newVisibleTabs);
        }
    }, [
        settingsData,
        tenancyData,
        defaultTemplateSettings,
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.warning.main,
    ]);

    const filteredTabs = EMAIL_TAB_CONFIG.filter(tab => visibleTabs.includes(tab.id));

    const handleInputChange = (field) => (event) => {
        const newValue = event.target.value;
        setFormData((prev) => ({
            ...prev,
            [field]: newValue,
        }));
    };

    const handleInsertText = (type) => {
        const insertField =
            type === "user"
                ? "{firstName}"
                : type === "course"
                    ? "{courseName}"
                    : type === "p"
                        ? "<p></p>"
                        : type === "br"
                            ? "<br>"
                            : type === "strong"
                                ? "<strong></strong>"
                                : "";

        const actualIndex = mapVisibleIndexToActualIndex(activeTab);

        const currentTabId = EMAIL_TAB_CONFIG[actualIndex]?.id;

        let fieldName;
        switch (currentTabId) {
            case 'invites':
                fieldName = "inviteMessage";
                break;
            case 'autoAssign':
                fieldName = "autoAssignMessage";
                break;
            case 'reminders':
                fieldName = "reminderMessage";
                break;
            case 'oneClick':
                fieldName = "oneClickMessage";
                break;
            case 'contentPacks':
                fieldName = "contentPackMessage";
                break;
            default:
                fieldName = "inviteMessage";
        }

        const textarea = document.querySelector(`textarea[name="${fieldName}"]`);
        if (textarea) {
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const text = formData[fieldName];
            const newText = text.substring(0, start) + insertField + text.substring(end);

            setFormData((prev) => ({
                ...prev,
                [fieldName]: newText,
            }));
        }
    };


    const handleSave = async () => {
        if (!formData.tenancyId) {
            console.error("No tenancyId available");
            return;
        }

        setIsSaving(true);
        try {
            const currentColors = globalEmailColors;
            let updatedTemplate = formData.courseBodyText;

            if (updatedTemplate) {
                const styleBlockRegex = /<style>([\s\S]*?)<\/style>/;
                const styleBlock = updatedTemplate.match(styleBlockRegex);

                if (styleBlock) {
                    const newStyleBlock = styleBlock[0]
                        .replace(/\{color\.primary\}/g, currentColors.primary)
                        .replace(/\{color\.secondary\}/g, currentColors.secondary)
                        .replace(/\{color\.tertiary\}/g, currentColors.tertiary)
                        .replace(/\{logo\.size\}/g, `${globalLogoSize}`);

                    updatedTemplate = updatedTemplate.replace(styleBlockRegex, newStyleBlock);
                }

                updatedTemplate = updatedTemplate
                    .replace(
                        /bgcolor="\{color\.primary\}"/g,
                        `bgcolor="${currentColors.primary}"`
                    )
                    .replace(
                        /bgcolor="\{color\.secondary\}"/g,
                        `bgcolor="${currentColors.secondary}"`
                    )
                    .replace(
                        /bgcolor="\{color\.tertiary\}"/g,
                        `bgcolor="${currentColors.tertiary}"`
                    )
                    .replace(
                        /background-color:\s*\{color\.primary\}/g,
                        `background-color: ${currentColors.primary}`
                    )
                    .replace(
                        /background-color:\s*\{color\.secondary\}/g,
                        `background-color: ${currentColors.secondary}`
                    )
                    .replace(
                        /background-color:\s*\{color\.tertiary\}/g,
                        `background-color: ${currentColors.tertiary}`
                    )
                    .replace(/width="\{logo\.size\}"/g, `width="${globalLogoSize}"`)
                    .replace(/width=\{logo\.size\}/g, `width="${globalLogoSize}"`);
            }

            // Always save as custom template when saving, regardless of previous setting
            const settingsToSave = {
                ...formData,
                content: updatedTemplate,
                courseBodyText: updatedTemplate,
                emailColors: currentColors,
                logoSize: globalLogoSize,
                useDefaultTemplate: false, // Always save as custom when making any changes
            };

            await saveSettings({
                tenancyId: formData.tenancyId,
                settingsData: settingsToSave,
            }).unwrap();

            localStorage.setItem("emailColors", JSON.stringify(currentColors));
            localStorage.setItem("logoSize", globalLogoSize.toString());


            setShowingDefaultAsOutline(false);
            // After saving, we're now using a custom template
            setIsUsingDefaultTemplate(false);

            console.log("Settings saved successfully");
            navigate(-1);
        } catch (error) {
            console.error("Failed to save settings:", error);
        } finally {
            setIsSaving(false);
        }
    };
    // Determine if we should show loading indicator
    const isLoading = settingsLoading || tenancyBasicsLoading || defaultTemplateLoading;

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const getActiveSubject = () => {
        const actualIndex = mapVisibleIndexToActualIndex(activeTab);

        switch (actualIndex) {
            case 0:
                return formData.inviteEmailSubject;
            case 1:
                return formData.autoAssignEmailSubject;
            case 2:
                return formData.reminderEmailSubject;
            case 3:
                return formData.oneClickEmailSubject;
            case 4:
                return formData.contentPackEmailSubject;
            default:
                return "";
        }
    };

    const renderTabFields = () => {
        const actualIndex = mapVisibleIndexToActualIndex(activeTab);

        switch (actualIndex) {
            case 0:
                return (
                    <>
                        <TextField
                            label="Invite Email Subject"
                            value={formData.inviteEmailSubject}
                            onChange={handleInputChange("inviteEmailSubject")}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Body"
                            name="inviteMessage"
                            value={formData.inviteMessage}
                            onChange={handleInputChange("inviteMessage")}
                            fullWidth
                            multiline
                            rows={8}
                            sx={{ mb: 2 }}
                        />
                    </>
                );
            case 1:
                return (
                    <>
                        <TextField
                            label="Auto-Assign Email Subject"
                            value={formData.autoAssignEmailSubject}
                            onChange={handleInputChange("autoAssignEmailSubject")}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Body"
                            name="autoAssignMessage"
                            value={formData.autoAssignMessage}
                            onChange={handleInputChange("autoAssignMessage")}
                            fullWidth
                            multiline
                            rows={8}
                            sx={{ mb: 2 }}

                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <TextField
                            label="Reminder Email Subject"
                            value={formData.reminderEmailSubject}
                            onChange={handleInputChange("reminderEmailSubject")}
                            fullWidth
                            sx={{ mb: 2 }}

                        />
                        <TextField
                            label="Body"
                            name="reminderMessage"
                            value={formData.reminderMessage}
                            onChange={handleInputChange("reminderMessage")}
                            fullWidth
                            multiline
                            rows={8}
                            sx={{ mb: 2 }}

                        />
                    </>
                );
            case 3:
                return (
                    <>
                        <TextField
                            label="One-Click Email Subject"
                            value={formData.oneClickEmailSubject}
                            onChange={handleInputChange("oneClickEmailSubject")}
                            fullWidth
                            sx={{ mb: 2 }}

                        />
                        <TextField
                            label="Body"
                            name="oneClickMessage"
                            value={formData.oneClickMessage}
                            onChange={handleInputChange("oneClickMessage")}
                            fullWidth
                            multiline
                            rows={8}
                            sx={{ mb: 2 }}

                        />
                    </>
                );
            case 4:
                return (
                    <>
                        <TextField
                            label="Content Pack Subject"
                            value={formData.contentPackEmailSubject}
                            onChange={handleInputChange("contentPackEmailSubject")}
                            fullWidth
                            sx={{ mb: 2 }}

                        />
                        <TextField
                            label="Body"
                            name="contentPackMessage"
                            value={formData.contentPackMessage}
                            onChange={handleInputChange("contentPackMessage")}
                            fullWidth
                            multiline
                            rows={8}
                            sx={{ mb: 2 }}

                        />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="h2" sx={{ ml: 2, mb: 2 }}>
                            {showStyleEditor ? "Edit Style" : "Edit Text"}
                        </Typography>
                        {showStyleEditor ? (
                            <StyleEditor
                                onBack={() => setShowStyleEditor(false)}
                                formData={formData}
                                handleInputChange={handleInputChange}
                                tenancyBasics={tenancyBasics}
                                onTemplateChange={handleTemplateChange}
                                onColorChange={handleGlobalColorChange}
                                onLogoSizeChange={handleGlobalLogoSizeChange}
                                initialColors={globalEmailColors}
                                initialLogoSize={globalLogoSize}
                                isDisabled={false}
                            />
                        ) : (
                            <>
                                <MainCard
                                    content={true}
                                    boxShadow={true}
                                    border={false}
                                    sx={{
                                        bgcolor: getCardColor(lightMode),
                                        display: "flex",
                                        flexDirection: "column",
                                        ml: 2,
                                        mb: 1,
                                    }}
                                >

                                    <Typography
                                        variant="h6"
                                        sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                                    >
                                        Global Email Text
                                    </Typography>

                                    <TextField
                                        label="Display Name"
                                        value={formData.displayName}
                                        onChange={handleInputChange("displayName")}
                                        fullWidth
                                        sx={{ mb: 2 }}
                                    />

                                    <TextField
                                        label="Button Text"
                                        value={formData.courseButtonText}
                                        onChange={handleInputChange("courseButtonText")}
                                        fullWidth
                                        sx={{ mb: 2 }}
                                    />

                                </MainCard>

                                <MainCard
                                    content={true}
                                    boxShadow={true}
                                    border={false}
                                    sx={{
                                        bgcolor: getCardColor(lightMode),
                                        display: "flex",
                                        flexDirection: "column",
                                        ml: 2,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                                    >
                                        {filteredTabs[activeTab]?.label || ""} Text
                                    </Typography>


                                    {renderTabFields()}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: 2,
                                            mb: 2,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={() => handleInsertText("user")}
                                            fullWidth
                                        >
                                            Insert User Name
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleInsertText("course")}
                                            fullWidth
                                        >
                                            Insert Course Name
                                        </Button>
                                    </Box>

                                    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleInsertText("p")}
                                        >
                                            Paragraph
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleInsertText("br")}
                                        >
                                            Break
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleInsertText("strong")}
                                        >
                                            Bold
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: 2,
                                            mb: 2,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={() => setShowStyleEditor(true)}
                                        >
                                            Edit Style
                                        </Button>
                                    </Box>
                                </MainCard>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: 2,
                            }}
                        >
                            <Typography variant="h2">Select to preview & edit</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<KeyboardArrowLeftTwoToneIcon />}
                                    onClick={() => navigate(-1)}
                                    sx={{ whiteSpace: "nowrap" }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    sx={{
                                        bgcolor: theme.palette.secondary.main,
                                        "&:hover": {
                                            bgcolor: theme.palette.secondary.dark,
                                        },
                                        whiteSpace: "nowrap"
                                    }}
                                    disabled={isSaving}
                                >
                                    {isSaving ? <CircularProgress size={24} /> : "Save & Apply"}
                                </Button>
                            </Box>
                        </Box>
                        <MainCard
                            content={true}
                            boxShadow={true}
                            border={false}
                            sx={{
                                bgcolor: getCardColor(lightMode),
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                                mr: 2,
                            }}
                        >
                            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2, mx: 1 }}>
                                <ScrollableTabs
                                    value={activeTab}
                                    onChange={(_, newValue) => setActiveTab(newValue)}
                                    ariaLabel="email template tabs"
                                >
                                    {filteredTabs.map((tab, index) => (
                                        <Tab key={tab.id} label={tab.label} />
                                    ))}
                                </ScrollableTabs>
                            </Box>
                            <Box>
                                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
                                    <Typography variant="h6">
                                        Display name: {formData.displayName}
                                    </Typography>
                                    <Typography variant="h6">
                                        Subject: {getActiveSubject()}
                                    </Typography>
                                </Box>
                                <EmailPreview
                                    templateContent={formData.courseBodyText}
                                    messageToUse={getCurrentMessage()}
                                    buttonText={formData.courseButtonText}
                                    logoSize={globalLogoSize}
                                    colors={globalEmailColors}
                                    tenancyBasics={tenancyBasics}
                                    tenancyData={tenancyData}
                                />
                            </Box>
                        </MainCard>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default CustomEmailTemplate;