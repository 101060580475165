import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
    useDeleteAllUsersMutation,
    useDeleteJourneysAndSchedulesMutation,
    useDeleteTenancyMutation,
    useUpdateTenancyLogoMutation,
    useUpdateTenancyMutation
} from "../../store/services/tenancy.service";
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    CircularProgress,
    Divider,
    FormControlLabel,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { useGetUniversityCourseSettingsQuery, useSaveUniversityCourseSettingsMutation } from "../../store/services/university-service";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { APP_URL } from "../../config";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material/styles";
import { saveThemeColors, getThemeColors } from "../../themes/themeColourManager";
import { getCardColor, isValidHexCode } from "../../utils/colorUtils";
import { useThemeContext } from '../../themes/ThemeContext';
import ThemeSquare from '../../components/ThemeSquare';
import { PRESET_THEMES } from '../../themes/PresetThemes';
import ColorPicker from '../../components/ColorPicker';
import EmailPreview from "../../components/EmailPreview";
import EmailPreviewModal from "../../components/EmailPreviewModal";
import LoadingSwitch from "../../components/LoadingSwitch";

EmailSettings.propTypes = {
    data: PropTypes.object
};

export default function EmailSettings(props) {
    const { data } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const { customColors, updateThemeColors } = useThemeContext();
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const lightMode = theme.palette.mode === 'light';

    const [tenancyData, setTenancyData] = useState(data);
    const [useCustomTemplate, setUseCustomTemplate] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [pendingValue, setPendingValue] = useState(null);
    const [originalCustomTemplate, setOriginalCustomTemplate] = useState(null);

    const [primaryColor, setPrimaryColor] = useState(theme.palette.primary.main);
    const [secondaryColor, setSecondaryColor] = useState(theme.palette.secondary.main);
    const [tertiaryColor, setTertiaryColor] = useState(theme.palette.warning.main);

    const [isSaving, setIsSaving] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const environment = process.env.REACT_APP_BRAND_TYPE === 'censornet';
    const environment_name = process.env.REACT_APP_ENVIRONMENT_NAME;
    const [previewContent, setPreviewContent] = useState('');
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

    const {
        data: universitySettings,
        error: universitySettingsError,
        isLoading: universitySettingsLoading
    } = useGetUniversityCourseSettingsQuery({ tenancyId: tenancyData.tenancyId });

    const [updateTenancy] = useUpdateTenancyMutation();
    const [updateUniversityCourseSettings] = useSaveUniversityCourseSettingsMutation();
    const [deleteAllUsers] = useDeleteAllUsersMutation();
    const [deleteTenancy] = useDeleteTenancyMutation();
    const [deleteJourneysSchedules] = useDeleteJourneysAndSchedulesMutation();
    const [updateTenancyLogo] = useUpdateTenancyLogoMutation();
    const {
        data: defaultTemplateSettings,
        isLoading: defaultTemplateLoading
    } = useGetUniversityCourseSettingsQuery({ tenancyId: 0 });

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [enableOnDemandBox, setEnableOnDemandBox] = useState(tenancyData.enableOndemand);
    const [unauthSendingBox, setUnauthSendingBox] = useState(universitySettings ? universitySettings?.allowUnauthorisedTakeCourse : false);
    const [anonymiseReportingBox, setAnonymiseReportingBox] = useState(tenancyData.anonymiseReports);

    useEffect(() => {
        if (universitySettings) {
            setUseCustomTemplate(!universitySettings.useDefaultTemplate);

            // Store the original custom template when component loads
            if (!universitySettings.useDefaultTemplate) {
                setOriginalCustomTemplate({
                    courseBodyText: universitySettings.courseBodyText,
                    inviteMessage: universitySettings.inviteMessage,
                    reminderMessage: universitySettings.reminderMessage,
                    oneClickMessage: universitySettings.oneClickMessage,
                    contentPackMessage: universitySettings.contentPackMessage,
                    autoAssignMessage: universitySettings.autoAssignMessage,
                    inviteEmailSubject: universitySettings.inviteEmailSubject,
                    reminderEmailSubject: universitySettings.reminderEmailSubject,
                    oneClickEmailSubject: universitySettings.oneClickEmailSubject,
                    contentPackEmailSubject: universitySettings.contentPackEmailSubject,
                    autoAssignEmailSubject: universitySettings.autoAssignEmailSubject,
                    courseButtonText: universitySettings.courseButtonText,
                    displayName: universitySettings.displayName
                });
            }
        }
    }, [universitySettings]);

    useEffect(() => {
        if (universitySettings && defaultTemplateSettings) {
            const templateToUse = useCustomTemplate
                ? universitySettings.courseBodyText
                : defaultTemplateSettings.courseBodyText;

            if (templateToUse) {
                let processedTemplate = templateToUse;

                if (tenancyData?.logo) {
                    processedTemplate = processedTemplate.replace(
                        /{client\.logo}/g,
                        `${APP_URL}/${tenancyData.logo}`
                    );
                }

                setPreviewContent(processedTemplate);
            }
        }
    }, [
        useCustomTemplate,
        universitySettings,
        defaultTemplateSettings,
        tenancyData?.logo
    ]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
    }, []);

    const handleToggleClick = () => {
        setPendingValue(!useCustomTemplate);
        setIsDialogOpen(true);
    };

    const handleConfirm = async () => {
        setIsDialogOpen(false);

        try {
            const newUseDefaultTemplate = !pendingValue;

            let completeSettingsData = {
                ...universitySettings,
                tenancyId: tenancyData.tenancyId,
                useDefaultTemplate: newUseDefaultTemplate
            };

            if (newUseDefaultTemplate) {
                // Switching TO default template - save the default values
                completeSettingsData = {
                    ...completeSettingsData,
                    courseBodyText: defaultTemplateSettings?.courseBodyText || "",
                    inviteMessage: defaultTemplateSettings?.inviteMessage || "",
                    reminderMessage: defaultTemplateSettings?.reminderMessage || "",
                    oneClickMessage: defaultTemplateSettings?.oneClickMessage || "",
                    contentPackMessage: defaultTemplateSettings?.contentPackMessage || "",
                    autoAssignMessage: defaultTemplateSettings?.autoAssignMessage || "",
                    inviteEmailSubject: defaultTemplateSettings?.inviteEmailSubject || "",
                    reminderEmailSubject: defaultTemplateSettings?.reminderEmailSubject || "",
                    oneClickEmailSubject: defaultTemplateSettings?.oneClickEmailSubject || "",
                    contentPackEmailSubject: defaultTemplateSettings?.contentPackEmailSubject || "",
                    autoAssignEmailSubject: defaultTemplateSettings?.autoAssignEmailSubject || "",
                    courseButtonText: defaultTemplateSettings?.courseButtonText || "",
                    displayName: defaultTemplateSettings?.displayName || ""
                };
            } else if (originalCustomTemplate) {
                // Switching FROM default template back to custom - restore original custom values
                completeSettingsData = {
                    ...completeSettingsData,
                    ...originalCustomTemplate
                };
            }

            await updateUniversityCourseSettings({
                tenancyId: tenancyData.tenancyId,
                settingsData: completeSettingsData
            }).unwrap();

            setUseCustomTemplate(pendingValue);

            // Set correct template content for preview
            const newTemplateContent = pendingValue
                ? (originalCustomTemplate?.courseBodyText || universitySettings.courseBodyText)
                : defaultTemplateSettings.courseBodyText;

            if (newTemplateContent) {
                let processedTemplate = newTemplateContent;

                if (tenancyData?.logo) {
                    processedTemplate = processedTemplate.replace(
                        /{client\.logo}/g,
                        `${APP_URL}/${tenancyData.logo}`
                    );
                }

                setPreviewContent(processedTemplate);
            }

            setSnackbarMessage(
                pendingValue
                    ? "Custom template enabled successfully"
                    : "Default template enabled successfully"
            );
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error updating template settings:", error);
            setSnackbarMessage("Error updating template settings");
            setSnackbarSeverity("error");
        }

        setSnackbarOpen(true);
        setPendingValue(null);
    };

    const handleCancel = () => {
        setIsDialogOpen(false);
        setPendingValue(null);
    };

    const handleSave = useCallback(async (savedData) => {
        setIsSaving(true);
        try {
            if (!isValidHexCode(primaryColor) || !isValidHexCode(secondaryColor) || !isValidHexCode(tertiaryColor)) {
                throw new Error("Invalid hex code detected");
            }

            const dataToUpdate = savedData || tenancyData;

            const updatedTenancyData = {
                ...dataToUpdate,
                primaryHex: primaryColor,
                secondaryHex: secondaryColor,
                tertiaryHex: tertiaryColor
            };

            await updateTenancy({
                data: updatedTenancyData,
                tenancyId: tenancyData.tenancyId
            }).unwrap();

            updateThemeColors({
                primary: primaryColor,
                secondary: secondaryColor,
                warning: tertiaryColor
            });

            setTenancyData(updatedTenancyData);
            setSnackbarMessage("Settings saved successfully");
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error saving settings:", error);
            setSnackbarMessage(`Error saving settings: ${error.message}`);
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setIsSaving(false);
        }
    }, [tenancyData, primaryColor, secondaryColor, tertiaryColor, updateTenancy, updateThemeColors]);

    const handlePresetThemeSelect = (theme) => {
        setSelectedTheme(theme);
        const newColors = {
            primary: theme.primary,
            secondary: theme.secondary,
            warning: theme.tertiary
        };
        setPrimaryColor(theme.primary);
        setSecondaryColor(theme.secondary);
        setTertiaryColor(theme.tertiary);
        updateThemeColors(newColors);
    };

    const handleColorChange = (colorKey, newValue) => {
        const newColors = { ...customColors, [colorKey]: newValue };
        updateThemeColors(newColors);
    };

    const handleSaveUniversityCourseSettings = () => {
        const completeSettingsData = {
            ...universitySettings,
            tenancyId: tenancyData.tenancyId,
            allowUnauthorisedTakeCourse: unauthSendingBox
        };

        updateUniversityCourseSettings({
            tenancyId: tenancyData.tenancyId,
            settingsData: completeSettingsData
        })
            .then(() => {
                setSnackbarMessage("University course settings updated successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error("Error updating university course settings:", error);
                setSnackbarMessage("Error updating university course settings.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    if (tenancyData) {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ mb: 4 }}>
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{
                                            height: '100%',
                                            bgcolor: getCardColor(lightMode),
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            mb: 1
                                        }}>
                                            <Box sx={{display: "flex", alignItems: "center"}}>
                                                <Tooltip
                                                    title="Customise your email templates or use the default template">
                                                    <IconButton>
                                                        <HelpIcon sx={{color: "secondary.main"}}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h4">
                                                    Email Customisation
                                                </Typography>
                                            </Box>
                                            <LoadingSwitch
                                                checked={useCustomTemplate}
                                                onChange={handleToggleClick}
                                                loading={universitySettingsLoading || defaultTemplateLoading}
                                                disabled={false}
                                                inputProps={{ "aria-label": "template type toggle" }}
                                            />
                                        </Box>
                                        <Divider sx={{width: "100%", mb: 2}}/>
                                        <EmailPreview
                                            content={previewContent}
                                            isLoading={universitySettingsLoading || defaultTemplateLoading}
                                        />
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: 2,
                                            marginTop: 1
                                        }}>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                onClick={() => setIsPreviewModalOpen(true)}
                                                sx={{ px: 3, width: "700px" }}
                                            >
                                                Preview
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                onClick={() => navigate("/settings/customemail")}
                                                sx={{px: 3, width: '700px'}}
                                            >
                                                Edit
                                            </Button>
                                            <EmailPreviewModal
                                                open={isPreviewModalOpen}
                                                onClose={() => setIsPreviewModalOpen(false)}
                                                previewContent={previewContent}
                                                tenancyData={tenancyData}
                                                universitySettings={useCustomTemplate ? universitySettings : defaultTemplateSettings}
                                            />
                                        </Box>
                                    </MainCard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog
                    open={isDialogOpen}
                    onClose={handleCancel}
                    aria-labelledby="template-confirmation-dialog"
                >
                    <DialogTitle id="template-confirmation-dialog">
                        {pendingValue ? "Switch to Custom Template?" : "Switch to Default Template?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {pendingValue
                                ? "Switching to custom templates will replace any email settings currently in place. Are you sure you want to continue?"
                                : "Switching to the default template will disable your custom email templates. All users will receive the default template emails. Are you sure you want to continue?"
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} color="primary" variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity={snackbarSeverity}
                        variant="filled"
                        sx={{ width: "100%" }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </>
        );
    }
    return null;
}