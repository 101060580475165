import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const PdfExporter = ({
                         url,
                         fileName = 'export.pdf',
                         buttonText = 'Export to PDF',
                         buttonProps = {},
                         onSuccess = () => {},
                         onError = () => {}
                     }) => {
    const [isExporting, setIsExporting] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info'
    });


    const imageUrlToBase64 = async (url) => {
        try {
            
            const absoluteUrl = url.startsWith('http') ? url : new URL(url, window.location.origin).href;

            const response = await fetch(absoluteUrl, {
                mode: 'cors',
                cache: 'no-cache',
            });

            if (!response.ok) throw new Error(`Failed to fetch image: ${response.status}`);

            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.warn(`Error converting image to base64: ${url}`, error);
            return null; // Return null for failed conversions
        }
    };

    /**
     * Process HTML content to replace image sources with base64
     * @param {string} html - Original HTML content
     * @returns {Promise<string>} - HTML with base64 images
     */
    const processHtmlImages = async (html) => {
        // Create a temporary DOM element to work with the HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Process all <img> tags
        const imgTags = doc.querySelectorAll('img');
        const imgPromises = Array.from(imgTags).map(async (img) => {
            if (img.src) {
                const base64Data = await imageUrlToBase64(img.src);
                if (base64Data) {
                    img.src = base64Data;
                    img.crossOrigin = 'Anonymous';
                }
            }
        });

        // Process CSS background images
        const elementsWithBgImage = doc.querySelectorAll('*');
        const bgPromises = Array.from(elementsWithBgImage).map(async (el) => {
            const style = window.getComputedStyle(el);
            const bgImage = style.backgroundImage;

            if (bgImage && bgImage !== 'none') {
                const match = bgImage.match(/url\(['"]?([^'"]+)['"]?\)/);
                if (match) {
                    const imageUrl = match[1];
                    const base64Data = await imageUrlToBase64(imageUrl);
                    if (base64Data) {
                        el.style.backgroundImage = `url(${base64Data})`;
                    }
                }
            }
        });

        // Wait for all conversions to complete
        await Promise.all([...imgPromises, ...bgPromises]);

        // Return the modified HTML
        return doc.documentElement.outerHTML;
    };

    /**
     * Handle PDF export
     */
    const handleExport = async () => {
        if (!url) {
            setSnackbar({
                open: true,
                message: 'No URL provided for export',
                severity: 'error'
            });
            onError(new Error('No URL provided'));
            return;
        }

        setIsExporting(true);
        setSnackbar({
            open: true,
            message: 'Preparing PDF export...',
            severity: 'info'
        });

        try {
            // Fetch the HTML content
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'text/html',
                    'Cache-Control': 'no-cache',
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch content: ${response.status} ${response.statusText}`);
            }

            // Get the HTML content and process the images
            let htmlContent = await response.text();
            htmlContent = await processHtmlImages(htmlContent);

            // Create a temporary container to render the content
            const container = document.createElement('div');
            container.style.width = '1024px';
            container.style.position = 'absolute';
            container.style.left = '-9999px';
            container.style.top = '-9999px';
            container.innerHTML = htmlContent;

            // Add the container to the DOM temporarily
            document.body.appendChild(container);

            // Initialize jsPDF
            const pdf = new jsPDF('p', 'mm', 'a4');

            // Use html2canvas with proper settings
            const canvas = await html2canvas(container, {
                scale: 2,
                useCORS: true,
                allowTaint: true,
                logging: false,
                width: 1024,
                windowWidth: 1024,
                imageTimeout: 15000,
            });

            // Convert canvas to image
            const imgData = canvas.toDataURL('image/png');

            // Calculate dimensions
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add image to first page
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            // Add additional pages if needed
            let heightLeft = imgHeight - pageHeight;
            let position = -pageHeight;

            while (heightLeft > 0) {
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                position -= pageHeight;
            }

            // Save the PDF
            pdf.save(fileName);

            // Clean up
            document.body.removeChild(container);

            setSnackbar({
                open: true,
                message: `Successfully exported PDF as ${fileName}`,
                severity: 'success'
            });

            onSuccess(fileName);
        } catch (error) {
            console.error("PDF export error:", error);
            setSnackbar({
                open: true,
                message: `Failed to export PDF: ${error.message || "Unknown error"}`,
                severity: 'error'
            });

            onError(error);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleExport}
                disabled={isExporting || !url}
                startIcon={isExporting ? <CircularProgress size={20} /> : <PictureAsPdfIcon />}
                {...buttonProps}
            >
                {isExporting ? "Exporting..." : buttonText}
            </Button>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    variant="filled"
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

PdfExporter.propTypes = {
    url: PropTypes.string.isRequired,
    fileName: PropTypes.string,
    buttonText: PropTypes.string,
    buttonProps: PropTypes.object,
    onSuccess: PropTypes.func,
    onError: PropTypes.func
};

export default PdfExporter;