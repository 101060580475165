import { SVG_PATHS } from './SvgPaths';

/**
 * Creates an inline SVG string with the specified path and color
 * @param {string} pathKey - The key for the SVG path in SVG_PATHS
 * @param {string} color - The fill color for the SVG
 * @param {number} size - The size of the SVG (width and height)
 * @returns {string} - The inline SVG string
 */
export const createInlineSvg = (pathKey, color, size = 60) => {
    const path = SVG_PATHS[pathKey];
    if (!path) {
        console.error(`SVG path for key "${pathKey}" not found`);
        return '';
    }

    return `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24" fill="${color}">
    <path d="${path}" />
  </svg>`;
};

/**
 * Generate all icon SVGs with the specified color
 * @param {string} color - The color to use for the icons
 * @param {number} size - The size of the icons
 * @returns {Object} - An object with keys for each icon and values as inline SVG strings
 */
export const generateAllIconSvgs = (color, size = 60) => {
    return {
        emailDomain: createInlineSvg('alternateEmail', color, size),
        spoofing: createInlineSvg('mail', color, size),
        urgency: createInlineSvg('urgency', color, size),
        links: createInlineSvg('link', color, size),
        attachments: createInlineSvg('attachFile', color, size),
        sensitiveInfo: createInlineSvg('searchCheck', color, size)
    };
};