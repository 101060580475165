import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
    Typography,
    Alert,
    Divider,
    IconButton,
    Autocomplete,
    Card,
    CardContent,
    Tooltip
} from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    useGetCitiesByTenancyIdQuery,
    useGetCompanyNamesByTenancyIdQuery,
    useGetCountriesByTenancyIdQuery,
    useGetDepartmentsByTenancyIdQuery,
    useGetLocationsByTenancyIdQuery,
    useGetStatesByTenancyIdQuery,
} from '../../store/services/tenancy.service';
import MainCard from '../../components/cards/MainCard';
import { useTheme } from "@mui/material/styles";
import { getCardColor } from "../../utils/colorUtils";



AdminsSelection.propTypes = {
    state: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function AdminsSelection({ state, onChange }) {
    const { tenancyId } = useSelector((state) => state.tenancy);
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const [errorMessage, setErrorMessage] = useState('');
    const [currentAdmin, setCurrentAdmin] = useState({
        firstName: '',
        lastName: '',
        email: '',
        location: '',
        department: '',
        companyName: '',
        country: '',
        state: '',
        city: '',
    });
    const [errors, setErrors] = useState({});
    const [currentUser, setCurrentUser] = useState(null);

    const { data: departments } = useGetDepartmentsByTenancyIdQuery({ tenancyId });
    const { data: locations } = useGetLocationsByTenancyIdQuery({ tenancyId });
    const { data: cities } = useGetCitiesByTenancyIdQuery({ tenancyId });
    const { data: companyNames } = useGetCompanyNamesByTenancyIdQuery({ tenancyId });
    const { data: countries } = useGetCountriesByTenancyIdQuery({ tenancyId });
    const { data: states } = useGetStatesByTenancyIdQuery({ tenancyId });

    // Load the current user from localStorage when component mounts
    useEffect(() => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            setCurrentUser(user);
        } catch (error) {
            console.error("Error retrieving user from localStorage:", error);
        }
    }, []);

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const validateCurrentAdmin = () => {
        const newErrors = {};

        if (!currentAdmin.firstName) {
            newErrors.firstName = true;
        }
        if (!currentAdmin.email) {
            newErrors.email = true;
        } else if (!validateEmail(currentAdmin.email)) {
            newErrors.email = true;
            setErrorMessage('Please enter a valid email address');
            return false;
        }

        // Check if the email is the same as the current user's email
        if (currentUser && currentAdmin.email.toLowerCase() === currentUser.email.toLowerCase()) {
            newErrors.email = true;
            setErrorMessage('You cannot add yourself as an administrator. You already have access to this tenancy.');
            return false;
        }

        // Check if the email already exists in the list of admins
        if (state.assignAdmins?.admins?.some(admin =>
            admin.email.toLowerCase() === currentAdmin.email.toLowerCase()
        )) {
            newErrors.email = true;
            setErrorMessage('This email address has already been added to the administrators list.');
            return false;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAddAdmin = () => {
        if (!validateCurrentAdmin()) {
            return;
        }

        const newAdmin = {
            ...currentAdmin,
            userName: currentAdmin.email,
            startUserJourneyOn: new Date().toISOString(),
            tenancyId,
        };

        const updatedAdmins = [...(state.assignAdmins.admins || []), newAdmin];
        onChange('assignAdmins', { admins: updatedAdmins });

        // Reset current admin form
        setCurrentAdmin({
            firstName: '',
            lastName: '',
            email: '',
            location: '',
            department: '',
            companyName: '',
            country: '',
            state: '',
            city: '',
        });
        setErrors({});
        setErrorMessage('');
    };

    const handleRemoveAdmin = (index) => {
        const updatedAdmins = state.assignAdmins.admins.filter((_, i) => i !== index);
        onChange('assignAdmins', { admins: updatedAdmins });
    };

    const handleInputChange = (field, value) => {
        setCurrentAdmin(prev => ({
            ...prev,
            [field]: value
        }));
        if (errors[field]) {
            setErrors(prev => ({
                ...prev,
                [field]: false
            }));
        }

        // Clear error message when email changes
        if (field === 'email') {
            setErrorMessage('');
        }
    };

    return (
        <Grid
            container
            spacing={2}
            sx={{
                p: { xs: 1, sm: 3, md: 5, lg: 7 },
                width: '100%',
                m: 0
            }}
        >
            {/* Add New Admin Card */}
            <Grid item xs={12} md={12}>
                <MainCard
                    content={true}
                    boxShadow={true}
                    border={false}
                    sx={{
                        height: '100%',
                        display: 'flex',
                        bgcolor: getCardColor(lightMode),
                        flexDirection: 'column'
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Add administrators who will have full access to manage this tenancy. You cannot add yourself as you already have access to this tenancy.">
                                <IconButton>
                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h4">
                                Add Administrator
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ width: "100%", mb: 2 }} />

                    {errorMessage && (
                        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setErrorMessage('')}>
                            {errorMessage}
                        </Alert>
                    )}

                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="First Name"
                                    value={currentAdmin.firstName}
                                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                                    required
                                    error={errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Last Name"
                                    value={currentAdmin.lastName}
                                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Email"
                                    type="email"
                                    value={currentAdmin.email}
                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                    required
                                    error={errors.email}
                                    helperText={currentUser?.email ? `Note: You cannot use your own email (${currentUser.email})` : ""}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddAdmin}
                            startIcon={<AddIcon />}
                        >
                            Add Administrator
                        </Button>
                    </Box>
                </MainCard>
            </Grid>

            {/* Added Admins Card */}
            <Grid item xs={12} md={12}>
                <MainCard
                    content={true}
                    boxShadow={true}
                    border={false}
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: getCardColor(lightMode)
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="List of administrators who will be added to this tenancy.">
                                <IconButton>
                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h4">
                                Added Administrators ({state.assignAdmins?.admins?.length || 0})
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ width: "100%", mb: 2 }} />

                    <Box sx={{ flexGrow: 1 }}>
                        {state.assignAdmins?.admins?.length > 0 ? (
                            state.assignAdmins.admins.map((admin, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        p: 2,
                                        mb: 2,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: theme.borderRadius.sm,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box>
                                        <Typography variant="subtitle1">
                                            {admin.firstName} {admin.lastName}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {admin.email}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        onClick={() => handleRemoveAdmin(index)}
                                        color="error"
                                        size="small"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))
                        ) : (
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{ textAlign: 'center', py: 4 }}
                            >
                                No administrators added yet
                            </Typography>
                        )}
                    </Box>
                </MainCard>
            </Grid>
        </Grid>
    );
}