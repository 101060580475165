
export const SVG_PATHS = {
    mail: "M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z",

    urgency: "M6 20C5.44772 20 5 19.5523 5 19V18.9176C5 18.4108 5.4108 18 5.91756 18V18C6.32264 18 6.67979 17.7344 6.79633 17.3464L8.575 11.425C8.70833 10.9917 8.95417 10.6458 9.3125 10.3875C9.67083 10.1292 10.0667 10 10.5 10H13.5C13.9333 10 14.3292 10.1292 14.6875 10.3875C15.0458 10.6458 15.2917 10.9917 15.425 11.425L17.2037 17.3464C17.3202 17.7344 17.6774 18 18.0824 18V18C18.5892 18 19 18.4108 19 18.9176V19C19 19.5523 18.5523 20 18 20H6ZM9.0862 16.7127C8.89372 17.3543 9.37417 18 10.044 18H13.956C14.6258 18 15.1063 17.3543 14.9138 16.7127L13.7138 12.7127C13.5869 12.2897 13.1976 12 12.756 12H11.244C10.8024 12 10.4131 12.2897 10.2862 12.7127L9.0862 16.7127ZM12 8C11.4477 8 11 7.55228 11 7V4C11 3.44772 11.4477 3 12 3V3C12.5523 3 13 3.44772 13 4V7C13 7.55228 12.5523 8 12 8V8ZM17.6571 9.76289C17.267 10.1558 16.629 10.154 16.2375 9.7625V9.7625C15.846 9.371 15.8442 8.73302 16.2371 8.3429L18.3725 6.22252C18.7609 5.83689 19.388 5.838 19.775 6.225V6.225C20.162 6.612 20.1631 7.23911 19.7775 7.62747L17.6571 9.76289ZM19 15C18.4477 15 18 14.5523 18 14V14C18 13.4477 18.4477 13 19 13H22C22.5523 13 23 13.4477 23 14V14C23 14.5523 22.5523 15 22 15H19ZM7.7625 9.7625C7.371 10.154 6.73302 10.1558 6.3429 9.76289L4.22252 7.62747C3.83689 7.23911 3.838 6.612 4.225 6.225V6.225C4.612 5.838 5.23911 5.83689 5.62747 6.22252L7.76289 8.3429C8.15577 8.73302 8.154 9.371 7.7625 9.7625V9.7625ZM2 15C1.44772 15 1 14.5523 1 14V14C1 13.4477 1.44772 13 2 13H5C5.55228 13 6 13.4477 6 14V14C6 14.5523 5.55228 15 5 15H2Z",

    link: "M11 16C11 16.5523 10.5523 17 10 17H7C5.61667 17 4.4375 16.5125 3.4625 15.5375C2.4875 14.5625 2 13.3833 2 12C2 10.6167 2.4875 9.4375 3.4625 8.4625C4.4375 7.4875 5.61667 7 7 7H10C10.5523 7 11 7.44772 11 8V8C11 8.55228 10.5523 9 10 9H7C6.16667 9 5.45833 9.29167 4.875 9.875C4.29167 10.4583 4 11.1667 4 12C4 12.8333 4.29167 13.5417 4.875 14.125C5.45833 14.7083 6.16667 15 7 15H10C10.5523 15 11 15.4477 11 16V16ZM9 13C8.44772 13 8 12.5523 8 12V12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12V12C16 12.5523 15.5523 13 15 13H9ZM14 17C13.4477 17 13 16.5523 13 16V16C13 15.4477 13.4477 15 14 15H17C17.8333 15 18.5417 14.7083 19.125 14.125C19.7083 13.5417 20 12.8333 20 12C20 11.1667 19.7083 10.4583 19.125 9.875C18.5417 9.29167 17.8333 9 17 9H14C13.4477 9 13 8.55228 13 8V8C13 7.44772 13.4477 7 14 7H17C18.3833 7 19.5625 7.4875 20.5375 8.4625C21.5125 9.4375 22 10.6167 22 12C22 13.3833 21.5125 14.5625 20.5375 15.5375C19.5625 16.5125 18.3833 17 17 17H14Z",

    attachFile: "M18 15.75C18 17.4833 17.3917 18.9583 16.175 20.175C14.9583 21.3917 13.4833 22 11.75 22C10.0167 22 8.54167 21.3917 7.325 20.175C6.10833 18.9583 5.5 17.4833 5.5 15.75V6.5C5.5 5.25 5.9375 4.1875 6.8125 3.3125C7.6875 2.4375 8.75 2 10 2C11.25 2 12.3125 2.4375 13.1875 3.3125C14.0625 4.1875 14.5 5.25 14.5 6.5V15.25C14.5 16.0167 14.2333 16.6667 13.7 17.2C13.1667 17.7333 12.5167 18 11.75 18C10.9833 18 10.3333 17.7333 9.8 17.2C9.26667 16.6667 9 16.0167 9 15.25V7C9 6.44772 9.44772 6 10 6V6C10.5523 6 11 6.44772 11 7V15.25C11 15.4667 11.0708 15.6458 11.2125 15.7875C11.3542 15.9292 11.5333 16 11.75 16C11.9667 16 12.1458 15.9292 12.2875 15.7875C12.4292 15.6458 12.5 15.4667 12.5 15.25V6.5C12.4833 5.8 12.2375 5.20833 11.7625 4.725C11.2875 4.24167 10.7 4 10 4C9.3 4 8.70833 4.24167 8.225 4.725C7.74167 5.20833 7.5 5.8 7.5 6.5V15.75C7.48333 16.9333 7.89167 17.9375 8.725 18.7625C9.55833 19.5875 10.5667 20 11.75 20C12.9167 20 13.9083 19.5875 14.725 18.7625C15.5417 17.9375 15.9667 16.9333 16 15.75V7C16 6.44772 16.4477 6 17 6V6C17.5523 6 18 6.44772 18 7V15.75Z",

    searchCheck: "M10 16C11.6667 16 13.0833 15.4167 14.25 14.25C15.4167 13.0833 16 11.6667 16 10C16 8.33333 15.4167 6.91667 14.25 5.75C13.0833 4.58333 11.6667 4 10 4C8.33333 4 6.91667 4.58333 5.75 5.75C4.58333 6.91667 4 8.33333 4 10C4 11.6667 4.58333 13.0833 5.75 14.25C6.91667 15.4167 8.33333 16 10 16ZM8.24133 12.8413C8.63247 13.2325 9.26683 13.2318 9.6571 12.8398L13.896 8.5821C14.2851 8.19133 14.2793 7.55425 13.8893 7.16434V7.16434C13.498 6.773 12.8581 6.76879 12.4679 7.16128L9.65293 9.99291C9.26438 10.3838 8.63283 10.3866 8.24083 9.99917L7.52585 9.29261C7.13712 8.90845 6.51077 8.90872 6.12091 9.29173V9.29173C5.72448 9.68121 5.72042 10.3204 6.11339 10.7134L8.24133 12.8413ZM10 18C7.76667 18 5.875 17.225 4.325 15.675C2.775 14.125 2 12.2333 2 10C2 7.76667 2.775 5.875 4.325 4.325C5.875 2.775 7.76667 2 10 2C12.2333 2 14.125 2.775 15.675 4.325C17.225 5.875 18 7.76667 18 10C18 10.9333 17.8542 11.8125 17.5625 12.6375C17.3703 13.1811 17.1257 13.6939 16.8286 14.176C16.5641 14.6052 16.5918 15.168 16.9475 15.5253L21.3015 19.8985C21.6875 20.2862 21.6868 20.9132 21.3 21.3V21.3C20.9132 21.6868 20.2862 21.6875 19.8985 21.3015L15.5253 16.9475C15.168 16.5918 14.6052 16.5641 14.176 16.8286C13.6939 17.1257 13.1811 17.3703 12.6375 17.5625C11.8125 17.8542 10.9333 18 10 18Z",

    alternateEmail: "M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12V13.45C22 14.4333 21.6625 15.2708 20.9875 15.9625C20.3125 16.6542 19.4833 17 18.5 17C17.9167 17 17.3667 16.875 16.85 16.625C16.6829 16.5441 16.5245 16.452 16.3748 16.3484C15.8743 16.0023 15.1675 15.9463 14.65 16.2666C14.4153 16.4118 14.1695 16.5354 13.9125 16.6375C13.3042 16.8792 12.6667 17 12 17C10.6167 17 9.4375 16.5125 8.4625 15.5375C7.4875 14.5625 7 13.3833 7 12C7 10.6167 7.4875 9.4375 8.4625 8.4625C9.4375 7.4875 10.6167 7 12 7C13.3833 7 14.5625 7.4875 15.5375 8.4625C16.5125 9.4375 17 10.6167 17 12V13.45C17 13.8833 17.1417 14.25 17.425 14.55C17.7083 14.85 18.0667 15 18.5 15C18.9333 15 19.2917 14.85 19.575 14.55C19.8583 14.25 20 13.8833 20 13.45V12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20H16C16.5523 20 17 20.4477 17 21V21C17 21.5523 16.5523 22 16 22H12ZM12 15C12.8333 15 13.5417 14.7083 14.125 14.125C14.7083 13.5417 15 12.8333 15 12C15 11.1667 14.7083 10.4583 14.125 9.875C13.5417 9.29167 12.8333 9 12 9C11.1667 9 10.4583 9.29167 9.875 9.875C9.29167 10.4583 9 11.1667 9 12C9 12.8333 9.29167 13.5417 9.875 14.125C10.4583 14.7083 11.1667 15 12 15Z"
};